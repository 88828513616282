import React from "react";

// Customizable Area Start
import {
	Grid,
	Divider,
	Modal,
	Backdrop,
	Box,
	Card,
	CardHeader,
	CardActions,
	Avatar,
	Button,
	OutlinedInput,
	InputAdornment
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { Link } from 'react-router-dom';
import LikeAPost from '../../LikeAPost/src/LikeAPost.web'
import UserContext from '../../../components/src/UserProvider'
import Picker from 'emoji-picker-react';
import { CommonActions } from '../../../components/src/PostCard.web'
import { SendIcon, EmogiIcon, LikedIcon, CloseIcon } from './assets'
import OverlayLoader from '../../../components/src/Loader.web'

// Customizable Area End

import ProjectDataController, {
	Props,
} from "./ProjectDataController.web";

export default class ProjectData extends ProjectDataController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start

	// Customizable Area End

	render() {
		// Customizable Area Start
		const { navigation } = this.props
		let { courseCommentData, ProjectDataById } = this.state

		const handleCommentFunction = () => {
			return (
				<>
					{this.state.userComment.length > 0 ?
						<BtnImg src={SendIcon} onClick={() => this.handleCourseComment(ProjectDataById)} style={{ width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', cursor: 'pointer' }} />
						: <BtnImg src={SendIcon} style={{ width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', opacity: 0.5 }} />
					}
				</>
			)
		}

		const handleEmoji = () => {
			if (this.state.openCommentEmoji) {
				return <Picker onEmojiClick={this.onCommentEmojiClick} pickerStyle={{ width: '100%' }} />
			}
			else {
				return ""
			}
		}

		const handlePagination = () => {
			if (this.state.pagination?.next_page) {
				return <div style={{ marginTop: '50px', marginBottom: '50px', width: '100%', textAlign: 'center', color: '#b9b9b9', fontSize: '15px', fontWeight: 'bold', cursor: 'pointer', backgroundColor: '#f0f0f0', padding: '15px 0px', borderRadius: '10px', borderColor: '#fefefe' }} onClick={this.loadMore}>Load more discussion</div>
			}
			else {
				return ""
			}
		}

		const showLikes = (p: any) => {
			if (p?.like_count>0) {
				return <div style={{ display: 'flex', alignItems: 'center' }}>
					<BtnImg src={LikedIcon} style={{ width: '18px' }} />
					<span style={{ paddingLeft: '3px', fontSize: '13px' }}>{p?.like_count}</span>
				</div>
			}
			else {
				return ""
			}
		}

		const emojiPicker = () => {
			if (this.state.openCommentReplyEmoji) {
				return <Picker onEmojiClick={this.onReplyEmojiClick} pickerStyle={{ width: '100%' }} />
			}
			else {
				return ""
			}
		}

		const showReplies = (p: any) => {
			if (this.state.openReply == p.id) {
				return p?.replies?.map?.((r: any) => <div style={{ padding: '10px 0px 10px 50px' }}>
					<UserAvtar>
						<Link to={`/user/${r?.account_id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={r?.profile_pic} onClick={() => navigation.navigate(`/user/${r?.account_id}`)} /></Link>
					</UserAvtar>
					<CommentDetail>
						<AccountName>{r?.account_name}</AccountName>
						<CommentData>{r?.content}</CommentData>
					</CommentDetail>
				</div>)
			}
			else {
				return ""
			}
		}

		const showModalPopup = () => {
			const {userID} = this.state
			return <Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					margin: '20px 40px',
				}}
				open={this.state.openModal}
				onClose={() => this.onCloseModal()}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Box
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						background: '#fff',
						borderRadius: 8,
						width: '900px',
						position: "relative",
					}} className="schedule_modal">
					<Grid container spacing={0}>
						<ModelBody item xs={12}>
							<RoundedCard style={{ padding: '0', margin: '0', maxHeight: 'calc(100vh - 50px)', overflow: 'scroll' }} key={ProjectDataById?.data?.id} variant="outlined">
								<HeaderWrapper>
									<CardHeader
										style={{ padding: '25px 20px 15px 20px', width:"65%" }}
										avatar={
											<Avatar style={{ width: 75, height: 75, verticalAlign:"middle", borderRadius:"50%", border:"1px solid #d3cece" }} src={ProjectDataById?.data?.attributes?.project_profile_picture} />}
										subheader={<Grid style={{ gap: '10px', display:"block" }} container alignItems="center">
										<span style={{ fontSize: '16px', fontWeight:"bold",color:"black" }}>{this.state.ProjectDataById?.data?.attributes?.project_name}</span>
										<Divider orientation="vertical" flexItem />
										<span style={{ textTransform: 'capitalize', fontSize: '14px',color:"black" }}>{ProjectDataById?.data?.attributes?.location}</span>
										<Divider orientation="vertical" flexItem />
										<div style={{display:"flex", justifyContent:"space-between"}}>
										<Link style={{ cursor: "pointer", textTransform: 'capitalize', fontSize: '14px',color:"black"}} to={'/CorporateProfile'}>{ProjectDataById?.data?.attributes?.company_name}</Link>
										<div>
										<span style={{ textTransform: 'capitalize', fontSize: '14px',color:"black", fontWeight:"bold" }}>Preference: </span>
										<span style={{ textTransform: 'capitalize', fontSize: '14px',color:"black" }}>{(ProjectDataById?.data?.attributes?.preference!==null && ProjectDataById?.data?.attributes?.preference!==undefined && ProjectDataById?.data?.attributes?.preference!=="null") ? ProjectDataById?.data?.attributes?.preference : ""}</span>
										</div>
										</div>
										</Grid>}
									/>
									<CloseButton onClick={this.onCloseModal}>
										<img src={CloseIcon} width="26px" />
									</CloseButton>
								</HeaderWrapper>
								<Divider />
								<FlexCol style={{ padding: '0 15px' }}>
									<DiscussHeader>Project Description</DiscussHeader>
									<div style={{wordWrap:"break-word",marginBottom:"15px"}}>{ProjectDataById?.data?.attributes?.description}</div>	
								</FlexCol>
								<Divider />
								<Divider />
								<CommonActions
									onStarClick={() => { }}
									onShareClick={this.handleShareCourse(ProjectDataById)}
									isFavourite={false}
									isHideComment={true}
									isHideStar={true}
									likeButton={
										<LikeAPost
											navigation={this.props.navigation}
											id=""
											likeable_type="BxBlockProjectManagement::ProjectManagement"
											isLiked={Boolean(ProjectDataById?.data?.attributes?.project_likes?.some((project: any) => Number(project.like_by_id) === Number(userID)))}
											post_id={String(ProjectDataById?.data?.id)}
											like_id={ProjectDataById?.data?.attributes?.project_likes[0]?.id}
											onLikeChange={this.onLikeChange}
											onLikeIdUpdate={this.onLikeIdUpdate}
										/>}
								/>
								<Divider style={{ marginTop: '10px' }} />
								<DiscussSection>
									<DiscussHeader>Discussion</DiscussHeader>
									<div style={{ marginBottom: '40px' }}>
										<CommentUserAvtar>
											<Link to={`/user/${this.context?.id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={this.context?.profile_pic} /></Link>
										</CommentUserAvtar>
										<div style={{ width: 'calc(100% - 50px)', display: 'inline-block', boxSizing: 'border-box' }}>
											<OutlinedInput
												id="outlined-adornment-weight"
												value={this.state.userComment}
												onChange={this.handleCourseCommentChange}
												placeholder="Comment your views"
												endAdornment={<InputAdornment position="end"><BtnImg src={EmogiIcon} onClick={this.openEmojiComment} style={{ width: '18px', paddingLeft: '10px', paddingRight: '10px' }} />
													<Divider orientation="vertical" style={{ height: '55px', color: '#fefefe' }} />
													{handleCommentFunction()}
												</InputAdornment>}
												aria-describedby="outlined-weight-helper-text"
												inputProps={{
													'aria-label': 'weight',
												}}
												style={{ width: '100%' }}
											/>
										</div>
										{handleEmoji()}
									</div>
									{courseCommentData?.map?.(p => {
										return (
											<div style={{ marginBottom: '15px' }}>
												<UserAvtar>
													<Link to={`/user/${p?.account_id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={p?.profile_pic} onClick={() => navigation.navigate(`/user/${p?.account_id}`)} /></Link>
												</UserAvtar>
												<CommentDetail>
													<AccountName>{p?.account_name}</AccountName>
													<CommentData>{p?.content}</CommentData>
												</CommentDetail>
												<CardActions>
													<FlexSpaceBetween style={{ padding: '0px 0px 5px 5px' }}>
														<CardButtonWrapper>
															<CardButton onClick={() => this.handleCommentLike(p)}>{p?.like_count!== 0 ? <div style={{ color: '#009c05' }}>Like</div> : "Like" }</CardButton>
															<div style={{ lineHeight: '2.5', color: '#d4d4d4' }}>|</div>
															<CardButton onClick={() => this.handleSetReplyId(p.id)}>Reply</CardButton>
														</CardButtonWrapper>
														{showLikes(p)}
													</FlexSpaceBetween>
												</CardActions>
												{this.state.reply_id === p.id ?
													<div style={{ marginTop: '15px', marginBottom: '40px', paddingLeft: '50px' }}>
														<CommentUserAvtar>
															<Link to={`/user/${this.context?.id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={this.context?.profile_pic} /></Link>
														</CommentUserAvtar>
														<div style={{ width: 'calc(100% - 50px)', display: 'inline-block', boxSizing: 'border-box' }}>
															<OutlinedInput
																id="outlined-adornment-weight"
																value={this.state.userReply}
																onChange={this.handleCourseReplyChange}
																placeholder="Comment your views"
																endAdornment={<InputAdornment position="end"><BtnImg src={EmogiIcon} onClick={this.openEmojiCommentReply} style={{ width: '18px', paddingLeft: '10px' }} />
																	{this.state.userReply.length > 0 ?
																		<BtnImg src={SendIcon} onClick={() => this.handleReplyComment()} style={{ cursor: "pointer", width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px' }} />
																		: <BtnImg src={SendIcon} style={{ cursor: "pointer", width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', opacity: 0.5 }} />
																	}</InputAdornment>}
																aria-describedby="outlined-weight-helper-text"
																inputProps={{
																	'aria-label': 'weight',
																}}
																style={{ width: '100%' }}
															/>
														</div>
														{emojiPicker()}
													</div> : ''}
												{p?.replies?.length > 0 && this.state.openReply != p.id ?
													<div onClick={() => this.showReply(p.id)} style={{ width: 'calc(100% - 50px)', height: '8px', margin: '0px 50px 25px', borderBottom: '1px solid #cdcdcd', textAlign: 'center' }}>
														<span style={{ fontSize: '13px', backgroundColor: '#fff', padding: '0 30px', color: '#a9a9a9' }}>
															View  {p?.replies?.length} replies
														</span>
													</div>
													: ''}
												{showReplies(p)}
											</div>
										)
									}
									)}
									{handlePagination()}
								</DiscussSection>
							</RoundedCard>
						</ModelBody>
					</Grid>
				</Box>
			</Modal >
		}
		// Customizable Area End
		return (
			// Customizable Area Start
			<Wrapper>
				{showModalPopup()}
				<OverlayLoader loading={this.state.loading}/>
			</Wrapper>
			// Customizable Area End
		);
	}
}
// Customizable Area Start
ProjectDataController.contextType = UserContext

const ModelBody = styled(Grid)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'unset',
	flexDirection: 'column',
	padding: '0px'
})

const FlexCol = styled('div')({
	display: 'flex',
	flexDirection: 'column'
})

const RoundedCard = styled(Card)({
	borderRadius: '15px',
	padding: '10px 20px',
	margin: '20px 0px'
})

const FlexSpaceBetween = styled('div')({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	alignContent: 'center',
	padding: '20px 0px',
	width: '100%',
})

const CardButton = styled(Button)({
	border: 'none',
	color: '#aaaaaa',
	fontWeight: 600,
	textTransform: 'none'
})

const CardButtonWrapper = styled('div')({
	display: 'flex',
	gap: '5px',
	padding: '0 20px'
})

const BtnImg = styled('img')({
	width: 25
})

const HeaderWrapper = styled('div')({
	display: 'flex',
})

const CloseButton = styled(Button)({
	position: 'absolute',
	top: '20px',
	right: '0px',
	flex: 1,
})
const Wrapper = styled('div')({
	zIndex: 1010,
	minWidth: '100vw',
	minHeight: '100vh',
	background: 'rgba(0,0,0,0.5)',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
})

const DiscussSection = styled('div')({
	padding: '16px 30px'
})

const AccountName = styled('div')({
	color: '#009c05',
	'font-weight': 'bold',
	'font-size': '13px'
})

const CommentData = styled('div')({
	width: '100%',
	'word-break': 'break-word',
	marginTop: '5px'
})

const DiscussHeader = styled('div')({
	fontSize: '18px',
	fontWeight: 'bold',
	padding: "15px 18px 25px",
	paddingLeft: 0
})

const UserAvtar = styled('div')({
	display: 'inline-block',
	width: '50px',
	height: '40px',
	verticalAlign: 'top',
	paddingTop: '3px'
})

const CommentUserAvtar = styled('div')({
	display: 'inline-block',
	width: '50px',
	height: '40px',
	verticalAlign: 'top',
	paddingTop: '12px'
})

const CommentDetail = styled('div')({
	display: 'inline-block',
	width: 'calc(100% - 50px)',
	boxSizing: 'border-box',
	padding: '10px 20px 20px',
	minHeight: '80px',
	border: '1px solid #e8e4e4',
	borderRadius: '5px',
	lineHeight: '1.3',
	textAlign: 'justify'
})

// Customizable Area End
