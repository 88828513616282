import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAddRequestMessage } from '../../RequestManagement/src/Messages'
import { sendReportMessage } from '../../ActivityFeed/src/Messages'
import { toast } from 'react-toastify'
import { sendSharePopupMessage } from "../../share/src/Messages";
import { sendAPIRequest } from '../../../components/src/utils'
import { Service } from "./types";
import { parseServiceCreatedMessage, parseServiceUpdatedMessage } from './Messages'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  // selectedMyTab: number;
  services:Service[];
  modal: 'rateService' | null;
  userRole: any,
  pagination?: any,
  currentServiceId?: any;
  currentService?: any;
  deleteServiceId?: any;
  serviceDeleteCallId?: any;
  // eventID: string,
  // allCategories: any[];
  // allSubCategories: any[];
  // categoryOptions: any[];
  // subCategoryOptions: any[];
  // selectedPreferences: string[];
  loadingServices: boolean,
  // loadingPosts: boolean;
  // filterQuery: string;
  modalRating: number;
  threedotAnchorEl?:any;
  handleThreeDotClose?:any;
  urlSearchParam?: any;
  filterQuery?: any;
  selectedService?: Service;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ServicesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  serviceListRequestId: string = ""
  userRoleCallId: string = ""
  serviceFilterCallId: string = ""
  apiPaginateCallId: string = ""
  rateServiceCallId: string = ""
  apiUnfavCallIds: any[] = []
  favCallIdToPostId: any = {}
  apiFavCallIds: any[] = []
  unfavCallIdToPostId: any = {}
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.GetAllCategoriesResponseMessage),
      getName(MessageEnum.GetAllSubCategoriesResponseMessage),
      getName(MessageEnum.NewServiceCreatedMessage),
      getName(MessageEnum.ServiceUpdatedMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      modal: null,
      token:'',
      // selectedMyTab: 1,
      services:[],
      userRole: "",
      modalRating: 0,
      // allCategories: [],
      // allSubCategories: [],
      // categoryOptions: [],
      // subCategoryOptions: [],
      // selectedPreferences: [],
      loadingServices: false,

      // loadingPosts: false,
      // eventID: '',
      // filterQuery: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    parseServiceCreatedMessage(message, this.handleServiceCreatedMessage)
    parseServiceUpdatedMessage(message, this.handleServiceUpdatedMessage)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log({events: responseJson})
      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if(apiRequestCallId === this.serviceListRequestId  || apiRequestCallId === this.apiPaginateCallId){
          if(Array.isArray(responseJson?.services?.data)){
            const services = responseJson?.services?.data.map((e:any) => e.attributes)
            this.setState({ services, pagination: responseJson?.meta?.pagination })
          }
          this.setState({ loadingServices: false })
      } else if(apiRequestCallId === this.serviceFilterCallId){
        if(Array.isArray(responseJson?.services?.data)){
            const services = [...this.state.services, ...responseJson?.services?.data.map((e:any) => e.attributes)]
            this.setState({ services, pagination: responseJson?.meta?.pagination })
          }
          this.setState({ loadingServices: false })
      } else if (apiRequestCallId === this.userRoleCallId) {
        this.setState({ 
          userRole: responseJson.user_role || ''
        })
      } else if (apiRequestCallId === this.rateServiceCallId) {
        const services = this.state.services.map((p: any)=>{
          if(p.id!=this.state.selectedService?.id) return p
          return ({
              ...p, 
              is_rated: true, 
              average_rating: responseJson?.data?.attributes?.average_rating,
          })
        })
        this.setState({ modalRating: 0, modal: null, selectedService: undefined, services })
        toast.success("Rated Service Successfully")
      } else if (this.apiFavCallIds.includes(apiRequestCallId)){
          const postId = this.favCallIdToPostId[apiRequestCallId]
          this.apiFavCallIds = this.apiFavCallIds.filter((x:any) => x !== apiRequestCallId)
          delete this.favCallIdToPostId[apiRequestCallId]
          if(responseJson.data){
          const fav = {...responseJson.data, ...responseJson.data?.attributes}
          const newServices = this.state.services.map((post:any) => {
            if(String(post.id) == postId)
              return {...post, favourite_id: [fav]}
            return post
          })
          this.setState({ services: newServices })
        }
      } else if (this.apiUnfavCallIds.includes(apiRequestCallId)) {
        const postId = this.unfavCallIdToPostId[apiRequestCallId]
        const services = this.state.services.map((s: any)=>{
          if(s.id == postId){
            return {...s, favourite_id: []}
          }
          return s
        })
        this.setState({ services })
      } else if (apiRequestCallId === this.state.serviceDeleteCallId) {
        const pid = this.state.deleteServiceId
        this.closeDeleteModal()
        const services = this.state.services.filter((p: any) => p.id != pid)
        console.log({ pid, services })
        this.setState({ services })
      }
    }
    // Customizable Area End

  }

  // Customizable Area Start
  async componentDidMount(){
    const authToken= await getStorageData('authToken') || ''
    this.setState({token: authToken}, () => {
    this.getUserRole()
    this.getAllServices()
    })
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.navigation.history.location.state?.comment) {
      this.getUserRole()
      this.getAllServices()
      this.props.navigation.history.replace({ state: {} })
    }
  }

  getAllServices = async() => {
    console.log("getAllEvent ")
    this.setState({ loadingServices: true })
    const authToken = await getStorageData('authToken');
    let url = configJSON.servicesListEndPoint
    const myUrl = this.getMyUrl()
    if(myUrl)
      url = myUrl
     
    this.serviceListRequestId = sendAPIRequest(
      url, {
        method: 'GET',
        headers: { 'token': authToken || ''},
      }    
    )
  }

  handleFavClick = (id: any) => () => {
    // return 
    const service = this.state.services.find(j=>j.id==id)
    const token= this.state.token
    if(service) {
      if(service?.is_favourite && service?.favourite_id) {
        const favourite_id = service?.favourite_id[0]
        if(!favourite_id) return ;
        const endpoint = configJSON.setUnFavEndpoint.replace(':id', favourite_id.id)
        const body = new FormData()
        body.append('data[favouriteable_type]', 'BxBlockContentManagement::MarketplaceService')
        const callId = sendAPIRequest(endpoint, { method: 'DELETE', body, headers: {token} })
        this.apiUnfavCallIds.push(callId)
        this.unfavCallIdToPostId[callId] = service?.id as any
      } else {
        const endpoint = configJSON.setFavEndpoint
        const body = new FormData()
        body.append('data[favouriteable_type]', 'BxBlockContentManagement::MarketplaceService')
        body.append('data[favouriteable_id]', id)
        const callId = sendAPIRequest(endpoint, {method: 'POST', body, headers: { token }})
        this.apiFavCallIds.push(callId)
        this.favCallIdToPostId[callId] = id
      } 

      const services = this.state.services.map(
        (j: any) => {
          if(j.id!=id) return j
          return ({...j, is_favourite: !j.is_favourite})
        }
      )
      this.setState({ services })
    }
  }


  handleShareCourse = (p: any) => () => {
    let data = {
      userId: p?.created_by?.id,
      postId: p?.id,
      link: `${window?.location?.origin}/user/${JSON.parse(p?.created_by?.id || '0')}`,
      shareableType: 'BxBlockContentManagement::MarketplaceService',
      shareableId: p?.id,
    }
    sendSharePopupMessage(data);
  }

  onLikeChange = (postId: any, delta: number) => {
    const services = this.state.services.map(p => {
      if(p.id == postId){
          p.total_likes += delta;
          p.like_id = [];
      }
      return p
    })
    this.setState({ services })
  }

  onLikeIdUpdate = (postId: any, likeObj: any) => {
    const services = this.state.services.map((p: any) => {
      if (p.id == postId) {
        p.like_id = [likeObj];
      }
      return p
    })
    this.setState({ services })
  }

  goToEvent = (service_Id: any) => () => {
    const url = `${this.props.navigation.history.location.pathname}/service/${service_Id}`
    this.props.navigation.history.push(url)
  }

  onFilterQueryChange = (query: any) => {
    console.log({ urlSearchParam: query })
    this.setState({ urlSearchParam: query })
    this.onFilterChange(this.queryToString(query))
  }

  onFilterChange = async(filter: string) => {
    const token=await getStorageData('authToken') || ''
    if(filter === this.state.filterQuery) return
    this.setState({ filterQuery: filter })
    if(filter)
     {  console.log("filter value ", filter);
        let url = configJSON.gerServiceFilterSearchEndpoint
        this.serviceFilterCallId = sendAPIRequest(`${url}?${filter}`, {method: 'GET', headers: { token } })
        this.setState({ loadingServices: true, services: [] })
    }
    else 
      this.getAllServices()
  }

  
  createServiceClick = () => {
    this.props.navigation.history.push(`${window.location.pathname}/create-service`)
  }

  // handleThreeDotClose = () => {
  //   this.setState({ threedotAnchorEl: undefined })
  // }


  hideModal = () => { this.setState({ modal: null, modalRating: 0 }) }

  handleServicesRating = (e: any) => {
    console.log("check")
    const id = Number(e.currentTarget.dataset['id'])
    const selectedService = this.state.services.find(x => x.id == id )
    if(!selectedService) return 
    if(selectedService.is_rated){
      toast.warn('You have already rated this service.')
      return 
    }
    if(selectedService?.created_by?.id == this.context.id) {
      toast.warn('Cannot rate your own service')
    } else {
      this.setState({ selectedService, modal: 'rateService' })
    }
  }
  handleEnquireClick = (e: any) => {
    const id = e.currentTarget.dataset.id
    const service = this.state.services.find((s: any) => s.id==id)
    if(service?.created_by?.id == this.context.id) {
      toast.warn('Cannot enquire your own service')
      return 
    }
    this.props.navigation.history.push(`${window.location.pathname}/enquire-service/${id}`)
  }
  handleRateServices = async(value: any) => {
    const authToken = await getStorageData('authToken');
    this.rateServiceCallId = sendAPIRequest(configJSON.rateServiceEndpoint,{
      method: 'POST',
      headers: { 
        token: authToken, 
        'content-type': 'application/json' 
      },
      body: {
        "data":{
          "attributes": {
            "rating": value.rating,
            "marketplace_service_id": this.state.selectedService?.id,
          }
        }
      },
    })
  }

  setModalRating = ( modalRating: number ) => {
    this.setState({ modalRating })
  }
  
  getUserRole = async() => {
    const token=await getStorageData('authToken') || ''
    const url = configJSON.getUserRoleEndpoint
    this.userRoleCallId =  sendAPIRequest(url , { method: 'GET', headers: {token} })
  }

  handleServiceCreatedMessage = (service: any) => {
    const services = [service, ...this.state.services]
    this.setState({ services })
  }

  handleServiceUpdatedMessage = (service: any) => {
    console.log("handleservice data ", service)
    const services = this.state.services.map((j: any) => {
      if(j.id!=service.id)
        return j
      return service
    })
    this.setState({ services })
  }

  getMyUrl = ()=>{
    if(window.location.pathname.indexOf('My')>=0) {
      return "/bx_block_content_management/marketplace_services/my_marketplace"
    }
    else {
      return ''
    }
  }  

  loadNextPage = async() => {
    const query = this.state.urlSearchParam
    const isFiltersSet = query.toString().length > 0
    query.append('page', String(this.state.pagination?.next_page))
    let baseUrl = isFiltersSet ? `${configJSON.productFilterAPIEndpoint}` : configJSON.productListAPIEndpoint
    const myUrl = this.getMyUrl()
    const authToken = await getStorageData('authToken');
    if (myUrl)
      baseUrl = myUrl
    this.apiPaginateCallId = sendAPIRequest(baseUrl + "?" + this.queryToString(query), {
      method: 'GET',
      headers: { token: authToken || '' }
    })
  }

  queryToString = (queryParams: URLSearchParams) => {
    const from_date = queryParams.get('from_date')
    const to_date = queryParams.get('to_date')

    if(from_date) {
      queryParams.delete('from_date')
      queryParams.append('from_date', (new Date(from_date)).toISOString().slice(0, 10))
    }
    if(to_date) {
      queryParams.delete('to_date')
      queryParams.append('to_date', (new Date(to_date)).toISOString().slice(0, 10))
    }
    return queryParams.toString()
  }

  handleServiceReport = () => {
    sendReportMessage(this.state.currentServiceId, 'BxBlockContentManagement::MarketplaceService')
    this.setState({ threedotAnchorEl: null })
  }

  handleThreeDotClose = () => this.setState({ threedotAnchorEl: null, currentServiceId: undefined })

  closeDeleteModal = () => this.setState({
    currentServiceId: undefined,
    deleteServiceId: undefined,
    serviceDeleteCallId: undefined,
    currentService: undefined,
  })

  openThreeDot = (e: any) => {
    this.setState({ threedotAnchorEl: e.currentTarget, currentServiceId: e.currentTarget.dataset.id, currentService: this.state.services.find((x: any) => x.id == e.currentTarget.dataset.id) })
    console.log("current Event=> ", this.state.currentService);
    console.log("e.currentTarget", e.currentTarget.dataset);
  }

  handleServiceDeleteConfirm = async() => {
    const token= await getStorageData('authToken') || ''
        const serviceDeleteCallId = sendAPIRequest(configJSON.deleteServiceEndpoint.replace(':id', this.state.deleteServiceId), {
      method: 'DELETE',
      headers: { token }
    })
    this.setState({ serviceDeleteCallId })
  }

  handleServiceDelete = () => {
    this.setState({ threedotAnchorEl: null, deleteServiceId: this.state.currentServiceId })
  }

  handleServiceEdit = () => {
    console.log({ history: this.props.navigation.history })
    const url = `${this.props.navigation.history.location.pathname}/edit-service/${this.state.currentServiceId}`
    this.props.navigation.history.push(url)
    this.setState({ threedotAnchorEl: null })
    this.getAllServices();
  }

  handleRaiseRequest = (e: any) => {
    this.props.navigation.navigate('AddRequest')
    this.handleThreeDotClose()
    const requestOwner = this.context
    let assignedTo = this.state.currentService.created_by
    assignedTo = { ...assignedTo, first_name: assignedTo.name, last_name: '' }

    setTimeout(() => sendAddRequestMessage({
          disable_fields: ['created_by', 'accounts', 'request_type_id'],
          sender_id: requestOwner,
          created_by: requestOwner,
          accounts: {data: [{ id: String(assignedTo.id), attributes: assignedTo}]},
          "request_type_id": {
            "request_type": {
              "id": 7,
              "title": "service related",
            }
          }
        }), 500 // a slight delay to send after component is rendered
    )
  }
  // Customizable Area End

}