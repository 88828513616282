import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent ,Context} from 'react'
import { toast } from 'react-toastify';
import { sendAPIRequest } from '../../../components/src/utils';
export const configJSON = require("./config");
import UserContext, { User } from "../../../components/src/UserProvider";
import { getStorageData } from '../../../framework/src/Utilities';
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isProfile?: boolean;
  companyName?: string;
  // Customizable Area End
}

interface S {
  token: string;
  txtSavedValue: string;
  // Customizable Area Start
  barSeries: { name: string; data: number[];}[];
  barOptions: ApexCharts.ApexOptions;
  FactorBarSeries:{
    name: string;
    data: number[];
  }[];
  FactorBarOption: ApexCharts.ApexOptions;
  GHGEmissionsScope1Series: { name: string; data: number[]; }[];
  GHGEmissionsScope1Option: ApexCharts.ApexOptions;
  barChartForLandSeries: { name: string; data: number[]; }[];
  barChartForLandOption: ApexCharts.ApexOptions;
  barChartForWaterSeries: { name: string; data: number[]; }[];
  barChartForWaterOption: ApexCharts.ApexOptions;
  barChartForWasteSeries: { name: string; data: number[]; }[];
  barChartForWasteOption: ApexCharts.ApexOptions;
  barChartForAirSeries: { name: string; data: number[]; }[];
  barChartForAirOption: ApexCharts.ApexOptions;
  lineChartForCountrySeries: { name: string; data: number[]; }[];
  lineChartForCountryOption: ApexCharts.ApexOptions;
  lineChartForFinesSeries: { name: string; data: number[]; }[];
  lineChartForFinesOption: ApexCharts.ApexOptions;
  GHGEmissionsScope2Series: { name: string; data: number[]; }[];
  GHGEmissionsScope2Option: ApexCharts.ApexOptions;
  GHGEmissionsScope3Series: { name: string; data: number[]; }[];
  GHGEmissionsScope3Option: ApexCharts.ApexOptions;
  GHGEmissionsTotalScopeSeries: { name: string; data: number[]; }[];
  GHGEmissionsTotalScopeOption: ApexCharts.ApexOptions;
  selectedData: {
    header: string;
    tableHead: string[];
    tableData: {}[];
    isEmapty: boolean;
    key: string;
    isError: string;
  };
  emptyData: {
    header: string;
    tableHead: string[];
    tableData: {}[];
    isEmapty: boolean;
    key: string
  };
  emptyAirData: {
    header: string;
    tableHead: string[];
    tableData: {}[];
    isEmapty: boolean;
    key: string
  };
  GHGEmissionsScope1SeriesForTabel: {
    id: number;
    year: number;
    actual: number;
    target: number;
  }[];
  GHGEmissionsScope2SeriesForTabel: {
    id: number;
    year: number;
    actual: number;
    target: number;
  }[];
  GHGEmissionsScope3SeriesForTabel: {
    id: number;
    year: number;
    actual: number;
    target: number;
  }[];
  AirEmissionsSeriesForTabel: {
    id: number;
    year: number;
    actual_achieved: number;
    target_set: number;
  }[];
  WaterEmissionsSeriesForTabel: {
    id: number;
    year: number;
    water_reused: number;
    water_withdrawals: number;
    water_discharges: number;
    water_consumed: number;
    water_diversions: number;
  }[];
  barChartForWasteSeriesForTabel: {
    id: number;
    year: number;
    actual_achieved: number;
    target_set: number;
  }[]
  lineChartForCountryForTabel: {
    id: number;
    country: string;
    environmental: number;
    health: number;
    others: number;
    safety: number;
  }[]
  lineChartForFinesForTabel: {
    id: number;
    country: string;
    environmental: number;
    health: number;
    others: number;
    safety: number;
  }[]
  CategoryEmptyData: {
    tableHead: string[];
    tableData: {}[];
  };
  openModel: boolean;
  LandEmissions: {
    tableHead: string[];
    tableData: {}[];
  };
  barChartForLandSeriesForTabel: {
    id: number;
    year: number;
    land_owned: number;
    land_managed: number;
    land_leased: number;
  }[];
  WaterEmissions: {
    tableHead: string[];
    tableData: {}[];
  };
  role: boolean,
  loading: boolean,
  sdgYear:string[]
  sdgData:any[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ImpactDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  static contextType?: Context<User> = UserContext;
  sdgChart = '';
  getSdgProjects = '';
  ghgEmissionFirstScopesCallId = '';
  updateApi = '';
  ghgEmissionTotalScopes = '';
  ghgEmissionSecondScopes = '';
  ghgEmissionThirdScopes = '';
  AirEmissionsCallId = '';
  WasteEmissionsCallId = '';
  LandEmissions = '';
  WaterEmissions = '';
  FundNumbers = '';
  MonetaryFund = '';
  responsive = [
    {
      breakpoint: 1000,
      options: {
        legend: {
          position: "bottom",
          horizontalAlign: "left",
          floating: true,
          offsetY: 25,
          offsetX: 0,
        }
      }
    }
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtSavedValue: "A",
      token: '',
      // Customizable Area Start
      sdgYear:[],
      sdgData:[],
      barSeries: [],
      barOptions: {
        xaxis: {
          categories: [""],
          title: {
            text: 'Year'
          }
        },
        colors: ['#34c85a', '#fe7735', '#4c94ff'],
        plotOptions: {
          bar: {
            borderRadius: 8,
            dataLabels: {
              position: "top"
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: 10,
          formatter:(value:number, { seriesIndex,dataPointIndex}) => {
            const year = this.state.sdgYear[dataPointIndex];
            const yearData = this.state.sdgData.find(obj => Object.keys(obj)[0] == year);
            let totalData = 0;
            if (yearData) {
              const values:number[] = Object.values(yearData[year]);
              totalData = values.reduce((acc:number, value:number) => acc + value, 0);
            }
            const percentage = (value / totalData) * 100;
            return `${percentage.toFixed(2)}%`;
          },
        },
        yaxis: {
          title: {
            text: 'No of Projects'
          },
          labels: {
            formatter: function (value) {
              return Math.round(value).toString();
            }
          },
        },
        title: {
          text: 'ESG Projects over years'
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          offsetY: -30,
          offsetX: 0,
          markers: {
            strokeWidth: 30,
            radius: 30
          },
          itemMargin: {
            vertical: 5
          }
        },
        chart: {
          fontFamily: 'roboto, sans-serif, Arial',
          toolbar: {
            show: false
          },
        },
        stroke: {
          colors: ["transparent"],
          width: 10
        },
        responsive: this.responsive
      },
      FactorBarSeries: [],
      FactorBarOption: {
        xaxis: {
          categories: [""],
          title: {
            text: 'SDG Factors'
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            columnWidth: '95%',
            dataLabels: {
              position: "top"
            }
          }
        },
        theme: {
          palette: 'palette1'
        },
        stroke: {
          colors: ["transparent"],
          width: 25
        },
        dataLabels: {
          enabled: true,
          offsetY: 10,
          formatter:(value:number, { series, seriesIndex }) => {
            const percentage = this.calculatePercentageSDG(value,seriesIndex,this.state.FactorBarSeries );
            return percentage;
          },
        },
        yaxis: {
          title: {
            text: 'No of Projects'
          },
          labels: {
            formatter: function (value) {
              return Math.round(value).toString();
            }
          },
        },
        title: {
          text: 'Projects created by SDGs'
        },
        chart: {
          fontFamily: 'roboto, Arial, sans-serif',
          toolbar: {
            show: false
          },
        },
        legend: {
          markers: {
            strokeWidth: 20,
            radius: 20,
          },
          itemMargin: {
            horizontal: 10
          },
        }
      },
      GHGEmissionsScope1Series: [],
      GHGEmissionsScope1Option: {
        xaxis: {
          categories: [],
          title: {
            text: 'Year'
          }
        },
        dataLabels: {
          enabled: true,
          background: {
            padding: 10,
          }
        },
        colors: ['#fe9c73', '#09980f'],
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -30,
          offsetX: 0,
          markers: {
            radius: 2,
          }
        },
        stroke: {
          curve: "straight"
        },
        yaxis: {
          title: {
            text: 'GHG Emissions (MtCO2-e)'
          }
        },
        chart: {
          toolbar: {
            show: false
          },
          fontFamily: 'roboto, Arial, sans-serif',
        },
        title: {
          text: 'Scope 1 GHG Emissions'
        },
        responsive: this.responsive
      },
      GHGEmissionsScope2Series: [],
      GHGEmissionsScope2Option: {
        xaxis: {
          categories: [],
          title: {
            text: 'Year'
          }
        },
        dataLabels: {
          enabled: true,
          background: {
            padding: 10,
          }
        },
        colors: ['#fe9c73', '#09980f'],
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -30,
          offsetX: 0,
          markers: {
            strokeWidth: 12,
            radius: 2,
          }
        },
        stroke: {
          curve: "straight"
        },
        chart: {
          fontFamily: 'roboto, Arial, sans-serif',
          toolbar: {
            show: false
          },
        },
        yaxis: {
          title: {
            text: 'GHG Emissions (MtCO2-e)'
          }
        },
        title: {
          text: 'Scope 2 GHG Emissions'
        },
        responsive: this.responsive
      },
      GHGEmissionsScope3Series: [],
      GHGEmissionsScope3Option: {
        xaxis: {
          categories: [],
          title: {
            text: 'Year'
          }
        },
        dataLabels: {
          enabled: true,
          background: {
            padding: 10,
          }
        },
        colors: ['#fe9c73', '#09980f'],
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -30,
          offsetX: 0,
          markers: {
            radius: 2,
            strokeWidth: 12,
          }
        },
        stroke: {
          curve: "straight"
        },
        yaxis: {
          title: {
            text: 'GHG Emissions (MtCO2-e)'
          }
        },
        title: {
          text: 'Scope 3 GHG Emissions'
        },
        chart: {
          fontFamily: 'roboto, Arial, sans-serif',
          toolbar: {
            show: false
          },
        },
        responsive: this.responsive
      },
      GHGEmissionsTotalScopeSeries: [],
      GHGEmissionsTotalScopeOption: {
        xaxis: {
          categories: [],
          title: {
            text: 'Year'
          }
        },
        dataLabels: {
          enabled: true,
          background: {
            padding: 10,
          }
        },
        colors: ['#fe9c73', '#09980f'],
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -30,
          offsetX: 0,
          markers: {
            strokeWidth: 12,
            radius: 2,
          }
        },
        stroke: {
          curve: "straight"
        },
        yaxis: {
          title: {
            text: 'GHG Emissions (MtCO2-e)'
          }
        },
        title: {
          text: 'Total GHG Emissions'
        },
        chart: {
          fontFamily: 'roboto, Arial',
          toolbar: {
            show: false
          },
        },
        responsive: this.responsive
      },
      barChartForLandSeries: [],
      barChartForLandOption: {
        xaxis: {
          categories: [],
          title: {
            text: 'Year'
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#5ea4e8', '#91e2a1', '#fea959'],
        legend: {
          horizontalAlign: "left",
          markers: {
            strokeWidth: 12,
            radius: 2,
          }
        },
        yaxis: {
          title: {
            text: 'Hectares'
          },
        },
        title: {
          text: 'Land owned, Leased and managed',
          offsetX:-8,
          style:{
            fontSize:"14px"
          }
        },
        chart: {
          fontFamily: 'roboto, Arial, sans-serif',
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            columnWidth: '60%'
          }
        },
        stroke: {
          colors: ["transparent"],
          width: 10
        },
        responsive: [
          {...this.responsive[0],
            options: {
              bar: {
                columnWidth: '10%'
              },
              stroke: {
                strokeWidth: 12
              }
            }
          }
        ]
      },
      barChartForWaterSeries: [],
      barChartForWaterOption: {
        xaxis: {
          categories: [],
          title: {
            text: 'Year'
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#b9ece7', '#ffc7c7', '#fea959', '#5ea4e8', '#91e2a1'],
        legend: {
          horizontalAlign: "left",
          markers: {
            strokeWidth: 12,
            radius: 2,
          }
        },
        yaxis: {
          title: {
            text: 'ML'
          }
        },
        title: {
          text: 'Water'
        },
        chart: {
          fontFamily: 'roboto, Arial, sans-serif',
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            columnWidth: '70%'
          }
        },
        stroke: {
          colors: ["transparent"],
          width: 8
        },
        responsive: [
          {...this.responsive[0],
            options: {
              bar: {
                columnWidth: '10%'
              },
              stroke: {
                width: 10
              }
            }
          }
        ]
      },
      barChartForWasteSeries: [],
      barChartForWasteOption: {
        xaxis: {
          categories: [],
          title: {
            text: 'Year'
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#c7d2ce', '#7fa08b'],
        legend: {
          horizontalAlign: "left",
          markers: {
            strokeWidth: 12,
            radius: 2,
          }
        },
        yaxis: {
          title: {
            text: 'Kilotonnes'
          }
        },
        title: {
          text: 'Waste'
        },
        chart: {
          fontFamily: 'roboto, Arial, sans-serif',
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            columnWidth: '50%'
          }
        },
        stroke: {
          colors: ["transparent"],
          width: 10
        },
      },
      barChartForAirSeries: [],
      barChartForAirOption: {
        xaxis: {
          categories: [],
          title: {
            text: 'Year'
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#aed4e2', '#7cbecb'],
        yaxis: {
          title: {
            text: 'Metric tonnes'
          }
        },
        title: {
          text: 'Air Emissions'
        },
        chart: {
          fontFamily: 'roboto, Arial, sans-serif',
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            columnWidth: '50%'
          }
        },
        stroke: {
          colors: ["transparent"],
          width: 10
        },
        legend: {
          horizontalAlign: "left",
          markers: {
            strokeWidth: 12,
            radius: 2,
          }
        },
      },
      lineChartForCountrySeries: [],
      lineChartForCountryOption: {
        xaxis: {
          categories: [],
          title: {
            text: 'Country'
          }
        },
        dataLabels: {
          enabled: true,
          background: {
            padding: 10,
          }
        },
        colors: ['#f6794a', '#39c95d', '#3d61ef', '#2c2c2e'],
        legend: {
          horizontalAlign: "left",
          markers: {
            strokeWidth: 12,
            radius: 2,
          }
        },
        stroke: {
          curve: "straight",
          width: [2, 2, 2, 2],
        },
        yaxis: {
          title: {
            text: 'Number of Fines'
          },
        },
        title: {
          text: 'Fine by Country'
        },
        subtitle: {
          text: '(Last Financial Year)'
        },
        chart: {
          fontFamily: 'roboto, Arial, sans-serif',
          toolbar: {
            show: false
          },
        },
      },
      lineChartForFinesSeries: [],
      lineChartForFinesOption: {
        xaxis: {
          categories: [],
          title: {
            text: 'Country'
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return "$" + val;
          },
          background: {
            padding: 10,
          }
        },
        colors: ['#f6794a', '#39c95d', '#3d61ef', '#2c2c2e'],
        legend: {
          horizontalAlign: "left",
          markers: {
            strokeWidth: 12,
            radius: 2,
          }
        },
        stroke: {
          curve: "straight",
          width: [2, 2, 2, 2],
        },
        yaxis: {
          title: {
            text: 'Total monetary value of fines (US$)'
          },
          labels: {
            formatter: function (y) {
              return "$" + y.toFixed(0);
            }
          }
        },
        title: {
          text: 'Fine by monetary value of fines (US$)',
          offsetX:-8,
          style:{
            fontSize:"13.5px"
          }
        },
        subtitle: {
          text: '(Last Financial Year)'
        },
        chart: {
          fontFamily: 'roboto, Arial',
          toolbar: {
            show: false
          },
        },
      },
      selectedData: {
        header: 'none',
        tableHead: ['Year', 'Actual Achieved', 'Target Set'],
        tableData: [{ year: 0, actual: 0, target: 0 }, { year: 0, actual: 0, target: 0 },
        { year: 0, actual: 0, target: 0 }, { year: 0, actual: 0, target: 0 },
        { year: 0, actual: 0, target: 0 }],
        isEmapty: false,
        key: '',
        isError: ""
      },
      emptyData: {
        header: 'none',
        tableHead: ['Year', 'Actual Achieved', 'Target Set'],
        tableData: [{ year: 0, actual: 0, target: 0 }, { year: 0, actual: 0, target: 0 },
        { year: 0, actual: 0, target: 0 }, { year: 0, actual: 0, target: 0 },
        { year: 0, actual: 0, target: 0 }],
        isEmapty: false,
        key: ''
      },
      emptyAirData: {
        header: 'none',
        tableHead: ['Year', 'Actual Achieved', 'Target Set'],
        tableData: [{ year: 0, actual_achieved: 0, target_set: 0 }, { year: 0, actual_achieved: 0, target_set: 0 },
        { year: 0, actual_achieved: 0, target_set: 0 }, { year: 0, actual_achieved: 0, target_set: 0 },
        { year: 0, actual_achieved: 0, target_set: 0 }],
        isEmapty: false,
        key: ''
      },
      GHGEmissionsScope1SeriesForTabel: [],
      GHGEmissionsScope2SeriesForTabel: [],
      GHGEmissionsScope3SeriesForTabel: [],
      barChartForWasteSeriesForTabel: [],
      barChartForLandSeriesForTabel: [],
      AirEmissionsSeriesForTabel: [],
      WaterEmissionsSeriesForTabel: [],
      lineChartForCountryForTabel: [],
      lineChartForFinesForTabel: [],
      LandEmissions: {
        tableHead: ['Year', 'Land owned', 'Land Leased', 'Land managed'],
        tableData: [{ year: 0, land_owned: 0, land_leased: 0, land_managed: 0 }, { year: 0, land_owned: 0, land_leased: 0, land_managed: 0 },
        { year: 0, land_owned: 0, land_leased: 0, land_managed: 0 }, { year: 0, land_owned: 0, land_leased: 0, land_managed: 0 },
        { year: 0, land_owned: 0, land_leased: 0, land_managed: 0 }],
      },
      WaterEmissions: {
        tableHead: ['Year', 'Water Reused', 'Water Withdrawals', 'Water discharges', 'Water Consumed', 'Water Diversions'],
        tableData: [{ year: 0, water_reused: 0, water_withdrawals: 0, water_discharges: 0, water_consumed: 0, water_diversions: 0 }, { year: 0, water_reused: 0, water_withdrawals: 0, water_discharges: 0, water_consumed: 0, water_diversions: 0 },
        { year: 0, water_reused: 0, water_withdrawals: 0, water_discharges: 0, water_consumed: 0, water_diversions: 0 }, { year: 0, water_reused: 0, water_withdrawals: 0, water_discharges: 0, water_consumed: 0, water_diversions: 0 },
        { year: 0, water_reused: 0, water_withdrawals: 0, water_discharges: 0, water_consumed: 0, water_diversions: 0 }],
      },
      CategoryEmptyData: {
        tableHead: ['Country', 'Environmental', 'Health', 'Other', 'Safety'],
        tableData: [{ country: '', environmental: 0, health: 0, others: 0, safety: 0 }, { country: '', environmental: 0, health: 0, others: 0, safety: 0 },
        { country: '', environmental: 0, health: 0, others: 0, safety: 0 }, { country: '', environmental: 0, health: 0, others: 0, safety: 0 },
        { country: '', environmental: 0, health: 0, others: 0, safety: 0 }]
      },
      openModel: false,
      role: false,
      loading: true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const corporateId = this.props?.companyName ? this.props?.companyName :  this.context.id
    const authToken = await getStorageData("authToken")
    if (authToken == null) {
      this.props.navigation.navigate('Home')
    }
    if(this.context?.role?.role_level > 4 && this.context.user_type != "licensed_user"){
      this.setState({
        role: true
      })
    }else{
      toast.warn("Sorry, You do not have access to this page")
      this.navigationToFun("ActivityFeed",this.props)    
    }
    this.setState({token: authToken}, () => {
      this.getSdgChart(corporateId);
      this.getAllSdgProjects(corporateId);
      this.getGhgEmissionFirstScopes(corporateId);
      this.getGhgEmissionSecondScopes(corporateId);
      this.getGhgEmissionThirdScopes(corporateId);
      this.getGhgEmissionTotalScopes(corporateId);
      this.getAirEmissions(corporateId);
      this.getWasteEmissions(corporateId);
      this.getLandEmissions(corporateId);
      this.getWaterEmissions(corporateId);
      this.getFundNumbers(corporateId);
      this.getMonetaryFund(corporateId);
    })
    
    this.setState({ loading: false });
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        switch (apiRequestCallId) {
          case this.sdgChart:
            this.handleSdgYearData(responseJson.percentage_factors);
            break;

          case this.getSdgProjects:
            this.handleGetSdgProjects(responseJson.yearly_sdg_projects);
            break;

          case this.ghgEmissionFirstScopesCallId:
            this.handleGhgEmissionFirstScopesCallId(responseJson);
            break;

          case this.updateApi:
            this.handleEditDataPoint(responseJson);
            break;

          case this.ghgEmissionTotalScopes:
            this.handleGhgEmissionTotalScopes(responseJson);
            break;

          case this.ghgEmissionSecondScopes:
            this.handleGhgEmissionSecondScopes(responseJson);
            break;

          case this.ghgEmissionThirdScopes:
            this.handleGhgEmissionThirdScopes(responseJson);
            break;

          case this.AirEmissionsCallId:
            this.handleAirEmissionsCallId(responseJson);
            break;

          case this.WasteEmissionsCallId:
            this.handleWasteEmissionsCallId(responseJson);
            break;

          case this.LandEmissions:
            this.handleLandEmissions(responseJson);
            break;

          case this.WaterEmissions:
            this.handleWaterEmissions(responseJson);
            break;

          case this.FundNumbers:
            this.handleFundNumbers(responseJson);
            break;

          case this.MonetaryFund:
            this.handleMonetaryFund(responseJson);
            break;
          default:
            if (responseJson.errors) {
              this.parseApiErrorResponse(responseJson.errors);
              this.parseApiCatchErrorResponse(responseJson.errors);
            }
            break;
        }
      }
    }
    // Customizable Area End

  }

  // Customizable Area Start

  handleSubmit = (values: any) => {
    return values
  }
  handleEditDataPoint = (responseJson: any) => {
    responseJson?.errors?.length && responseJson?.errors[0]?.data?.length ? toast.error(responseJson?.errors[0]?.data[0]) : window.location.reload();
    window.location.reload();
  }
  getSdgChart = async(corporateId:string) => {
    const token = await getStorageData("authToken")
    this.sdgChart = sendAPIRequest(`${configJSON.getSdgChart}?corporate_id=${corporateId}`, {
      method: 'GET',
      headers: { token }
    })
  }

  getAllSdgProjects = async(corporateId:string) => {
    const token = await getStorageData("authToken")
    this.getSdgProjects = sendAPIRequest(`${configJSON.getAllSdgProjects}?corporate_id=${corporateId}`, {
      method: 'GET',
      headers: { token }
    })
  }

  getGhgEmissionFirstScopes = async(corporateId:string) => {
    const token = await getStorageData("authToken")

    this.ghgEmissionFirstScopesCallId = sendAPIRequest(`${configJSON.getGhgEmissionFirstScopes}?corporate_id=${corporateId}`, {
      method: 'GET',
      headers: { token }
    })
  }

  getGhgEmissionSecondScopes = async(corporateId:string) => {
    const token = await getStorageData("authToken")

    this.ghgEmissionSecondScopes = sendAPIRequest(`${configJSON.getGhgEmissionSecondScopes}?corporate_id=${corporateId}`, {
      method: 'GET',
      headers: { token }
    })
  }

  getGhgEmissionThirdScopes = async(corporateId:string) => {
    const token = await getStorageData("authToken")

    this.ghgEmissionThirdScopes = sendAPIRequest(`${configJSON.getGhgEmissionThirdScopes}?corporate_id=${corporateId}`, {
      method: 'GET',
      headers: { token }
    })
  }

  getGhgEmissionTotalScopes = async(corporateId:string) => {
    const token = await getStorageData("authToken")

    this.ghgEmissionTotalScopes = sendAPIRequest(`${configJSON.getGhgEmissionTotalScopes}?corporate_id=${corporateId}`, {
      method: 'GET',
      headers: { token }
    })
  }

  getLandEmissions = async(corporateId:string) => {
    const token = await getStorageData("authToken")

    this.LandEmissions = sendAPIRequest(`${configJSON.getLandEmissions}?corporate_id=${corporateId}`, {
      method: 'GET',
      headers: { token }
    })
  }

  getWaterEmissions = async(corporateId:string) => {
    const token = await getStorageData("authToken")

    this.WaterEmissions = sendAPIRequest(`${configJSON.getWaterEmissions}?corporate_id=${corporateId}`, {
      method: 'GET',
      headers: { token }
    })
  }

  getAirEmissions = async(corporateId:string) => {
    const token = await getStorageData("authToken")

    this.AirEmissionsCallId = sendAPIRequest(`${configJSON.getAirEmissions}?corporate_id=${corporateId}`, {
      method: 'GET',
      headers: { token }
    })
  }

  getWasteEmissions = async(corporateId:string) => {
    const token = await getStorageData("authToken")

    this.WasteEmissionsCallId = sendAPIRequest(`${configJSON.getWasteEmissions}?corporate_id=${corporateId}`, {
      method: 'GET',
      headers: { token }
    })
  }

  getFundNumbers = async(corporateId:string) => {
    const token = await getStorageData("authToken")

    this.FundNumbers = sendAPIRequest(`${configJSON.getFundNumbers}?corporate_id=${corporateId}`, {
      method: 'GET',
      headers: { token }
    })
  }

  getMonetaryFund = async(corporateId:string) => {
    const token = await getStorageData("authToken")

    this.MonetaryFund = sendAPIRequest(`${configJSON.getMonetaryFund}?corporate_id=${corporateId}`, {
      method: 'GET',
      headers: { token }
    })
  }

  handleClose = () => {
    this.setState({ ...this.state, openModel: false });
  }

  handleOpen = (name: string, head: string, apiName: string, table: string) => {
    const data: any = this.state?.[name as keyof Object];
    const header = head;
    if (data?.length) {
      const tableHead = Object.keys(data[0]).map((item) => {
        let result;
        if (item === 'target') {
          result = 'Target Set';
        } else if (item === 'actual') {
          result = 'Actual Achieved';
        } else {
          result = item;
        }
        return result.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
      });
      const isEmapty = tableHead?.length ? false : true;
      const tableData = data;
      this.setState({
        ...this.state, openModel: true,
        selectedData: {
          header,
          tableData,
          tableHead,
          isEmapty,
          key: apiName,
          isError: ""
        }
      });
    } else {
      const data = this.state?.[table as keyof Object];
      this.setState({
        ...this.state, openModel: true,
        selectedData: {
          ...this.state.selectedData, ...data,
          isEmapty: true,
          key: apiName,
          header
        }
      });
    }
  }

  handleUpdateData = async(detail:any) => {
    const selectedData: {
      header: string;
      tableHead: string[];
      tableData: {}[];
      isEmapty: boolean;
      key: string;
    } = this.state.selectedData;
    const body = selectedData?.tableData;
    const isValidData = this.checkDuplicateKey(body) 
    const isYearValid = this.checkYear(body)
    const checkCountry = this.checkCountry(body)
    if (!isValidData.hasDuplicates && !isYearValid.isZero && checkCountry.isValid){
      const token = await getStorageData("authToken")

      if (selectedData?.isEmapty) {
        this.updateApi = sendAPIRequest(configJSON[`create${selectedData && selectedData.key}`], {
          method: 'POST',
          body,
          headers: {
            "Content-Type": "application/json",
            token
          }
        })
      } else {
        this.updateApi = sendAPIRequest(configJSON[`update${selectedData && selectedData.key}`], {
          method: 'PATCH',
          body,
          headers: {
            "Content-Type": "application/json",
            token
          }
        })
      }
      this.setState({ ...this.state, openModel: false });
    }
    else if(isValidData.keyName === 'country'){
        toast.error(`Please provide a valid data in ${isValidData.keyName}. Country field can not be: 1. Empty  2. Numbers.`);
        this.setState({ ...this.state, selectedData:{...this.state.selectedData,isError: isValidData?.value} });

      }else{
        toast.error(`Please provide a valid data in ${isValidData.keyName}. It can not be: 1. Duplicate 2. Zero  3. In decimal.`);
        this.setState({ ...this.state, selectedData:{...this.state.selectedData,isError: isValidData?.value} });
      }
     
  }
  isFirstColumnDuplicate = () => {
    const selectedData: {
      header: string;
      tableHead: string[];
      tableData: {}[];
      isEmapty: boolean;
      key: string;
    } = this.state.selectedData;
    const body = selectedData?.tableData;
    const firstItem = body?.[0] as {[key: string]: number | string}
    const keys = Object.keys(firstItem || {});
    const values = keys.map((key) => (firstItem[key] || '').toString().toLowerCase());
  
    const duplicateValues = new Set<string>();
    const duplicateIndices: number[] = [];
  
    for (let i = 0; i < values.length; i++) {
      const value = values[i];
      if (duplicateValues.has(value)) {
        duplicateIndices.push(i);
      } else {
        duplicateValues.add(value);
      }
    }
  
    return duplicateIndices;
  };

  handleSdgYearData = (responseJson: { [key: string]: { [key: string]: number } }[]) => {
    if (responseJson?.length) {
      const years: string[] = [];
      const factorsData: { [name: string]: number[] } = {};
  
      // Iterate through each object in responseJson
      responseJson.forEach((data: { [key: string]: { [key: string]: number } }) => {
        const year: string[] = Object.keys(data);
        const factors = data[year[0]] || {};
  
        // Add the year to years array
        if (year.length) {
          years.push(year[0]);
        }

        // Iterate through each factor in factors
        Object.entries(factors).forEach(([factorName, value]) => {
          factorsData[factorName] = factorsData[factorName] || Array(years.length).fill(0);
          const index = years.indexOf(year[0]);
          if (index !== -1) {
            factorsData[factorName][index] = value || 0;
          }
        });
      });
  
      // Transform factorsData into the desired output format
      const formatedSeries = Object.entries(factorsData).map(([name, data]) => ({
        name,
        data
      }));
  
      // Update state with the transformed data
      this.setState({
        ...this.state,
        barOptions: {
          ...this.state.barOptions,
          xaxis: {
            ...this.state.barOptions.xaxis,
            categories: years
          }
        },
        sdgData: responseJson,
        sdgYear: years,
        barSeries: formatedSeries
      });
    }
  }

  handleGhgEmissionScopes = (responseJson: { data: [] }, isTotalData: boolean) => {
    const Year: number[] = [];
    let GHGEmissionsScopeOption: { name: string; data: number[]; }[] = []
    const scData = responseJson.data?.length ? responseJson.data : [];
    const scopeOption = isTotalData ? responseJson.data.sort((a: { year: number }, b: { year: number }) => b.year - a.year)?.slice(0,5) : scData;
    const keys = ['actual_achieved', 'target_set', 'actual', 'target', 'land_owned', 'land_managed', 'land_leased', 'water_reused', 'water_withdrawals', 'water_discharges', 'water_consumed', 'water_diversions', 'environmental', 'health', 'others', 'safety','other'];
    scopeOption.forEach((data: { target: number, year: number, country: number }) => {
      const sdgKey = Object.keys(data);
      sdgKey?.length && sdgKey.filter((key: string) => keys.includes(key)).map((item) => {
        const value = item
        const dataValue = data?.[value as keyof Object].toString() || "0"
        GHGEmissionsScopeOption = [...GHGEmissionsScopeOption,
        { name: item, data: [Number(Number(dataValue).toFixed(2))] }
        ];
      })
      Year.push(data.year || data.country);
    });
    let seen: {
      [key: string]: {
        name: string;
        data: number[]
      }
    } = {};
    GHGEmissionsScopeOption = GHGEmissionsScopeOption.filter(function (entry) {
      let previous: { name: string, data: number[] };
      if (seen.hasOwnProperty(entry.name)) {
        previous = seen[entry.name as keyof object];
        entry.data?.length && previous.data.push(...entry.data);
        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen[entry.name] = entry;
      return true;
    });
    const tableData = responseJson.data.map((data: { id: number, target: number, year: number, actual: number }) => {
      return {
        id: data?.id,
        year: data?.year,
        actual: data?.actual,
        target: data?.target
      }
    })
    const Series = GHGEmissionsScopeOption.map((item) => {
      let name;
      switch (item.name) {
        case 'target':
        case 'target_set':
          name = 'Target Set';
          break;
        case 'actual':
        case 'actual_achieved':
          name = 'Actual Achieved';
          break;
        default:
          name = item.name;
          break;
      }
      return { name: name, data: item.data }
    });
    return {
      year: Year,
      GhgEmissionScopes: Series,
      tableData
    }
  }

  handleCahnge = (event: ChangeEvent<HTMLInputElement>, index: number, value: string) => {
    const tableData = this.state.selectedData.tableData;
    const selected = value === 'country' ?  event.target.value.toUpperCase() : Number(event.target.value);
    if(value === 'year' && event.target.value?.length > 4){
      return false;
    }
    tableData[index] = { ...this.state.selectedData.tableData[index], [value]: selected}
    this.setState({
      ...this.state,
      selectedData: {
        ...this.state.selectedData,
        tableData
      }
    })
  }
  handleGetSdgProjects = (responseJson: []) => {
    const FactorBarSeries = responseJson?.length ? responseJson?.map(({ name, data }: { name: string, data: number }) => {
      return {
        name,
        data: [Number(data.toFixed(2))]
      }
    }) : [{ name: '', data: [0] }];
    if (responseJson?.length) {
      this.setState({
        ...this.state,
        FactorBarSeries
      });
    }
  }
  handleGhgEmissionFirstScopesCallId = (responseJson: { data: [], role: {name : string} }) => {
    if (responseJson?.data?.length) {
      const GhgEmission = this.handleGhgEmissionScopes(responseJson, false);
      this.setState({
        ...this.state,
        GHGEmissionsScope1Option: {
          ...this.state.GHGEmissionsScope1Option,
          xaxis: {
            ...this.state.GHGEmissionsScope1Option.xaxis,
            categories: GhgEmission.year
          }
        },
        GHGEmissionsScope1Series: GhgEmission.GhgEmissionScopes,
        GHGEmissionsScope1SeriesForTabel: GhgEmission.tableData
      });
    }
  }
  handleGhgEmissionTotalScopes = (responseJson: { data: [] }) => {
    if (responseJson?.data?.length) {
      const GhgEmission = this.handleGhgEmissionScopes(responseJson, true);
      this.setState({
        ...this.state,
        GHGEmissionsTotalScopeOption: {
          ...this.state.GHGEmissionsTotalScopeOption,
          xaxis: {
            ...this.state.GHGEmissionsTotalScopeOption.xaxis,
            categories: GhgEmission.year
          }
        },
        GHGEmissionsTotalScopeSeries: GhgEmission.GhgEmissionScopes,
      });
    }
  }
  handleGhgEmissionSecondScopes = (responseJson: { data: [] }) => {
    if (responseJson?.data?.length) {
      const GhgEmission = this.handleGhgEmissionScopes(responseJson, false);
      this.setState({
        ...this.state,
        GHGEmissionsScope2Option: {
          ...this.state.GHGEmissionsScope2Option,
          xaxis: {
            ...this.state.GHGEmissionsScope2Option.xaxis,
            categories: GhgEmission.year
          }
        },
        GHGEmissionsScope2Series: GhgEmission.GhgEmissionScopes,
        GHGEmissionsScope2SeriesForTabel: GhgEmission.tableData
      });
    }
  }
  handleGhgEmissionThirdScopes = (responseJson: { data: [] }) => {
    if (responseJson?.data?.length) {
      const GhgEmission = this.handleGhgEmissionScopes(responseJson, false);
      this.setState({
        ...this.state,
        GHGEmissionsScope3Option: {
          ...this.state.GHGEmissionsScope3Option,
          xaxis: {
            ...this.state.GHGEmissionsScope3Option.xaxis,
            categories: GhgEmission.year
          }
        },
        GHGEmissionsScope3Series: GhgEmission.GhgEmissionScopes,
        GHGEmissionsScope3SeriesForTabel: GhgEmission.tableData
      });
    }
  }
  handleAirEmissionsCallId = (responseJson: { data: [] }) => {
    if (responseJson?.data?.length) {
      const AirEmission = this.handleGhgEmissionScopes(responseJson, false);
      const tableData = responseJson.data.map((data: { id: number, target: number, year: number, actual: number, target_set: number, actual_achieved: number }) => {
        return {
          id: data?.id,
          year: data?.year,
          actual_achieved: data?.actual_achieved || 0,
          target_set: data?.target_set || 0
        }
      })
      this.setState({
        ...this.state,
        barChartForAirOption: {
          ...this.state.barChartForAirOption,
          xaxis: {
            ...this.state.barChartForAirOption.xaxis,
            categories: AirEmission.year
          }
        },
        barChartForAirSeries: AirEmission.GhgEmissionScopes,
        AirEmissionsSeriesForTabel: tableData
      })
    }
  }
  handleWasteEmissionsCallId = (responseJson: { data: [] }) => {
    if (responseJson?.data?.length) {
      const WasteEmission = this.handleGhgEmissionScopes(responseJson, false);
      const tableData = responseJson.data.map((data: { id: number, target: number, year: number, actual: number, target_set: number, actual_achieved: number }) => {
        return {
          id: data?.id,
          year: data?.year,
          actual_achieved: data?.actual_achieved || 0,
          target_set: data?.target_set || 0
        }
      })
      this.setState({
        ...this.state,
        barChartForWasteOption: {
          ...this.state.barChartForWasteOption,
          xaxis: {
            ...this.state.barChartForWasteOption.xaxis,
            categories: WasteEmission.year
          }
        },
        barChartForWasteSeries: WasteEmission.GhgEmissionScopes,
        barChartForWasteSeriesForTabel: tableData
      })
    }
  }
  handleLandEmissions = (responseJson: { data: [] }) => {
    if (responseJson?.data?.length) {
      const LandEmissions = this.handleGhgEmissionScopes(responseJson, false);
      const barChartForLandSeriesForTabel = responseJson.data.map((data: { id: number, land_owned: number, year: number, land_managed: number, land_leased: number }) => {
        return {
          id: data?.id,
          year: data?.year,
          land_owned: data?.land_owned,
          land_managed: data?.land_managed,
          land_leased: data?.land_leased
        }
      })
      this.setState({
        ...this.state,
        barChartForLandSeries: LandEmissions.GhgEmissionScopes.map((item) => {
          return { name: item.name.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' '), data: item.data }
        }),
        barChartForLandOption: {
          ...this.state.barChartForLandOption,
          xaxis: {
            ...this.state.barChartForLandOption.xaxis,
            categories: LandEmissions.year
          }
        },
        barChartForLandSeriesForTabel
      })
    }
  }
  handleWaterEmissions = (responseJson: { data: [] }) => {
    if (responseJson?.data?.length) {
      const LandEmissions = this.handleGhgEmissionScopes(responseJson, false);
      const WaterEmissionsSeriesForTabel = responseJson.data.map((data: { id: number, year: number, water_reused: number, water_withdrawals: number, water_discharges: number, water_consumed: number, water_diversions: number }) => {
        return {
          id: data?.id,
          year: data?.year,
          water_reused: data?.water_reused,
          water_withdrawals: data?.water_withdrawals,
          water_discharges: data?.water_discharges,
          water_consumed: data?.water_consumed,
          water_diversions: data?.water_diversions
        }
      })
      this.setState({
        ...this.state,
        barChartForWaterSeries: LandEmissions.GhgEmissionScopes.map((item) => {
          return { name: item.name.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' '), data: item.data }
        }),
        barChartForWaterOption: {
          ...this.state.barChartForWaterOption,
          xaxis: {
            ...this.state.barChartForWaterOption.xaxis,
            categories: LandEmissions.year
          }
        },
        WaterEmissionsSeriesForTabel
      })
    }
  }
  handleFundNumbers = (responseJson: { data: [] }) => {
    if (responseJson?.data?.length) {
      const FundNumbers = this.handleGhgEmissionScopes(responseJson, false);
      const lineChartForCountryForTabel = responseJson.data.map((data: { id: number, country: string, environmental: number, health: number, others: number, safety: number }) => {
        return {
          id: data?.id,
          country: data?.country,
          environmental: data?.environmental,
          health: data?.health,
          others: data?.others,
          safety: data?.safety,
        }
      })  
      this.setState({
        ...this.state,
        lineChartForCountrySeries: FundNumbers.GhgEmissionScopes.map((item)=> {
          return {
            name: item.name[0].toUpperCase() + item.name.substring(1),
            data: item.data
          }
        }),
        lineChartForCountryOption: {
          ...this.state.lineChartForCountryOption,
          xaxis: {
            ...this.state.lineChartForCountryOption.xaxis,
            categories: FundNumbers.year
          }
        },
        lineChartForCountryForTabel
      })
    }
  }
  handleMonetaryFund = (responseJson: { data: [] }) => {
    if (responseJson?.data?.length) {
      const MonetaryFund = this.handleGhgEmissionScopes(responseJson, false);
      const lineChartForFinesForTabel = responseJson.data.map((data: { id: number, country: string, environmental: number, health: number, others: number, safety: number }) => {
        return {
          id: data?.id,
          country: data?.country,
          environmental: data?.environmental,
          health: data?.health,
          others: data?.others,
          safety: data?.safety,
        }
      })
      this.setState({
        ...this.state,
        lineChartForFinesSeries: MonetaryFund.GhgEmissionScopes.map((item)=> {
          return {
            name: item.name[0].toUpperCase() + item.name.substring(1),
            data: item.data
          }
        }),
        lineChartForFinesOption: {
          ...this.state.lineChartForFinesOption,
          xaxis: {
            ...this.state.lineChartForFinesOption.xaxis,
            categories: MonetaryFund.year
          }
        },
        lineChartForFinesForTabel
      })
    }
  }
  calculatePercentageSDG = (value:number,seriesIndex:number, series:{
    name: string;
    data: number[];
  }[]) => {
    let totalData = 0;
    series.forEach(obj => {
      obj.data.forEach(value => {
        totalData += value;
      });
    });
    if( totalData === 0){
      return "0%";
    }
    const percentage = (value / totalData) * 100;
    return `${percentage.toFixed(2)}%`;
  }
   checkDuplicateKey(data: any[]) {
      const seenValues = new Set<string>();
      let keyName: string | undefined
      const currentYear = new Date().getFullYear();
      for (const rowData of data) {
        if(keyName === undefined){
          keyName = "year" in rowData ? "year" : "country";
        }
        const value = rowData[keyName]
        if(!value){
          continue;
        }
        const normalizedValue = String(value).toLowerCase();
        if (keyName === "year" && (normalizedValue.length !== 4 || Number(value) > currentYear)) {
          return { hasDuplicates: true, keyName, value };
        }
        if (value && seenValues.has(normalizedValue)) {
          return {hasDuplicates: true,keyName, value};
        }
        seenValues.add(normalizedValue);
      }
      return {hasDuplicates: false,keyName};
   }  
   checkYear(data: any[]){
    let keyName: string | undefined
    for (const rowData of data) {
      if(keyName === undefined){
        keyName = "year" in rowData ? "year" : "";
      }
      const value = rowData[keyName]
        
      if (keyName === "year" && value == 0) {
          return { isZero: true, keyName, value };
      }
      if (keyName === "year" && !Number.isInteger(value)) {
          return { isZero: true, keyName, value };
      }

   }
   return {isZero: false,keyName};
  }

  hasNumbers(value: any){
    return /\d/.test(value);
  }
  checkCountry(data: any[]){
    let keyName: string | undefined
    for (const rowData of data) {
      if(keyName === undefined){
        keyName = "country" in rowData ? "country" : "";
      }
      const value = rowData[keyName]
        
      if (keyName === "country" && value == "") {
          return { isvalid: false, keyName, value };
      }
      if (keyName === "country" && this.hasNumbers(value)) {
          return { isValid: false, keyName, value };
      }

   }
   return {isValid: true,keyName};

  }
  navigationToFun = (route: string, props: Props) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);

    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    
    this.send(message);
  }

  // Customizable Area End

}