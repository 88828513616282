
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
export const configJSON = require("./config");
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import {ChangeEvent} from 'react'
import * as yup from 'yup';
import { esgPathRecords, sendAPIRequest } from "../../../components/src/utils";
import { toast } from 'react-toastify';
import { getStorageData } from '../../../framework/src/Utilities';
interface ESGCardsRecordData {
  access: {
    data: {
      id: string;
      type: string;
      attributes: {
        id: number;
        user_name: string | null;
        created_at: string;
        updated_at: string;
        email: string;
        company_name: string | null;
        ability_card: string;
        ability_type: string;
        profile_picture: string | null;
        card_serial_number: number;
        card_banner: string;
      };
    }[];
  };
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  userId: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string,
  allobjectives: any[]
  txtSavedValue: string;
  pestlein: string,
  openModal: boolean;
  Factor: boolean;
  modal: string,
  requestTypes: any[];
  filesArray: any[]
  preferences: any[];
  Issues_concern: any[];
  PestleData: any;
  objectiveId: string;
  isInput: boolean;
  anchorElForPopup: HTMLButtonElement | null;
  anchorInputForPopup: HTMLButtonElement | null;
  test: string;
  editId: {},
  editData: any,
  pages: number,
  current_page: number,
  total_pages: number | undefined,
  pages_rp: number,
  current_page_rp: number,
  total_pages_rp: number | undefined,
  addAFactor:boolean,
  pestleFactor:string,
  loading:boolean,
  recommendedProjectData:any[],
  openRPModel: boolean,
  recommendedProjectLink: string,
  objId: number,
  removedAttachments: number[]
  isdisabled: boolean,
}

interface SS {
   // Customizable Area Start
  id: any;
  // Customizable Area End
}
// Customizable Area Start
const requestTypes = [{
  name: "resourse",
}, {
  name: "air pollution",
}, {
  name: "water pollution",
}]
const PestleData: any = {
  political: [{ name: "Taxation Policy" }, { name: "Political Support And Stability" }, { name: "Grants And Funding" }, { name: "Trade Bodies" }, { name: "Bureaucracy And Corruption" }, { name: "Effect Of Wars" }, { name: "Change Of Government" }],
  Economic: [{ name: 'Overall Economic Situation' }, { name: 'GDP Growth' }, { name: 'Consumer Spending' }, { name: "Government Spending" }, { name: 'Ease Of Access To Loans' }, { name: 'Interest Rates' }, { name: 'Inflation' }, { name: 'Unemployment' }, { name: 'Taxation Policies' }, { name: 'Exchange Rates' }, { name: 'FDIs' }, { name: 'Effect Of Global Economy' }],
  Sociological_Factors: [{ name: 'Demographics' }, { name: 'Lifestyle Patterns And Changes' }, { name: 'Attitudes' }, { name: 'Values' }, { name: 'Social Mobility' }, { name: 'Media Views And Perceptions' }, { name: 'Ethnic And Religious Differences' }, { name: 'Carbon Footprint' }],
  Technological_Factors: [{ name: 'Future Technology Innovations' }, { name: 'Research Funding' }, { name: 'Increase In Online Usage' }, { name: 'Intellectual Property Rights' }, { name: 'Copyright Infringements' }, { name: 'Global Communication' }],
  Legal_Factors: [{ name: 'Legislation In Employment' }, { name: 'Competition And Health & Safety' }, { name: 'Future Legislation Changes' }, { name: 'Changes In Protection Laws' }, { name: 'Trading Policies' }, { name: 'Regulatory Bodies' }],
  Enviromental_Factors: [{ name: 'Industrial Pollution' }, { name: 'Recycling Considerations' }, { name: 'Attitudes To The Environment' }, { name: 'Current And Future Environmental Regulations' }, { name: 'Carbon Footprint' }],
}
const pestleFilter :any=[
  "Taxation Policy","Political Support And Stability","Grants And Funding","Trade Bodies","Bureaucracy And Corruption","Effect Of Wars","Change Of Government","Overall Economic Situation","GDP Growth","Consumer Spending","Goverment Spending","Ease Of Access To Loans","Interest Rates","Inflation","Unemployment","Taxation Policies","Exchange Rates","FDIs","Effect Of Global Economy","Demographics",
  "Lifestyle Patterns And Changes","Attitudes","Values","Social Mobility","Media Views And Perceptions","Ethnic And Religious Differences","Carbon Footprint","Future Technology Innovations",
  "Research Funding","Increase In Online Usage","Intellectual Property Rights","Copyright Infringements","Global Communication",
  "Legislation In Employment","Competition And Health & Safety","Future Legislation Changes","Changes In Protection Laws","Trading Policies","Regulatory Bodies","Industrial Pollution","Recycling Considerations",
  "Attitudes To The Environment","Current and Future Environmental Regulations","Carbon Footprint"
]

// Customizable Area End
export default class RiskManageMentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  esgCardsAccessAPIId: string = ""
  apiFetchObjectiveData: string = ""
  apiFetchobjectId: string = ""
  apiFetchIssuesId: string = ""
  apiAddObjectiveCallId: string = ""
  showdataCallId: string = ""
  apiEditObjectivCallId: string = ""
  apiRecommdedPro: string = ""
  apiRecommendedProjectCallID: string = ""
  allPreferences = [{ id: 'E', label: "E - Environmental" }, { id: 'S', label: "S - Social" }, { id: 'G', label: "G - Governance" }]

  validationSchema = yup.object({
    objectiveId: yup.string().label('ObjectId').required().matches(/^[a-zA-Z0-9 .!?"-]+$/, "Only alphabets and numbers are accepted"),
    description: yup.string().label('Description').required().max(1000),
    preferences: yup.array().min(1, 'Select atleast one Preference').required(),
    issues: yup.string().label('issues ').required(),
    pestle: yup.string().label('pestle ').required(),
    Scope_of_Operation: yup.string().label('Scope of  operation').required(),
    categories: yup.string().label('Category').required(),
    Reference: yup.string().label('Reference').required(),
    attachments: yup.mixed().when('Reference', { is: value => value , then: yup.mixed().label("Upload Document").required("Upload document") }),
    probability: yup.number().label('Probability').required(),
    Impact_range: yup.number().label('Impact').required(),
    Types_of_impact: yup.string().label('Type Of Impact').required(),
    Estimated_Financial_Impact: yup.string().label('Estimated Financial Impact').required(),
    Estimated_amount_of_impact: yup.string().required("Estimated Amount of Impact is a required field.").matches(/^[0-9 .!?"-]+$/, "Enter Numeric Value"),
    Impacted_Stakeholders: yup.string().label('Impacted Stakeholder').required(),
    Action_plan: yup.string().label('Action Plan').required(),
    Impact_description: yup.string().label('Impact Description').required()
  })
  handleSubmit = async (data: any) => {
    this.setState({loading:true})
    let authToken = await getStorageData('authToken');

    const body = new FormData()
    body.append('objective[objective_id]', data.objectiveId as string)
    data.attachments.forEach((file: any) => {if(!file?.id){body.append('objective[pdf_documents][]', file)}})
    data.preferences.forEach((id: any) => body.append(`objective[preferences][]`, id))
    body.append('objective[issues_concern]', data.issues as string)
    body.append('objective[pestile]', data.pestle as string)
    body.append('objective[scope_of_operations]', data.Scope_of_Operation as string)
    body.append('objective[description]', data.description as string)
    body.append('objective[category]', data.categories as string)
    body.append('objective[category_please_specify]', data.category_please_specify as string)
    body.append('objective[reference]', data.Reference as string)
    body.append('objective[impact]', data.Impact_range)
    body.append('objective[impact_probability]', data.probability)
    body.append('objective[materiality]', `${data.Impact_range},${data.probability}`)
    body.append('objective[type_of_impact]', data.Types_of_impact as string)
    body.append('objective[impact_on_enterprise_value]', data.Estimated_Impact_on_Enterprise as string)
    body.append('objective[impact_on_finance]', data.Estimated_Financial_Impact as string)
    body.append('objective[estimated_amount_of_impact]', data.Estimated_amount_of_impact as string)
    body.append('objective[impact_term]', data.Impact_Term as string)
    body.append('objective[action_plan]', data.Action_plan as string)
    body.append('objective[impacted_stakeholders]', data.Impacted_Stakeholders as string)
    body.append('objective[impacted_stakeholders_please_specify]', data.impacted_stakeholders_please_specify as string)
    body.append('objective[impact_desc]', data.Impact_description as string)

    
    if (this.state.editData) {

      const {token} = this.state;
      if (this.state.removedAttachments.length > 0) {
        const removeAttachmentUrl =  configJSON.deleteEditObjectiveAttachmentEndPoint
                     
        const removeAttachmentBody = {objective_id: data.objectiveId, ids: this.state.removedAttachments };
        const removeAttachmentPromise = new Promise<void>((resolve, reject) => {
          sendAPIRequest(removeAttachmentUrl, {
            method: 'POST',
            headers: { token,'content-type': 'application/json' },
            body: removeAttachmentBody,
          });
          resolve();
        });
        await removeAttachmentPromise;
      }

      this.apiEditObjectivCallId = sendAPIRequest(configJSON.EditRisk, {
        method: 'PUT',
        headers: {
          'token': authToken,
        },
        body
      })
    }
    else {
      this.apiAddObjectiveCallId = sendAPIRequest(configJSON.PostObjectivesofRisk, {
        method: 'POST',
        headers: {
          'token': authToken,
        },
        body
      })
    }
  }

  removeAttachments = (attachmentId:number) => {
    if(attachmentId){
        this.setState(prevState => ({
            removedAttachments: [...prevState.removedAttachments, attachmentId]
        }))
    }
}

  setFilesArray = (file: any) => {
    this.setState({ filesArray: [file] });
  }
  
  handlePageChange = (event: any, value: any) => {
    this.setState({ pages: value }, this.getAllObjectives)
  }
  handlePageChangeInRP = (event: any, value: any) => {
    this.setState({ pages_rp: value }, this.getRecommendedProject2);
    
  }
  
  handleEdit = async(id: any) => {
    let authToken = await getStorageData('authToken');

    if(this.state.isdisabled){
      toast.warn("You do not have permission to perform this action")
    }
    else{
    this.showdataCallId = sendAPIRequest(`${configJSON.showRisk}`, {
      method: 'POST',
      headers: {
        'token': authToken, 'content-type': 'application/json'
      },
      body: {
        "objective": {
          "objective_id": `${id}`
        }
      }
    })
    this.setState({ modal: 'create', editId: id },()=>{
      const filterPestleData = pestleFilter.filter((e:string)=>(
        e === this.state.editData?.attributes?.pestile
      ))
      
      if(filterPestleData.length>0){
        this.setState({addAFactor:false})
      }
      else{
        this.setState({addAFactor:true})
      }
    })
  }
  }
  handleProjectEval = (id:any,state:boolean)=>{
    if(this.state.isdisabled){
      toast.warn("You do not have permission to perform this action")
    }
    else{
      const url = `ProjectEvaluation/${id}`
      
      this.props.navigation.history.replace({pathname:url,state})
    }
     
  }
  getShowData = async() => {
    let authToken = await getStorageData('authToken');
    this.apiFetchIssuesId = sendAPIRequest(configJSON.getIssuesandConcern, {
      method: 'POST',
      headers: { 'token': authToken, 'content-type': 'application/json' },
      body: { preferences: this.state.editData.attributes.preferences }
    })
  }

  handleRecomendProject = () => {
    if (this.state.recommendedProjectLink) {
      window.open(this.state.recommendedProjectLink)
    }
    else {
      toast.warn("No recommended projects")
    }
  }
  headerTitles = (edit:any)=>{
    return  edit?"EDIT OBJECTIVE":"ADD OBJECTIVE"
   
   }
   getAttribute = (attributeName:any, defaultValue:any = '') => {
     return this.state.editData?.attributes?.[attributeName] ?? defaultValue;
   }
   getObjectiveId = ()=>{
    return  this.state.editId || this.state.objectiveId
   }

   getCondition1materiality = (mat: any, condition1materiality: any) => {
    return condition1materiality>= mat.value[0] && condition1materiality <= mat.value[1]
   }

   getIssuesFlag = (initiaIssueValue: any) => {
    return this.state.Issues_concern.length > 0 || initiaIssueValue
   }
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: '',
      txtSavedValue: "A",
      allobjectives: [],
      openModal: true,
      requestTypes: [...requestTypes],
      preferences: [],
      filesArray: [],
      PestleData: { ...PestleData },
      objectiveId: '',
      Issues_concern: [],
      isInput: false,
      anchorElForPopup: null,
      anchorInputForPopup: null,
      pestlein: '',
      loading:false,
      test: '',
      modal: '',
      editId: '',
      editData: {},
      Factor: true,
      total_pages: undefined,
      pages: 1,
      current_page: 1,
      pages_rp: 1,
      current_page_rp: 1,
      total_pages_rp: undefined,
      addAFactor:true,
      pestleFactor:'',
      recommendedProjectData:[],
      openRPModel:false,
      recommendedProjectLink:"",
      objId: 0,
      removedAttachments: [],
      isdisabled: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getAllObjectives = async() => {
    let authToken = await getStorageData('authToken');
    
    this.apiFetchObjectiveData = sendAPIRequest(`${configJSON.getAllObjectivesofRisk}/${this.state.pages}/10`, {
      method: 'GET',
      headers: { 'token': authToken }
    })
  }
  getRecommendedProject = async(row:any) => {
    let authToken = await getStorageData('authToken');

    const recommendedProjectURL =  row.attributes.recommended_projects_report.url;    
    const objID = row.attributes?.objective_id
    this.setState({ recommendedProjectLink: recommendedProjectURL , objId: objID})
    this.apiRecommendedProjectCallID = sendAPIRequest(`${configJSON.getRecommendedProject}/?objective_id=${objID}&page=1&per_page=10`, {
      method: 'GET',
      headers: { 'token': authToken }
    })
  }
  getRecommendedProject2 = async() => {
    let authToken = await getStorageData('authToken');

    this.apiRecommendedProjectCallID = sendAPIRequest(`${configJSON.getRecommendedProject}/?objective_id=${this.state.objId}&page=${this.state.pages_rp}&per_page=10`, {
      method: 'GET',
      headers: { 'token': authToken }
    })
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }

    const apiRequestCall = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.errors) {
      this.parseApiErrorResponse(responseJson.errors);
      this.parseApiCatchErrorResponse(responseJson.errors);
    } else if (responseJson) {
      if (this.apiFetchObjectiveData === apiRequestCall) {
        this.setState({ allobjectives: responseJson.objectives.data })
        const current_page = responseJson?.current_page
        const total_pages = responseJson?.total_pages
        this.setState({ total_pages, current_page,loading:false })
      }
      switch (apiRequestCall) {
              case this.apiFetchobjectId:
                this.setState({ objectiveId: responseJson.objective_id });
                break;
              case this.apiFetchIssuesId:
                this.setState({ Issues_concern: responseJson.issues });
                break;
              case this.apiAddObjectiveCallId:
                this.getAllObjectives()
                this.setState({ modal: '',loading:false })
                toast.success("Created Successfully")
                break;
              case this.showdataCallId:
                this.setState({ editData: responseJson?.objective?.data},()=>{
                  const filterPestleData = pestleFilter.filter((e:string)=>(
                    e === this.state.editData?.attributes?.pestile
                  ))
                  if(filterPestleData.length>0){
                    this.setState({addAFactor:true})
                  }
                  else{
                    this.setState({addAFactor:false})
                  }
                })
                this.getShowData()
                break;
              case this.apiEditObjectivCallId:
                this.getAllObjectives()
                this.setState({ modal: '',loading:false })
                toast.success("Updated Successfully")
                break;
              case this.apiRecommendedProjectCallID:
                this.setRecommendedProject(responseJson)
                break;
              case this.esgCardsAccessAPIId : 
                this.handleEsgPageAuth(responseJson)
              default:
                break;
            }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  SelectPreferences = async(e:ChangeEvent<HTMLSelectElement>) => {
    let authToken = await getStorageData('authToken');

        this.apiFetchIssuesId = sendAPIRequest(configJSON.getIssuesandConcern, {
          method: 'POST',
          headers: { 'token': authToken, 'content-type': 'application/json' },
          body: { preferences: e.target.value }
        })
  }
  handleAddObj = async() => {
    let authToken = await getStorageData('authToken');

    this.setState({ modal: 'create', editData: '',Issues_concern:[],editId: '', preferences: [],addAFactor:true}, () => {
      this.apiFetchobjectId = sendAPIRequest(configJSON.getObjectiveIdOfRisk, {
        method: 'GET',
        headers: { 'token': authToken },

      })
    },)
  }
  handleClose = () => {
    this.setState({ ...this.state, openRPModel: false,recommendedProjectData:[],recommendedProjectLink:""});
  }

  setRecommendedProject = (responseJson:any) => {
    if (responseJson.projects?.data && responseJson.projects?.data.length) {
      this.setState({ recommendedProjectData: responseJson.projects?.data ,openRPModel:true, current_page_rp: responseJson.projects.meta.pagination.current_page, total_pages_rp: responseJson.projects.meta.pagination.total_pages})
    }
    else {
      toast.warn("No Recommended Projects")
    }
  }
 
  async componentDidMount() {
    let authToken = await getStorageData('authToken');
    const userData = await getStorageData('user');
    const userRole = JSON.parse(userData);
    let isLicensed = userRole?.role?.role_level < 5 || userRole.user_type === "licensed_user"
    if(isLicensed){
      this.esgCardsAccess(authToken)
    }
    this.setState({loading:true,token:authToken},() => {
    const access = this.props?.navigation?.history?.location?.state?.ability_type
    if (access == "read") {
      this.setState({ isdisabled: true })
    }
    this.getAllObjectives()
    if (this.state.openModal) {
      this.apiFetchobjectId = sendAPIRequest(configJSON.getObjectiveIdOfRisk, {
        method: 'GET',
        headers: { 'token': authToken },

      })
    }
  })}
  navigate = (route: string, props: Props) => {
    const navigate = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), props);   
    this.send(navigate);
  }
  esgCardsAccess = async (authToken:string) => {
    this.esgCardsAccessAPIId = sendAPIRequest(`${configJSON.showAllCards}`, {
      method: "GET",
      headers: {token: authToken},
    })
  }
  handleEsgPageAuth(esgData:ESGCardsRecordData){
    let ability = esgPathRecords(esgData)
    if(!ability){
      let warnMessage = "Sorry, You do not have access to this page"
      let link = "ActivityFeed"
      toast.warn(warnMessage)
      this.navigate(link,this.props)   
    }
  }
  // Customizable Area End
}
