import React, { Component } from "react";
// Customizable Area Start
import { withStyles, styled } from "@material-ui/styles";
import {
  Card,
  Typography,
  Button,
  CardHeader,
  CardContent,
  Box,
  ButtonBase,
  Grid,
} from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import CustomisableusersubscriptionsController, {
  Props,
  configJSON,
} from "./CustomisableusersubscriptionsController";
import ChoosePackageSubscribtionPopup from "../../../components/src/ChoosePackageSubscribtionPopup.web";
import ChooseLicenseSubscribtionPopup from "../../../components/src/ChooseLicenseSubscribtionPopup.web";
import { LoadingSpinner } from "../../../components/src/LoadingSpinner.web";
import SeeMoreSubscribtion from "../../../components/src/SeeMoreSubscribtion.web";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { view_Path } from "./assets"
import DashboardLayout from '../../../components/src/DashboardLayout.web';
import PaymentSuccessfull from "./PaymentSuccessfull.web";
import PaymentStripe from "./PaymentStripe.web";
// Customizable Area End
export default class SubscribtionPlan extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { navigation } = this.props
    const subscribtionList = this.state.subscribtionList.sort((a: any, b: any)=>b.attributes.role_level-a.attributes.role_level)
    const requestButton = (ele: any, index: any)=>{
      if(ele.attributes.status === 'Approved')
        return <RequestText>
                <ApprovedText>Approved
                </ApprovedText>
              </RequestText>
      if(ele.attributes.status === 'Pending' && ele.attributes.is_purchaseable == false)
        return <RequestText>
                <Pending> 
                  <div style={{width: '8px', height:'8px', borderRadius: '100%', background:'#FF8000', marginRight: '15px'}}></div>
                  <span>Request Pending</span>
                </Pending>
              </RequestText>
      if(ele.attributes.status === 'Declined' && ele.attributes.is_purchaseable == false)
        return <CardButton
                  onClick={() => this.requestPlan(ele)}
                  variant="contained" color="primary" disableElevation
                >
                  Request Plan
                </CardButton>
      if(ele.attributes.is_purchaseable) return <></>
      return  <CardButton
                onClick={() => this.requestPlan(ele)}
                variant="contained" color="primary" disableElevation
              >
              Request Plan
              </CardButton>
    }

    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {!this.state.loader && this.state.subscribtionList?.length === 0 && <Typography style={{ marginTop: '40px', textAlign: 'center' }}>No Match Found</Typography>}
        {
          this.state.loader ? <LoadingSpinner /> :

          subscribtionList.map((ele: any, index: any) => {

              return (
              <RoundedCard
                // style={{ padding: '15' }}
                variant="outlined"
                >
                 <CardTitleBox>
                        <TextPriceHeading>
                        {ele.attributes.name}
                          <span style={{marginLeft:"8px",fontWeight:500,fontSize:"16px"}}>({ele.attributes.role})</span>
                        </TextPriceHeading>
                        <TextPriceHeading>
                        ${ele.attributes.price.split('.')[0]}
                         <span style={{marginLeft:"8px",fontWeight:500,fontSize:"16px"}}>(₹{ele.attributes.price_inr})</span>
                        </TextPriceHeading>
       
                       </CardTitleBox>
                       <CardDescBox>
                         <Box
                         style={{
                           marginRight:"20px",
                          
                         }}
                         >
                         <TextSubHeading>Members <span style={{textTransform:"lowercase"}}>include</span><span style={{marginLeft:"2px",marginRight:"4px"}}>:</span>
                         <span style={{fontWeight:800,marginRight:"30px",}}>{ele.attributes.member_covered}</span>
                         </TextSubHeading>
       
                         </Box>
                         <Box
                         style={{
                          marginRight:"20px"
                        }}
                         >
                         <TextSubHeading>Period<span style={{marginLeft:"9px",marginRight:"4px"}}>:</span>
                         <span style={{fontWeight:800}}>{ele.attributes.period} <span style={{fontWeight:800,marginRight:"30px"}}>Months</span></span>
                         </TextSubHeading>

                         </Box>
                        
                       
                 
                       </CardDescBox>
                       <Box
                         style={{
                          marginRight:"20px"
                        }}
                         >
                            {
                    (ele.attributes.role == 'Corporate Plus' || ele.attributes.role == 'portfolio manager' || ele.attributes.role == 'Corporate') &&
                         <TextSubHeading>PM/Corporate Licence Covered<span style={{marginLeft:"3px",marginRight:"4px"}}>:</span>
                         <span style={{fontWeight:800,marginRight:"30px"}}>{ele.attributes.license_coverd}</span>
                         </TextSubHeading>}

                         </Box>
                  
                      
                       <DescBox>
                         
                         <Box>
                      <TextSubHeading style={{
                        marginRight:"10px"
                      }}>
                      <SeeMoreSubscribtion text={ele.attributes.description} limit={75} />
                        </TextSubHeading> 
                       </Box>
                       <RequestPlanButton>
                        {requestButton(ele, index)}
                        </RequestPlanButton>
                       </DescBox>
                       <ChoosePackageBox>

                      { (ele.attributes.role == "Corporate Plus" || 
                        ele.attributes.role == "Corporate" || 
                        ele.attributes.role == "portfolio manager") ?
                        <>
                       <Box>
                         <CardButtonLicense
                        disabled={ele.attributes.license_coverd===null}
                        style={ele.attributes.license_coverd ===null? { backgroundColor: 'C9C9C9' } : { backgroundColor: "F3F8F3" }}
                        
                        >
                        <span style={{ fontWeight: 900, marginRight: "4px" }}>Add more licences</span><BreakingTag/><span style={{ fontWeight: 400, fontStyle: "italic" }}>(${ele.attributes.additional_pm_corpo_license_usd}/ ₹{ele.attributes.additional_pm_corpo_license_inr} per licence per month)</span>
                      </CardButtonLicense>
                        {/* <RequestPlanButton>
                        {requestButton(ele, index)}
                        </RequestPlanButton> */}
                         </Box>
                         <ChoosePackageButton>
                      {
                          
                          ((typeof ele.attributes.status === 'string' && ele.attributes.status !== 'Declined') || ele.attributes.is_purchaseable )  &&
                        
                      <CardButton
                        onClick={() => this.choosePackagePopUp(ele,index)}
                        disabled={ele.attributes.is_purchased || !ele.attributes.is_purchaseable}
                        variant="contained" 
                        color="primary" 
                        disableElevation
                      > 
                        Choose Package
                      </CardButton>
                    }
                         </ChoosePackageButton>
                         </>
                         :
                         <>
                         <span></span>
                         <ChoosePackageButton>
                      {
                          
                          ((typeof ele.attributes.status === 'string' && ele.attributes.status !== 'Declined') || ele.attributes.is_purchaseable )  &&
                        
                      <CardButton
                        onClick={() => this.choosePackagePopUp(ele,index)}
                        disabled={ele.attributes.is_purchased || !ele.attributes.is_purchaseable}
                        variant="contained" 
                        color="primary" 
                        disableElevation
                      > 
                        Choose Package
                      </CardButton>
                    }
                         </ChoosePackageButton>
                         </>
                        }
                         
                         </ChoosePackageBox>
                  
          
              </RoundedCard>
              
       
           
           
              );
            })

        }
        {this.state.choosePackPopup && (
          // console.log("POPUPppp")
          <ChoosePackageSubscribtionPopup
            {...this.props}
            open={this.state.choosePackPopup}
            closepopup={this.closeChoosePackPopup}
            subscribtionList={this.state.subscribtionList}
            subscribtionPopupdata={this.state.subscribtionListPopUpData}
            subscriptionReqProps={this.getCorporateVariableCost}
            requestpmCorporatetValue={this.state.requestpmCorporatetValue}
            handlesubscribtionindividualReqProps={this.getindividualVariableCost}
            requestindividualValue={this.state.requestindividualValue}
            variablePmCorporateINRCost={this.variablePmCorporateCost}
            payServiceProvider={this.payNowServiceProvider}
            pay={this.postPaymentDetails}
            usd={this.state.dollar}
            payment={this.payNowFuncton}
             
          />

        )}

        {this.state.addMoreLicensePopUp && (
          <ChooseLicenseSubscribtionPopup
            open={this.state.addMoreLicensePopUp}
            closepopup={this.closeAddMoreLicensePopUp}
            subscribtionList={this.state.subscribtionList}
          />
        )}

      {
        this.state.stripeOpen && 
        <PaymentStripe navigation={navigation} id={""} route={undefined} data={undefined}/>
      }
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
//*********** styles ***************//
const CradDetails = styled(Box)({
  display:"flex",
  flexWrap:"wrap",
  flexDirection:"column",
 
});
const CardButton = styled(Button)({
  width: "153px",
  height: "38px",
  // backgroundColor: "#009B07",
  // color: "#FFFFFF",
  marginTop: "1px",
  textTransform: "capitalize",
  fontWeight: 700,
  "&:hover": {
    // background: "#009B07",
    opacity: 0.9,
    // color: "FFFFFF",
    // borderRadius: "009B07",
  }
});
const ChoosePackageButton = styled(Box)({
  marginTop: "25px",
  '@media screen and (max-width: 1212px) and (min-width: 769px)': {
    textAlign: "center",
    marginTop: "15px"
  },
  '@media screen and (max-width: 624px)': {
    textAlign: "center",
    marginTop: "15px"
  }
});
const RequestPlanButton = styled(Box)({
  marginTop: "15px",
  '@media screen and (max-width: 1212px) and (min-width: 769px)': {
    textAlign: "center",
    marginTop: "15px"
  },
  '@media screen and (max-width: 624px)': {
    textAlign: "center",
    marginTop: "15px"
  }
});
const CardButtonLicense = styled(Button)(({ theme }) => ({
  border: "none",
  color: "#515C51",
  fontWeight: 600,
  backgroundColor: "#C9C9C9",
  textTransform: "none",
  borderRadius: "10px",
  width: "auto",
  padding: "15px 25px",
  height: "auto",
  marginTop:"25px",
  "&:hover": {
    background: "#C9C9C9",
    opacity: 0.9,
    color: "#515C51",
    borderRadius: "009B07",
  },
  '@media screen and (max-width: 1212px) and (min-width: 769px)': {
    display: "block",
  },
  '@media screen and (max-width: 624px)': {
    display: "block",
  }
}));
const RoundedCard = styled(Card)({
  borderRadius: '15px',
  padding: '20px 30px',
  margin: '20px 0px',
  border: "1.71815px solid transparent",
  transition: 'all 0.1s',
  "&:hover": {
    background: " #EEF5EE",
    color: "#000000",
    border: "1.71815px solid #00A100",
  },
});
const CardDescBox = styled(Box)({
  display:"flex",
  marginTop:"8px",
  '@media screen and (max-width: 1150px) and (min-width: 769px)': {
    display: "block",
  },
  '@media screen and (max-width: 500px)': {
    display: "block",
  }
});
const CardTitleBox = styled(Box)({
  display:"flex",
  justifyContent:"space-between",
  alignItems:"center",
  '@media screen and (max-width: 1150px) and (min-width: 769px)': {
    display:"flex",
  },
  '@media screen and (max-width: 500px)': {
    display:"flex",
  }
});
const DescBox = styled(Box)({
  display:"flex",
  justifyContent:"space-between",
  '@media screen and (max-width: 1212px) and (min-width: 769px)': {
    display: "unset",
  },
  '@media screen and (max-width: 624px)': {
    display: "unset",
  }
});
const ChoosePackageBox = styled(Box)({
  display:'flex',
  justifyContent:"space-between",
  '@media screen and (max-width: 1212px) and (min-width: 769px)': {
    display: "unset",
  },
  '@media screen and (max-width: 624px)': {
    display: "unset",
  }
});
const TextPlanHeading = styled(Typography)({
  color: "#000000",
  fontSize: "16px",
  marginBottom: "8px",
});
const TextSubHeading = styled(Typography)({
  color: "#000000",
  fontSize: "14px",
  marginBottom: "9px",
  fontWeight: 400,
  fontFamily: "helvetica"

});
const TextPriceHeading = styled(Typography)({
  color: "#000000",
  fontSize: "19px",
  fontWeight: 800,
  textTransform:"capitalize",
  fontFamily: "HelveticaNeue"
});
const RequestText = styled(Typography)({
  display:"flex",
  flexWrap:"nowrap",
  paddingTop:"25px",
  color: "FF8000",
  fontSize: "14px",
  fontWeight: 400,
  paddingRigth: "50px",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "helvetica"
});
const ApprovedText = styled(Typography)({
  color: "#009B07",
  fontSize: "14px",
  fontWeight: 800,
  paddingRigth: "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "helvetica"
});
const DeclinedText = styled(Typography)({
  color: "red",
  fontSize: "14px",
  fontWeight: 800,
  paddingRigth: "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "helvetica"
});
const Pending = styled(Typography)({
  color: "FF8000",
  fontSize: "14px",
  fontWeight: 800,
  paddingRigth: "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "helvetica"
});
const ActiveText = styled(Typography)({
  color: "#00A100",
  fontSize: "12px",
  fontWeight: "bold",
  marginBottom:"20px"
});

const PageWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  minHeight: '100vh'
})
const BreakingTag = styled('br')({
  '@media screen and (min-width: 900px) and (max-width: 769px)': {
    display: "none",
  },
  '@media screen and (min-width: 380px)': {
    display: "none",
  }
})
const CenterRow = styled('div')(({theme}) => ({ 
  // maxWidth: '60vw',
  width: '100%',

}))
  // Customizable Area End

