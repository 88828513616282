import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils';
import { toast } from 'react-toastify';
import { sendSharePopupMessage } from '../../share/src/Messages';
import moment from 'moment'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  data?: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openModal: boolean;
  selectedCollabrators: any[];
  activeAllProjectTab: boolean;
  showSeekingFundTab: boolean;
  searchInputValue: string;
  txtSavedValue: string;
  profileDashboardData: any[];
  activeProjectTab: boolean;
  activeSeekingFundTab: boolean;
  filterMenuPosition: {
    top: number,
    left: number,
  } | undefined;
  filterText: string;
  tableRowId: any | null;
  showCollaborators: boolean;
  filterByAll: boolean;
  filterByStatus: boolean;
  filterByPrefrence: boolean;
  allProjectsData: any[];
  collabratorsList: any[];
  seekingFundsData: any[];
  viewProjectData: any;
  particularProject: any;
  sdgFocousData: any;
  openContributros: boolean;
  userID: number | undefined;
  fetchCollaborats: boolean;
  openSeekingFunds: boolean;
  filteredProjects: any;
  filterBySDGFactorsId: boolean;
  filterByDimensions: boolean;
  filterByCreatedOn: boolean;
  filterByCreatedBy: boolean;
  Overlayloader: boolean;
  loader: boolean;
  indexValue: any;
  showSdg: boolean;
  selectedItem:any[];
  selectedName:any[];
  showSurvey: boolean;
  selectingDate?: boolean;
  filterToDate?: Date | null;
  filterFromDate?: Date | null;
  showProjectTab: boolean;
  serviceData: any;
  createdOn: any;
  allProjects: boolean;
  token: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyProjectsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiAllProjectsUnfavJobCallIds: string[] = []
  favCallIdToPostId: { [key: string]: string } = {}
  allProjectsfavCallIdToPostId: { [key: string]: string } = {}
  unfavCallIdToPostId: { [key: string]: string } = {}
  allProjectsunfavCallIdToPostId: { [key: string]: string } = {}
  filterRef?: any;
  apiUserLikeCountCallId: string = '';
  getSeekingFundsList: any;
  getSeekingFundsListById: any;
  getAllProjectsData: any;
  apiFavJobCallIds: string[] = []
  getAllCollabrators: any;
  projectDetailById: any;
  sdfFocous: any;
  filterMyProjects: any;
  apiAllProjectFavJobCallIds: string[] = [];
  addCollabrator: any;
  filterProjectsBYCategories: any;
  getProfileDashboardData: any;
  apiUnfavJobCallIds: string[] = []
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showProjectTab: true,
      serviceData: {},
      fetchCollaborats: false,
      showSdg: false,
      showCollaborators: false,
      indexValue: "",
      selectedItem:[],
      selectedName:[],
      particularProject: {},
      Overlayloader: false,
      loader: false,
      openContributros: false,
      collabratorsList: [],
      seekingFundsData: [],
      allProjects: true,
      selectedCollabrators: [],
      openModal: false,
      sdgFocousData: [],
      tableRowId: null,
      viewProjectData: [],
      activeAllProjectTab: true,
      filteredProjects: [],
      createdOn: "",
      activeSeekingFundTab: false,
      filterMenuPosition: undefined,
      filterText: "",
      filterByAll: false,
      filterByStatus: false,
      allProjectsData: [],
      openSeekingFunds: false,
      filterBySDGFactorsId: false,
      filterByDimensions: false,
      filterByCreatedOn: false,
      filterByCreatedBy: false,
      userID: undefined,
      showSurvey:false,
      selectingDate: false,
      filterToDate: null,
      filterFromDate: null,
      showSeekingFundTab: false,
      txtSavedValue: "",
      filterByPrefrence: false,
      profileDashboardData: [],
      activeProjectTab: true,
      searchInputValue: "",
      token: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.filterRef = React.createRef()
    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    const authToken = await getStorageData('authToken');
    if (authToken == null) {this.props.navigation.navigate('Home')}
    const userData = await getStorageData('user');
    const userId = userData ? (JSON.parse(userData)).id : undefined;
    this.setState({ Overlayloader: true, loader: true, userID: userId ,token: authToken}, () => { 
    
    if(this.props.navigation.history.location.pathname=='/my_projects'){
      this.getProfileDashboard('')
    }
    else{
      this.getAllProjects('')
    }
    this.getSDGFocousData()
    this.getSeekingFunds()
  })
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.setState({ txtSavedValue: value });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage) );

      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (responseJson && responseJson.errors) {
        this.setState({  
          loader: false,
          Overlayloader: false
        })
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      }
      if (apiRequestCallId == this.getAllProjectsData) {
        this.setState({allProjectsData: responseJson?.data,loader: false })
      }
      if (apiRequestCallId == this.getProfileDashboardData) {
        console.log("ProfileDashboard",responseJson)
        this.setState({profileDashboardData: responseJson?.data,Overlayloader: false})
      }

      if(apiRequestCallId ==this.apiUserLikeCountCallId){
        this.handleLikeCount(responseJson);
      }
      if (apiRequestCallId == this.getSeekingFundsList) {
        this.setState({ seekingFundsData: responseJson?.data })
      }
      if (apiRequestCallId == this.filterProjectsBYCategories) {
        this.setState({ allProjectsData: responseJson?.projects?.data,loader:false })
      }
      if (apiRequestCallId == this.getSeekingFundsListById) {
        this.setState({ viewProjectData: responseJson?.data })
      }
      if (apiRequestCallId == this.projectDetailById) {
        this.setState({ particularProject: responseJson.project.data })
      }
      if (apiRequestCallId == this.sdfFocous) {
        this.setState({ sdgFocousData: responseJson?.data })
      }
      if (apiRequestCallId == this.filterMyProjects) {
        this.setState({ profileDashboardData: responseJson?.projects?.data , Overlayloader:false})
      }
      if (this.apiFavJobCallIds.includes(apiRequestCallId)) {
        const postId = this.favCallIdToPostId[apiRequestCallId]
        this.apiFavJobCallIds = this.apiFavJobCallIds.filter((x: any) => x !== apiRequestCallId)
        if (responseJson?.data) {
          delete this.favCallIdToPostId[apiRequestCallId]

          const fav = { ...responseJson.data, ...responseJson.data?.attributes }
          const favData =
          {
            id: fav?.id,
            favourite_by_id: fav?.attributes?.favourite_by_id,
            created_at: fav?.attributes?.created_at,
            updated_at: fav?.attributes?.updated_at,
            favouriteable_id: fav?.attributes?.favouriteable_id,
            favouriteable_type: fav?.attributes?.favouriteable_type
          }
          const newPosts = this.state.profileDashboardData.map((post: any) => {
            if (Number(post.id) == Number(postId)) {
              post.attributes.favourite_id = [favData]
            }
            return post
          })
          this.setState({ profileDashboardData: newPosts })
        }
      }
      if (this.apiUnfavJobCallIds.includes(apiRequestCallId)) {
        if(responseJson?.message){
        const id = this.unfavCallIdToPostId[apiRequestCallId]
        const profileDashboardData = this.state.profileDashboardData.map((j: any) => {
          if (j.id == id) {
            j.attributes.favourite_id = []
          }
          return j
        })
        this.setState({ profileDashboardData })
        }
      }
      if (this.apiAllProjectFavJobCallIds.includes(apiRequestCallId)) {
        const postId = this.allProjectsfavCallIdToPostId[apiRequestCallId]
        this.apiAllProjectFavJobCallIds = this.apiAllProjectFavJobCallIds.filter((x: any) => x !== apiRequestCallId)
        if (responseJson?.data) {
          delete this.allProjectsfavCallIdToPostId[apiRequestCallId]

          const fav = { ...responseJson.data, ...responseJson.data?.attributes }
          const favData =
          {
            id: fav?.id,
            favourite_by_id: fav?.attributes?.favourite_by_id,
            created_at: fav?.attributes?.created_at,
            updated_at: fav?.attributes?.updated_at,
            favouriteable_id: fav?.attributes?.favouriteable_id,
            favouriteable_type: fav?.attributes?.favouriteable_type
          }
          const newPosts = this.state.allProjectsData.map((post: any) => {
            if (Number(post.id) == Number(postId)) {
              post.attributes.favourite_id = [favData]
            }
            return post
          })
          this.setState({ allProjectsData: newPosts })
        }
      }
      if (this.apiAllProjectsUnfavJobCallIds.includes(apiRequestCallId)) {
        if(responseJson?.message){
        const id = this.allProjectsunfavCallIdToPostId[apiRequestCallId]
        const allProjectsData = this.state.allProjectsData.map((j: any) => {
          if (j.id == id) {
            j.attributes.favourite_id = []
          }
          return j
        })
        this.setState({ allProjectsData })
        }
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start
  componentDidUpdate(prevProps: any) {
    if (prevProps.navigation.history.location.state?.comment) {
      this.getProfileDashboard('');
      this.getAllProjects('');
      this.props.navigation.history.replace({ state: {} })
    }
  }

  chooseProjectTab = () => {
    this.setState({ activeSeekingFundTab: false })
    this.setState({ activeProjectTab: true })
    this.setState({ showProjectTab: true });
    this.setState({ showSeekingFundTab: false });
    this.setState({ allProjects: false });
    this.setState({ activeAllProjectTab: false });
    this.getProfileDashboard('')
  }

  chooseAllProject = () => {
    this.setState({ activeSeekingFundTab: false })
    this.setState({ activeProjectTab: false })
    this.setState({ showProjectTab: false });
    this.setState({ showSeekingFundTab: false });
    this.setState({ allProjects: true });
    this.setState({ activeAllProjectTab: true });
    this.getAllProjects('')
  }

  chooseSeekingTab = () => {
    this.setState({ activeSeekingFundTab: true })
    this.setState({ activeProjectTab: false })
    this.setState({ showProjectTab: false });
    this.setState({ showSeekingFundTab: true });
    this.setState({ allProjects: false });
    this.setState({ activeAllProjectTab: false });
    this.getSeekingFunds()
  }

  getProfileDashboard = async(value: any) => {
    const authToken = await getStorageData('authToken');
    this.getProfileDashboardData = sendAPIRequest(`${configJSON.getuserAssociatedProject}?search=${value}`, {
      method: "GET",
      headers: { token: authToken || '' }
    })
  }

  getAllProjects = async(searchValue: any) => {
    const authToken = await getStorageData('authToken');
    this.getAllProjectsData = sendAPIRequest(`${configJSON.getAllProjectsList}?search=${searchValue}`, {
      method: "GET",
      headers: { token: authToken || '' }
    })
  }

  handleFilterQueryChange = (query: any) => {
    this.setState({loader:true})
    if (query !== "") {
      this.setState({ searchInputValue: query?.toLowerCase() }, (() => { this.getAllProjects(query) }))
    }
    else {
      this.getAllProjects('')
    }
  }


  handleFilterQueryChangeForMyProjects = (query: any) => {
    this.setState({ Overlayloader:true })
    if (query !== "") {
      this.setState({ searchInputValue: query?.toLowerCase() }, (() => { this.getProfileDashboard(query) }))
    }
    else {
      this.getProfileDashboard('')
    }
  }

  handleFilterMenuOpen = (e: React.MouseEvent) => {
    if (!this.filterRef) return;
    const box = this.filterRef.current.getBoundingClientRect();
    this.setState({
      filterMenuPosition: {
        top: box.bottom,
        left: box.left + 20
      }
    })
  }

  toggleFilterByAll = (value: string) => {
    this.setState({filterFromDate:null,filterToDate:null})
    const filters = this.getBlankFilters()
    filters['filterByAll'] = !this.state.filterByAll
    this.setState({ filterText: value, createdOn: "" })
    this.setState({ filterMenuPosition: undefined })
    this.handleFilterMenuClose()
    if (this.state.activeAllProjectTab) {
      this.setState({loader:true})
      this.getAllProjects('')
    }
    if (this.state.activeProjectTab) {
      this.setState({
        Overlayloader:true
      })
      this.getProfileDashboard('')
    }
  }

  toggleFilterSDGFactor = (value: any) => {
    this.setState({filterFromDate:null,filterToDate:null})
    let arr=[]
    let name=[]
    if (this.state.selectedItem.includes(value?.id)) {
      arr=this.state.selectedItem.filter((selectedItem:any) => selectedItem !== value?.id)
      name=this.state.selectedName.filter((selectedItem:any) => selectedItem !== value?.attributes?.goal)
      this.setState({selectedItem:this.state.selectedItem.filter((selectedItem:any) => selectedItem !== value?.id)})
      this.setState({selectedName:this.state.selectedName.filter((selectedItem:any) => selectedItem !== value?.attributes?.goal)})
    } else {
      arr=[...this.state.selectedItem, value?.id]
      name=[...this.state.selectedName, value?.attributes?.goal]
      this.setState({selectedItem:[...this.state.selectedItem, value?.id]})
      this.setState({selectedName:[...this.state.selectedName, value?.attributes?.goal]})
    }
    const filters = this.getBlankFilters()
    filters['filterBySDGFactorsId'] = !this.state.filterBySDGFactorsId
    this.setState({ filterText: name.toString(), createdOn: "" })
    this.setState({ filterMenuPosition: undefined })
    if (this.state.activeAllProjectTab) {
      this.setState({loader:true})
      arr?.length> 0 ? this.getProjectsByFilter('sdg_factors[]', arr?.toString()) :  this.getAllProjects('')
    }
    if (this.state.activeProjectTab) {
      this.setState(
        {Overlayloader: true}
        )
      arr?.length>0 ? this.getMyProjectsByFilter('sdg_factors[]', arr?.toString()) : this.getProfileDashboard('')
    }
    this.handleFilterMenuClose()
  }

  toggleFilterByDimensions = (value: string) => {
    this.setState({selectedItem:[],selectedName:[],filterFromDate:null,filterToDate:null})
    const filters = this.getBlankFilters()
    filters['filterByDimensions'] = !this.state.filterByDimensions
    this.setState({ filterText: value, createdOn: "" })
    if (this.state.activeAllProjectTab) {
      this.setState({loader:true})
      this.getProjectsByFilter('dimensions', value)
    }
    if (this.state.activeProjectTab) {
      this.setState({Overlayloader:true})
      this.getMyProjectsByFilter('dimensions', value)
    }
    this.handleFilterMenuClose()
  }

  toggleFilterByCreatedOn = () => {
    this.setState({selectedItem:[],selectedName:[],filterFromDate:null,filterToDate:null})
    const filters = this.getBlankFilters()
    filters['filterByCreatedOn'] = !this.state.filterByCreatedOn
    const fromDate=moment(this.state.filterFromDate).format('YYYY-MM-DD').toString()
    const toDate = moment(this.state.filterToDate).format('YYYY-MM-DD').toString()
    this.setState({ filterText: `Created On: ${fromDate} - ${toDate} ` })
    const authToken = this.state.token

    if (this.state.activeAllProjectTab) {
      this.setState({loader:true})
      this.filterProjectsBYCategories = sendAPIRequest(`${configJSON.getFilteredProjects}?from_date=${fromDate}&to_date=${toDate}`, {
        method: 'GET',
        headers: { 'token': authToken || '' }
      })
    }
    if (this.state.activeProjectTab) {
      this.setState({Overlayloader: true })
      this.filterMyProjects = sendAPIRequest(`${configJSON.filterdMyProjects}?from_date=${fromDate}&to_date=${toDate}`, {
        method: 'GET',
        headers: { 'token': authToken || '' }
      })
    }
  }


  toggleFilterByCreatedBy = (value: string) => {
    this.setState({selectedItem:[],selectedName:[],filterFromDate:null,filterToDate:null})
    const filters = this.getBlankFilters()
    filters['filterByCreatedBy'] = !this.state.filterByCreatedBy
    this.setState({ filterText: "Created by me", createdOn: "" })
    if (this.state.activeAllProjectTab) {
      this.setState({loader:true})
      this.getProjectsByFilter('created_by_me', value)
    }
    if (this.state.activeProjectTab) {
      this.setState({Overlayloader:true})
      this.getMyProjectsByFilter('created_by_me', value)
    }
    this.handleFilterMenuClose()
  }

  clearFilters = (e: React.MouseEvent) => {
    e.stopPropagation()
    this.getAllProjects('')
    this.getProfileDashboard('')
    this.setState({Overlayloader:true,loader:true})
    this.setState({
      filterBySDGFactorsId: false,
      filterByDimensions: false,
      selectedItem:[],
      filterText: '',
      filterByAll: false,
      selectedName:[],
      filterFromDate:null,
      filterToDate:null,
      // pages:1,
      // current_page:1
    })

  }

  getBlankFilters = () => {
    return {
      filterByAll: false,
      filterByCreatedBy: false,
      filterByCreatedOn: false,
      filterByDimensions: false,
      filterBySDGFactorsId: false,
    }
  }

  handleFilterMenuClose = () => {
    this.setState({
      filterMenuPosition: undefined 
    })
  }

  handleShareCourse = (p: any) => () => {
    let data = {link: `${window?.location?.href}/projects/${p?.id}`,
      shareableType: 'BxBlockProjectManagement::ProjectManagement',
      shareableId: p?.id,
    }
    sendSharePopupMessage(data);
  }

  goToEvent = (event_Id: any) => () => {this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}/projects/${event_Id}`)}

  onLikeChange = (postId: any, delta: number) => {
    const profileDashboardData = this.state.profileDashboardData.map((p: any) => { if (p.id == postId) {
        p.attributes.project_likes_count += delta;
        p.attributes.project_likes = [];
      }
      return p
    })
    this.setState({ 
      profileDashboardData 
    })
    // this.getProfileDashboard()
  }

  onLikeIdUpdate = (postId: any, likeObj: any) => {
    const profileDashboardData = this.state.profileDashboardData.map((p: any) => {
      if (p.id == postId) {
        // p.attributes.project_likes_count+= delta
        p.attributes.project_likes.push(likeObj);
      }
      return p
    })
    this.setState({ profileDashboardData })
    // this.getProfileDashboard()
  }

  onLikeChangeForProjects = (postId: any, delta: number) => {
    const allProjectsData = this.state.allProjectsData.map((p: any) => {
      if (p.id == postId) {
        if (Boolean(p.attributes?.project_likes?.some((project: any) => Number(project.like_by_id) === Number(this.state.userID)))) {
          p.attributes.project_likes_count -= 1
          p.attributes.project_likes = p.attributes?.project_likes.filter((project: any) => Number(project.like_by_id) !== Number(this.state.userID))
        } else {
          p.attributes.project_likes_count += 1
        }
      }
      return p
    })
    this.setState({ allProjectsData })
    this.getAllProjects('')
  }

  onLikeIdUpdateForProjects = (postId: any, likeObj: any) => {
    const allProjectsData = this.state.allProjectsData.map((p: any) => {
      if (p.id == postId) {
        p.attributes.project_likes = [...p.attributes.project_likes, likeObj];
      }
      return p
    })

    this.setState({ allProjectsData })
    // this.getAllProjects('')
  }


  getSeekingFunds = async() => {
    const authToken = await getStorageData('authToken');
    this.getSeekingFundsList = sendAPIRequest(`${configJSON.seekingFunds}`, {
      method: 'GET', headers: { 'token': authToken || '' } })
  }

  getSeekingFundsById = async(id: any) => {
    const authToken = await getStorageData('authToken');
    this.getSeekingFundsListById = sendAPIRequest(`${configJSON.seekingFundsById}/${id}`, {
      method: 'GET', headers: { 'token': authToken || '' } })
  }



  handleCollabrators = (value: any) => {
    this.setState({ selectedCollabrators: value })
  }

  handleseekingFunds = (value: any) => {
    this.setState({ openSeekingFunds: true, viewProjectData: value })
  }

  handleseekingFundsClose = (value: any) => {
    this.setState({ openSeekingFunds: false, viewProjectData: [] }, (() => {
      this.getSeekingFunds()
    }))
  }

  getProjectsByFilter = async(key: any, value: any) => {
    const authToken = await getStorageData('authToken');
    this.filterProjectsBYCategories = sendAPIRequest(`${configJSON.getFilteredProjects}?${key}=${value}`, {
      method: 'GET',
      headers: { 'token': authToken || '' }
    })
  }

  getMyProjectsByFilter = async(key: any, value: any) => {
    const authToken = await getStorageData('authToken');
    this.filterMyProjects = sendAPIRequest(`${configJSON.filterdMyProjects}?${key}=${[value]}`, {
      method: 'GET',
      headers: { 'token': authToken || '' }
    })
  }

  getProjectDataById = async(id: any) => {
    const authToken = await getStorageData('authToken');
    this.projectDetailById = sendAPIRequest(`${configJSON.projectDetailById}?project_id=${id}`, {
      method: 'GET',
      headers: { 'token': authToken || '' }
    })
  }

  goToProjectDetailEndUser = (id: any) => {
    this.props.navigation.history.push(`/project_detail_enduser/${id}`)
  }

  getSDGFocousData = async() => {
    const authToken = await getStorageData('authToken');
    this.sdfFocous = sendAPIRequest(`${configJSON.getSDGFocusEndpoint}`, {
      method: 'GET',
      headers: { 'token': authToken || '' }
    })
  }

  startDateChange = (v: any) => {
    // this.setState({ createdOn: v }, (() => this.toggleFilterByCreatedOn(v)))
    this.handleFilterMenuClose()
  }

  handleEditSeekingFunds = (id: any) => {
    this.setState({ openSeekingFunds: false }, (() => this.props.navigation.history.push(`all_project_end_user/seeking_funds/${id}`)))
  }

  handleTopContributorsModalClose = () => {
    this.setState({ 
      openContributros: false 
    })
  }

  handleTopContributorsModalOpen = (id: any) => {
    this.setState({ 
      tableRowId: id,
      openContributros: true, 
    })
  }

  handleCloseCollaborators = () => {
    this.setState({ 
      tableRowId: "" ,
      showCollaborators: false, 
      })
  }

  handleShowCollaborators = (id: any) => {
    this.setState({
      tableRowId: id , 
      showCollaborators: true,})
  }

  handleCloseSDGFactors = () => {
    this.setState({ 
      showSdg: false,
      tableRowId: "" })
  }

  handleOpenSDGFactors = (id: any) => {
    this.setState({ 
      tableRowId: id, 
      showSdg: true })
  }

  handleFavoriteClick = (id: any) => () => {
    const job = this.state.profileDashboardData.find((j: any) => j.id == id)
    const token = this.state.token
    if (job) {
      if (job?.attributes?.is_favourite) {
        const favourite_id = job?.attributes?.favourite_id[0]
        if (!favourite_id) return;
        const endpoint = configJSON.unfavJobAPIEndpoint.replace(':id', favourite_id.id)
        const body = new FormData()
        body.append('data[favouriteable_type]', 'BxBlockProjectManagement::ProjectManagement')
        const callId = sendAPIRequest(endpoint, { method: 'DELETE', body, headers: { token } })
        this.apiUnfavJobCallIds.push(callId)
        this.unfavCallIdToPostId[callId] = job.id
      }
      else {
        const endpoint = configJSON.favJobAPIEndpoint
        const body = new FormData()
        body.append('data[favouriteable_type]', 'BxBlockProjectManagement::ProjectManagement')
        body.append('data[favouriteable_id]', id)
        const callId = sendAPIRequest(endpoint, { method: 'POST', body, headers: { token } })
        this.apiFavJobCallIds.push(callId)
        this.favCallIdToPostId[callId] = id
      }

      const profileDashboardData = this.state.profileDashboardData.map((j: any) => {
        if (j.id !== id) { return j }
        else {
          j.attributes.is_favourite = !j.attributes.is_favourite;
          return j
        }
      })
      this.setState({ profileDashboardData })
    }
  }

  handleLikeCount = async(json: any) => {
    let userData: any = await getStorageData('user');
    let id: any = JSON.parse(userData)?.id;
    let userIds: any = [];
    let project_like_id: any = null;
    json?.data?.attributes?.liked_by_users?.map((item: any) => {
      if (item?.likeable_type == "BxBlockProjectportfolio::PromoteContent") {
        userIds?.push(item?.like_by_id)
        if (item?.like_by_id == id) { project_like_id = item?.id}
      }
    });
    this.setState({ ...this.state, viewProjectData: { ...this.state.viewProjectData, attributes: { ...this.state?.viewProjectData?.attributes, likes: json?.data, is_liked: userIds.includes(id), project_like_id: project_like_id } } });
  } 
  handleLikeChange = (id?: any) => {
    this.apiUserLikeCountCallId = sendAPIRequest(configJSON.getUserLikeCountEndpoint.replace(':id', id), {
      headers: { token: this.state.token }
    })
  }


  handleFavClick = (id: any) => () => {
    const job = this.state.allProjectsData.find((j: any) => j.id == id)
    const token = this.state.token
    if (job) {
      if (job?.attributes?.is_favourite) {
        const favourite_id = job?.attributes?.favourite_id[0]
        if (!favourite_id) return;
        const endpoint = configJSON.unfavJobAPIEndpoint.replace(':id', favourite_id.id)
        const body = new FormData()
        body.append('data[favouriteable_type]', 'BxBlockProjectManagement::ProjectManagement')
        const callId = sendAPIRequest(endpoint, { method: 'DELETE', body, headers: { token } })
        this.apiAllProjectsUnfavJobCallIds.push(callId)
        this.allProjectsunfavCallIdToPostId[callId] = job.id
      }
      else {
        const endpoint = configJSON.favJobAPIEndpoint
        const body = new FormData()
        body.append('data[favouriteable_type]', 'BxBlockProjectManagement::ProjectManagement')
        body.append('data[favouriteable_id]', id)
        const callId = sendAPIRequest(endpoint, { method: 'POST', body, headers: { token } })
        this.apiAllProjectFavJobCallIds.push(callId)
        this.allProjectsfavCallIdToPostId[callId] = id
      }

      const allProjectsData = this.state.allProjectsData.map((j: any) => {
        if (j.id !== id) { return j }
        else {
          j.attributes.is_favourite = !j.attributes.is_favourite;
          return j
        }
      })
      this.setState({ allProjectsData })
    }
  }

  handleSurveyOpen = (id: any) => () => {
    this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}/EndUserSurvey/${id}`)
   }

   handleDateInputBlur = () => (this.setState({ 
    selectingDate: false }
  ))

   handleDateInputFocus = () => {
    this.setState({
       selectingDate: true 
    })
  }
  handleToDateChange = (date: any) => {this.setState({ ...this.getBlankFilters(), filterToDate: date, filterFromDate: this.state.filterFromDate }) }

  handleFromDateChange = (date: any) => { this.setState({ ...this.getBlankFilters(), filterFromDate: date, filterToDate: this.state.filterToDate }) }

  handleDateFilterSubmit = () => {
    this.setState({ 
      filterMenuPosition: undefined
   })
    this.toggleFilterByCreatedOn()
  }

  handleDateFilterCancel = () => {
    this.setState({ 
      filterMenuPosition: undefined 
    })
  }
  navigationFun = ({
    id,
    props,
    screenName,
    }: {
      id?: number;
      props: unknown;
      screenName: string
    }) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    id && message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    runEngine.sendMessage(message.id, message);
    }

  // Customizable Area End
}