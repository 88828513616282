import React, { Component } from "react";
// Customizable Area Start
import { withStyles, styled } from "@material-ui/styles";
import {
  Card,
  Typography,
  Button,
  CardHeader,
  CardContent,
  Box,
  ButtonBase, 
  Grid,
  Popover
} from "@material-ui/core";
import { activeicon } from "./assets";
import {declineicon}  from "./assets";
import { LoadingSpinner } from "../../../components/src/LoadingSpinner.web";
import ChooseLicenseSubscribtionPopup from "../../../components/src/ChooseLicenseSubscribtionPopup.web";
import CustomisableusersubscriptionsController, {
  Props,
  configJSON,
} from "./CustomisableusersubscriptionsController";
import { formatMoneyINR, formatMoneyUSD } from '../../../components/src/utils'
// Customizable Area End
export default class SubscribtionHistory extends CustomisableusersubscriptionsController { 
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {!this.state.loader && this.state.SubscribtionHistoryList?.length === 0  && <Typography style={{ marginTop: '40px', textAlign: 'center' }}>No Match Found</Typography>}

      {
         this.state.loader ? <LoadingSpinner />:
         this.state.SubscribtionHistoryList.map((ele:any)=>{
          return(
            <RoundedCard raised={false}>
               <TitleBox>
                 <TextPriceHeading
                 
                 >
                   {ele.attributes.package_name}
                   <span style={{marginLeft:"8px",fontWeight:400,fontSize:"16px"}}>({ele.attributes.package_role})</span>
                 </TextPriceHeading>
                 <TextPriceHeading>
                 ${ele.attributes.total_cost_usd}
                  <span style={{marginLeft:"8px",fontWeight:400,fontSize:"16px"}}>({formatMoneyINR(ele.attributes.total_cost_inr)})</span>
                 </TextPriceHeading>

                </TitleBox>
                <CardContentOne>
                  <Box
                  style={{
                    marginRight:"20px",
                   
                  }}
                  >
                  <TextSubHeading>No of members<span style={{marginLeft:"2px",marginRight:"4px"}}>:</span>
                  <span style={{fontWeight:800}}>{ele.attributes.total_member}</span>
                  </TextSubHeading>

                  </Box>

                  <TextSubHeading>Duration<span style={{marginLeft:"2px",marginRight:"4px"}}>:</span>
                  <span style={{fontWeight:800}}>{ele.attributes.period}<span style={{fontWeight:800}}></span></span>
                  </TextSubHeading>

                  {ele.attributes.start_date && ele.attributes.start_date !== '' &&
                    <TextSubHeading>Date<span style={{marginLeft:"2px",marginRight:"4px"}}>:</span>
                    <span style={{fontWeight:800}}>{ele.attributes.start_date} - {ele.attributes.end_date}<span style={{fontWeight:800}}></span></span>
                    </TextSubHeading>
                  }

                  {ele.attributes.start_date && ele.attributes.start_date !== '' &&
                    // <TextSubHeading>End Date<span style={{marginLeft:"2px",marginRight:"4px"}}>:</span>
                    // <span style={{fontWeight:800}}>{ele.attributes.end_date}<span style={{fontWeight:800}}></span></span>
                    // </TextSubHeading>
                    <span></span>
                  }

                  {ele.attributes.start_date && ele.attributes.start_date !== '' &&
                    // <TextSubHeading>End Date<span style={{marginLeft:"2px",marginRight:"4px"}}>:</span>
                    // <span style={{fontWeight:800}}>{ele.attributes.end_date}<span style={{fontWeight:800}}></span></span>
                    // </TextSubHeading>
                    <span></span>
                  }

                  {ele.attributes.start_date && ele.attributes.start_date !== '' &&
                    // <TextSubHeading>End Date<span style={{marginLeft:"2px",marginRight:"4px"}}>:</span>
                    // <span style={{fontWeight:800}}>{ele.attributes.end_date}<span style={{fontWeight:800}}></span></span>
                    // </TextSubHeading>
                    <span></span>
                  }                 
                </CardContentOne>
                <CardContentTwo>
                  <Box
                  style={{
                    display:"flex",
                    flexWrap:"wrap"         
                  }}
                  >
                    <TextSubHeading>Payment Method<span style={{marginLeft:"2px",marginRight:"4px"}}>:</span>
                   <span style={{fontWeight:800,marginRight:"20px"}}>{ele.attributes.payment_method == "card" ? "Credit Card" : ele.attributes.payment_method}</span> 
                    </TextSubHeading>
                    <span>
                      <TextSubHeading>Transaction ID<span style={{marginLeft:"2px",marginRight:"4px"}}>:</span>
                      <span style={{fontWeight:800,marginRight:"20px"}} onClick={(e) => this.setState({ transactionData: e.currentTarget, selectedTransactionData: ele })}>{ele.attributes.transection_id[0]}</span> </TextSubHeading>
                   </span>
                    <TextSubHeading>Period<span style={{marginLeft:"2px",marginRight:"4px"}}>:</span>
                    <span style={{fontWeight:800,marginRight:"20px"}}>Monthly</span>
                    </TextSubHeading>

                  </Box>
                 { ele.attributes.package_status ==='Active' ?
                  <Box>
                   <ActiveText
                   style={{
                     display:"flex"
                   }}
                   >Active</ActiveText>
                  </Box> :
                  ele.attributes.package_status ==='Pending' ? 
                  <Box>
                   <RequestText
                   style={{
                     display:"flex"
                   }}
                   >
                      
                     {ele.attributes.package_status}</RequestText>
                    
                  </Box> :
                  ele.attributes.package_status ==='Queue' ? 
                  <Box>
                   <RequestText
                   style={{
                     display:"flex"
                   }}
                   >
                      
                     Request <RequestStatusBreak/>
                     InQueue</RequestText>
                    
                  </Box> :
                  <Box>
                  <ErrorText
                  style={{
                    display:"flex"
                  }}
                  >
                     
                    {ele.attributes.package_status}</ErrorText>
                   
                 </Box>}
                </CardContentTwo>
                { (ele.attributes.package_role == "Corporate Plus" || 
                ele.attributes.package_role == "Corporate" || 
                ele.attributes.package_role == "portfolio manager") ?
                <Box>
                  <CardButtonLicense>
                  <span>Add more licences <span style={{
                  color: "rgba(0,0,0,0.32)",
                  fontStyle:"italic",fontWeight:300,marginLeft:"3px",marginRight:"3px"}}>(${ele.attributes.future_corp_usd && ele.attributes.future_corp_usd !== '' ? ele.attributes.future_corp_usd : "-"} / ₹{ele.attributes.future_corpo_inr && ele.attributes.future_corpo_inr !== '' ? ele.attributes.future_corpo_inr : "-"} per licence per month ) </span>
                  </span>
                  <BreakingTag/>
                  {!ele.attributes.reactivation && 
                    <PurchaseButton
                      onClick={()=>this.addMoreLicensePopUp(ele)}
                      disabled={ele.attributes.package_status ==='Active' ? false : true}
                    >
                      Purchase 
                    </PurchaseButton>
                  }
                  {(!ele.attributes.reactivated && ele.attributes.reactivation) && 
                    <PurchaseButton
                      onClick={()=>this.handleReactiveLicense(ele.id,"inr")}
                    >
                      Reactivate 
                    </PurchaseButton>
                  }
                  </CardButtonLicense>
                </Box>
                :
                (!ele.attributes.reactivated && ele.attributes.reactivation) &&  
                  <Box style={{
                    display:'flex',
                    justifyContent:"space-between"
                  }}>
                    <span></span>
                    <CardButton onClick={()=>this.handleReactiveLicense(ele.id,"inr")}>
                        Reactivate 
                    </CardButton>
                  </Box>
                }          
       </RoundedCard>
    
          )
        })
      }
      {this.state.transactionData &&
      <Popover
          open={this.state.transactionData}
          anchorEl={this.state.transactionData}
          onClose={() => this.setState({ transactionData: null, selectedTransactionData: null})}
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <TextSubHeading>Transaction ID<span style={{marginLeft:"2px",marginRight:"4px"}}>:</span>
          <span style={{fontWeight:800,marginRight:"20px"}}>{this.state.selectedTransactionData.attributes.transection_id.join()}</span> </TextSubHeading>
        </Popover>
        }
        {this.state.addMoreLicensePopUp && (
          <ChooseLicenseSubscribtionPopup
            open={this.state.addMoreLicensePopUp}
            onSubmit={this.handleAddLicenseSubmit}
            closepopup={this.closeAddMoreLicensePopUp}
            subscribtionList={this.state.subscribtionList}
            selectedSubscription={this.state.selectedSubscription}
          />
        )}
      
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
//*********** styles ***************//
const CradDetails = styled(Box)({
  display:"flex",
  flexWrap:"wrap",
  flexDirection:"column",
  marginTop:"6px"
});

const PurchaseButton = styled(Button)({
  border: "1px solid #00A100",
  color: "#00A100",
  fontWeight: 600,
  backgroundColor: "#E2EFE2",
  textTransform: "none",
  borderRadius: "30px",
  width: "130px",
  height: "35px",
  marginLeft: "10px",
  "&:focus": {
    backgroundColor: "#E2EFE2",
    opacity: 0.9,
    color: "#00A100",
    borderRadius: "009B07",
  },
});
const CardButtonLicense = styled(Button)(({ theme }) => ({
  // maxWidth: '60vw',
  border: "1px solid #E6F1E6",
  color: "#515C51",
  fontWeight: 600,
  backgroundColor: "#E6F1E6",
  textTransform: "none",
  borderRadius: "10px",
  height: "auto",
  display: 'flex',
  justifyContent: 'center',
  gap: '20px',
  width: '100%',
  "&:hover": {
    background: "#E6F1E6",
    opacity: 0.9,
    color: "#515C51",
    borderRadius: "10px",
  },
  '@media screen and (max-width: 1212px) and (min-width: 769px)': {
    display: "unset",
  },
  '@media screen and (max-width: 624px)': {
    display: "unset",
  }
 }));
 const RequestText = styled(Typography)({
  color: "FF8000",
  fontSize: "14px",
  fontWeight: 800,
  paddingRigth: "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "helvetica"
});
const ErrorText = styled(Typography)({
  color: "red",
  fontSize: "14px",
  fontWeight: 800,
  paddingRigth: "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "helvetica"
});

const CardText = styled(Typography)({
  color: "#C9C9C9",
});
const TitleBox = styled(Box)({
  display:"flex",
  justifyContent:"space-between",
  alignItems:"center",
  '@media screen and (max-width: 900px) and (min-width: 769px)': {
    display: "unset",
  },
  '@media screen and (max-width: 624px)': {
    display: "unset",
  }
});
const CardContentOne = styled(Box)({
  display:"flex",
  justifyContent:"space-between",
  marginTop:"10px",
  '@media screen and (max-width: 1130px) and (min-width: 769px)': {
    display: "unset",
  },
  '@media screen and (max-width: 624px)': {
    display: "unset",
  }
});
const CardContentTwo = styled(Box)({
  display:"flex",
  justifyContent:"space-between",
  '@media screen and (max-width: 1130px) and (min-width: 769px)': {
    display: "unset",
  },
  '@media screen and (max-width: 624px)': {
    display: "unset",
  }
});
const RequestStatusBreak = styled("br")({
  '@media screen and (max-width: 1130px) and (min-width: 769px)': {
    display: "none",
  },
  '@media screen and (max-width: 624px)': {
    display: "none",
  }
});
const AdditionalButton = styled(Box)({
  color: "#C9C9C9",
});
const PageWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  minHeight: "100vh",
  maxWidth: "900px",
  width: "100%",
});
const CenterRow = styled("div")(({ theme }) => ({
  // maxWidth: '60vw',
  width: "100%",
  marginTop: "60px",
  maxWidth: "900px",
}));
const RoundedCard = styled(Card)({
  borderRadius: '15px',
  padding: '22px 32px',
  border:"1px solid #DBE0E6",
  margin: '20px 0px',
  // minWidth:"600px",
  maxwidth:"900px",
  boxShadow: 'none',
  "&:hover": {
    background: " #EEF5EE",
    color: "#000000",
    border: "1px solid #00A100",
  },
});
const TextPlanHeading = styled(Typography)({
  color: "#000000",
  fontSize: "16px",
  marginBottom: "8px",
  marginRight:"20px"
});
const ReactivateButton = styled(Button)({
  color: "#FFFFFF",
  fontSize: "10px",
  marginBottom: "8px",
  backgroundColor:"#009B07",
  borderRadius:"20px",
  width:"90px",
  fontWeight:"bold"
});
const TextSubHeading = styled(Typography)({
  color: "#000000",
  fontSize: "14px",
  marginBottom: "8px",
  marginRight:"10px",
  fontFamily:"helvetica"
});
const TextPriceHeading = styled(Typography)({
  color: "#000000",
  fontSize: "20px",
  fontWeight:700,
  fontFamily:"helvetica",
  textTransform:"capitalize"
});
const ActiveText = styled(Typography)({
  color: "#00A100",
  fontSize: "16px",
  marginBottom:"5px",
  fontWeight:800,
  fontFamily:"helvetica",
  justifyContent: "center",
  alignItems: "center",
});
const CardButton = styled(Button)({
  width: "153px",
  height: "38px",
  backgroundColor: "#009B07",
  color: "#FFFFFF",
  marginTop: "1px",
  textTransform: "capitalize",
  fontWeight: 700,
  borderRadius: "20px",
  "&:hover": {
    background: "#009B07",
    opacity: 0.9,
    color: "FFFFFF",
    borderRadius: "009B07",
  },
});
const BreakingTag = styled('br')({
  '@media screen and (min-width: 870) and (max-width: 1070px)': {
    display: "none",
  },
  '@media screen and (min-width: 440px)': {
    display: "none",
  }
})
const CardButtonLicenseRequest = styled(Button)(({ theme }) => ({
  // maxWidth: '60vw',
  border: "none",
  color: "#515C51",
  fontWeight: 600,
  backgroundColor: "#F3F8F3",
  textTransform: "none",
  borderRadius: "10px",
  width: "330px",
  height: "56px",
  "&:hover": {
    background: "#F3F8F3",
    opacity: 0.9,
    color: "#515C51",
    borderRadius: "009B07",
  },
  
}));

// Customizable Area End