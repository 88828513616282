import React from "react";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import CfCarbonCalculatorReportController, {
	Props, configJSON
} from "./CfCarbonCalculatorReportController";
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from 'moment'
import NestedMenuItem from '../../../components/src/NestedMenuItem.web'
import {
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableCell as TableCellContainer,
	TableRow,
	Paper,
	TextField,
	Button,
	InputAdornment,
	Menu,
	MenuItem,
	RadioGroup,
	FormControlLabel,
	Radio,
} from "@material-ui/core";
import OverlayLoader from '../../../components/src/Loader.web';
import Pagination from '@material-ui/lab/Pagination';
import SearchFilter from '../../../components/src/Search.web'
import { ExcelIcon, PdfIcon, ArrowDownIcon, UnverifiedIcon, VerifiedIcon, editImg } from './assets';
import { CloseCircleIcon } from '../../../components/src/Icons.web';
import { DatePicker } from '@material-ui/pickers'
import { Formik, Field } from 'formik'
import FormModal, {
	InputRow,
	MInput,
} from '../../../components/src/FormModal.web'

// Customizable Area End
export default class CfCarbonCalculator extends CfCarbonCalculatorReportController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start
	editEmissionReport() {
		let { editData } = this.state
		let verifiedStatus = editData.verified?.toString() == 'true' ? true : false
		const initialValues = {
			verified: verifiedStatus,
		}

		if (this.state.showModal !== 'create') return <></>
		else
			return <Formik enableReinitialize initialValues={initialValues} onSubmit={this.handleSubmit} >
				{({ values, errors }: any) => (
					<>
						{console.log("chcek values *************", values)}
						<FormModal headerTitle={<EditHeader>EDIT CARBON EMISSION CALCULATOR REPORT</EditHeader>} submitLabel="SAVE" onClose={() => this.setState({ showModal: '' })} >
							<InputRow label="Report Name*" input={<MInput style={{ background: '#ddd7d7bf' }} disabled name="report_name" value={editData.report_name} />} />
							<InputRow label="Quarter-Year*" input={<MInput style={{ background: '#ddd7d7bf' }} disabled name="quarter_year" value={editData.quarter_year} />} />
							<InputRow label="Submission Date-Time*" input={<MInput style={{ background: '#ddd7d7bf' }} disabled name="submission_date_time" value={moment(editData.submission_date_time).format("DD-MM-YYYY")} />} />
							<InputRow label="Score*" input={<MInput style={{ background: '#ddd7d7bf' }} disabled name="score" value={editData.score} />} />
							<InputRow label="Submitted by*" input={<MInput style={{ background: '#ddd7d7bf' }} disabled name="submitted_by" value={editData.submitted_by} />} />

							<InputRow label="Status*" input={
								<Field
									render={({ field }: any) => <><RadioGroup {...field} aria-label="Status"
										name="verified"
										value={values?.verified?.toString() == 'true' ? true : false}
										style={{ flexDirection: 'row' }}
									>
										<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
											<FormControlLabel style={{ marginLeft: '0', marginRight: '10' }} data-testid='verified' value={true} control={<Radio style={{color:"green"}}/>} label="" />
											<img src={VerifiedIcon} />
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
											<FormControlLabel style={{ marginLeft: '0', marginRight: '10' }} value={false} data-testid='noverified' control={<Radio style={{color:"green"}}/>} label="" />
											<img src={UnverifiedIcon} width="90px" />
										</div>
									</RadioGroup>
									</>
									}
								/>
							} />

						</FormModal>
					</>
				)}
			</Formik>
	}

	mappingStatus(status: any) {
		if (status) {
			return <img src={VerifiedIcon} />
		}
		return <img src={UnverifiedIcon} width="90px" />
	}

	// Customizable Area End

	render() {
		// Customizable Area Start
		const { navigation } = this.props
		const updateReport = this.editEmissionReport();
		const DownloadBtn = ({ url, iconSrc }: DownloadBtnProps) => {
			return (<a href={url} download target="_blank" rel="noopener noreferrer" style={{ cursor: "unset" }}>
				<div>
					<img style={{ width: "26px", cursor: "pointer" }} src={iconSrc} alt="" />
				</div>
			</a>)
		};
		// Customizable Area End
		return (
			// Customizable Area Start
			<>
			{updateReport}
			<DashboardLayout isDashboard={true} isUserSurvey={false} navigation={navigation} >
				<CfReportWrapper>
					<CfReportHeadingWrapper>
						<CfReportPageTitle onClick={() => this.getNavigationMessage(this.path, this.props)} data-testid='navigationBack'>
							ESG Journey
							<ArrowBackIosIcon />
						</CfReportPageTitle>
						<CfHead>CARBON EMISSION CALCULATOR REPORT</CfHead>
					</CfReportHeadingWrapper>
					<FilterRowWrapper >
						<div style={{ flex: 1 }}>
							<SearchFilter data-test-id='searchPosts' design='half' onChange={this.onFilterQueryChange} />
						</div>
						<FilterWrap>
							<TextFieldRounded
								data-testid='openFilter'
								value={this.filterText()}
								onClick={this.handleFilterMenuOpen}
								InputProps={{
									ref: this.filterRef,
									endAdornment: (<InputAdornment position="start">
										{this.filterText() && <CloseCircleIcon
											data-testid="clearBtn"
											onClick={this.clearFilters}
											style={{ margin: '0 10px', width: '24px', cursor: 'pointer' }}
										/>}
										<img src={ArrowDownIcon} width="16" />
									</InputAdornment>)
								}} variant="outlined" placeholder={configJSON.labelFilterBy}
							/>
							<FilterMenu
								data-test-id='filterMenu'
								menuPosition={this.state.filterMenuPosition}
								onClose={this.handleFilterMenuClose}
								filterByAll={this.state.filterByAll}
								toggleFilterByAll={this.toggleFilterByAll}
								keepOpen={this.state.selectedDate}
								handleDateInputFocus={this.handleDateInputFocus}
								handleDateInputBlur={this.handleDateInputBlur}
								dateControls={{
									handleSubmit: this.handleDateFilterSubmit,
									handleCancel: this.handleDateFilterCancel,
									handleFromDateChange: this.handleFromDateChange,
									handleToDateChange: this.handleToDateChange,
									filterFromDate: this.state.filterFromDate,
									filterToDate: this.state.filterToDate,
									minDate: this.state.filterFromDate,
									maxDate: this.state.filterToDate
								}}
							/>
						</FilterWrap>
					</FilterRowWrapper>
					<MainTableBoxWrapper>
						<TableWrapper>
							{this.state.loading ? <LoaderContainer data-testid="loader">Loading...</LoaderContainer> :
								<TableContainer component={Paper}>
									<Table>
										<TableHead>
											<TableRowBox>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>S No</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>Report Name</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>Quarter-Year</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>Submission Date-Time</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>Score</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>Submitted by</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>Status</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>PDF Report</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>xls Report</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}></TableCellContainer>
											</TableRowBox>
										</TableHead>
										<TableBody>
											{this.state.cfCalculatorReportList?.length > 0 ? this.state.cfCalculatorReportList.map((value: any, index: any) => {
												const { attributes } = value
												return (
													<>
														<TableBodyBox>
															<TableCellContainer>{10 * (this.state.current_page - 1) + (index + 1)}</TableCellContainer>
															<TableCellContainer>{attributes.report_name}</TableCellContainer>
															<TableCellContainer>{attributes.quarter_year}</TableCellContainer>
															<TableCellContainer>{moment(attributes.submission_date_time).format("DD-MM-YYYY")}</TableCellContainer>
															<TableCellContainer>{attributes.score}</TableCellContainer>
															<TableCellContainer>{attributes.submitted_by}</TableCellContainer>
															<TableCellContainer>{this.mappingStatus(attributes?.verified)}</TableCellContainer>
															<TableCellContainer>
																{attributes.pdf_report?.url ? <DownloadBtn
																	url={attributes.pdf_report.url}
																	iconSrc={PdfIcon}
																/> :
																	<div onClick={() => this.showPopup()} data-testid='pdfNotFound'>
																		<img style={{ width: "26px", cursor: "pointer" }} src={PdfIcon} alt="" />
																	</div>
																}
															</TableCellContainer>
															<TableCellContainer>
																{attributes.csv_report?.url ? <DownloadBtn
																	url={attributes.csv_report.url}
																	iconSrc={ExcelIcon}
																/> :
																	<div onClick={() => this.showPopup()} data-testid='ExcelNotFound'>
																		<img style={{ width: "26px", cursor: "pointer" }} src={ExcelIcon} alt="" />
																	</div>
																}
															</TableCellContainer>
															{!this.state.isdisabled && <TableCellContainer><img src={editImg} width="20px" data-testid="handleEdit" onClick={() => this.handleEditStatus(attributes)} /></TableCellContainer>}
														</TableBodyBox>
													</>
												)
											}) : <TableCellContainer style={{ textAlign: "center" }} colSpan={8}>No Data Found</TableCellContainer>}
										</TableBody>
									</Table>
								</TableContainer>
							}
						</TableWrapper>
						<Pagin>
							<Pagination data-testid="tablePageChange" disabled={this.state.total_pages <= 1 || !this.state.total_pages} defaultPage={1} color="primary" variant="outlined" count={this.state.total_pages} page={this.state.current_page} onChange={this.handlePageChange} />
						</Pagin>
						<OverlayLoader data-testid="loader" loading={this.state.loading} />
					</MainTableBoxWrapper>
				</CfReportWrapper>
			</DashboardLayout>
			</>
			// Customizable Area End
		);
	}
}

// Customizable Area Start
interface DownloadBtnProps {
	url: string;
	iconSrc: string;
}
interface FilterMenuProps {
	filterByAll: boolean,
	toggleFilterByAll: any,
	keepOpen: any,
	menuPosition: {
		top: number,
		left: number,
	} | undefined
	onClose: () => void,
	dateControls: {
		handleFromDateChange: any,
		handleToDateChange: any,
		handleSubmit: () => void,
		handleCancel: () => void,
		filterFromDate?: Date | null,
		filterToDate?: Date | null,
		maxDate?: Date | null,
		minDate?: Date | null,
	},
	handleDateInputBlur: any,
	handleDateInputFocus: any
}

const FilterMenu = ({ menuPosition, onClose, keepOpen, dateControls, handleDateInputBlur, handleDateInputFocus, ...props }: FilterMenuProps) => {
	const [nestedMenuName, setNestedMenuName] = React.useState("")
	const clearToDateCf = (e: React.MouseEvent) => {
		e.stopPropagation()
		dateControls.handleToDateChange(null)
	}
	const clearFromDateCf = (e: React.MouseEvent) => {
		e.stopPropagation()
		dateControls.handleFromDateChange(null)
	}
	return (
		<>
			<Menu open={!!menuPosition} anchorPosition={menuPosition} onClose={onClose} anchorReference="anchorPosition">
				<MenuItem data-testid="allMenu"selected={props.filterByAll} onClick={() => props.toggleFilterByAll('All')}>
					All
				</MenuItem>
				<NestedMenuItem
					data-test-id='nestedmenuId'
					name="date"
					nestedMenuName={nestedMenuName}
					setNestedMenuName={setNestedMenuName}
					keepOpen={keepOpen}
					label="Based on Date"
					parentMenuOpen={!!menuPosition}
					// @ts-ignore
					MenuProps={{
						getContentAnchorEl: null,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
					}}
				>
					<FilterByDateWrap>
						<FilterByDateRows>
							Choose Date
						</FilterByDateRows>
						<FilterByDateRows>
							<DatePlaceholder>From</DatePlaceholder>
							<FlexContainer>
								<DatePicker
									autoOk={true}
									variant="inline"
									data-testid='datePickerId'
									clearable
									inputVariant="outlined"
									value={dateControls.filterFromDate}
									onChange={dateControls.handleFromDateChange}
									style={{ width: '100%' }}
									format="yyyy-MM-dd"
									InputProps={{
										endAdornment: (<InputAdornment position="end">
											{dateControls.filterFromDate && <CloseCircleIcon
												style={{ width: '24px', cursor: 'pointer' }}
												onClick={clearFromDateCf}
											/>}
										</InputAdornment>)
									}}
									inputProps={{
										onBlur: handleDateInputBlur,
										placeholder: 'Select',
										onFocus: handleDateInputFocus,
									}}
									maxDate={new Date()}
									initialFocusedDate={dateControls.filterToDate}
								/>
							</FlexContainer>
						</FilterByDateRows>
						<FilterByDateRows>
							<DatePlaceholder>To</DatePlaceholder>
							<FlexContainer>
								<DatePicker
									clearable
									value={dateControls.filterToDate}
									data-testid='filterToDateId'
									variant="inline"
									onChange={dateControls.handleToDateChange}
									inputVariant="outlined"
									autoOk={true}
									format="yyyy-MM-dd"
									minDate={dateControls.filterFromDate || new Date()}
									maxDate={new Date()}
									style={{ width: '100%' }}
									initialFocusedDate={dateControls.filterFromDate}
									inputProps={{
										placeholder: 'Select',
										onBlur: handleDateInputBlur,
										onFocus: handleDateInputFocus
									}}
									InputProps={{
										endAdornment: (<InputAdornment position="end">
											{dateControls.filterToDate && <CloseCircleIcon
												onClick={clearToDateCf}
												style={{ cursor: 'pointer', width: '24px' }}
											/>}
										</InputAdornment>)
									}}
								/>
							</FlexContainer>
						</FilterByDateRows>
						<FilterByDateRows>
							<DatePlaceholder></DatePlaceholder>
							<FlexContainer>
								<CancelButton onClick={dateControls.handleCancel}>Cancel</CancelButton>
								<SubmitButton onClick={dateControls.handleSubmit}>Submit</SubmitButton>
							</FlexContainer>
						</FilterByDateRows>
					</FilterByDateWrap>
				</NestedMenuItem>
			</Menu>
		</>
	)
}

{/*    *********Css style************ */ }

const FilterByDateRows = styled('div')({
	marginBottom: '20px',
	display: 'flex',
	alignItems: 'center'
})

const DatePlaceholder = styled('div')({
	flex: 1
})

const FlexContainer = styled('div')({
	flex: 3
})

const FilterByDateWrap = styled('div')({
	padding: '10px 15px',
	width: '280px',
	height: '230px',
	background: '#FFF'
})

const CancelButton = styled(Button)({
	marginRight: '5px',
	background: '#999',
	color: '#FFF',
	'&:hover': {
		opacity: 0.9,
		background: '#999',
	}
})

const SubmitButton = styled(Button)({
	color: '#FFF',
	background: '#009c20',
	'&:hover': {
		opacity: 0.9,
		background: '#009c20',
	}
})

const CfReportWrapper = styled("div")({
	display: 'flex',
	flexWrap: 'wrap',
	flexDirection: 'column',
	padding: '0px 40px',
	gap: '30px'
});

const CfReportPageTitle = styled("span")({
	margin: "40px 0px 15px 0px",
	cursor: "pointer",
	display: 'flex',
	alignItems: "center",
	gap: "15px",
});

const CfHead = styled("div")({
	margin: "40px 0px 15px 0px",
	fontSize: "1.4rem",
	fontWeight: "bolder",
	textTransform: "uppercase",
	"@media screen and (max-width: 768px)": {
		margin: "20px 0px 10px 12px",
	},
});

const CfReportHeadingWrapper = styled("div")({
	display: "flex",
	alignItems: "center"
});

const FilterRowWrapper = styled('div')((props: any) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '10px 0',
	width: '100%',
	gap: '5px',
	[props.theme.breakpoints.down('sm')]: {
		flexDirection: 'column',
		'& > div': {
			width: '100%' // child with 100% width
		}
	}
}))

const TextFieldRounded = styled(TextField)((props: any) => ({
	width: '50%',
	background: '#FFFFFF',
	borderRadius: '15px',
	'& > .MuiOutlinedInput-root': {
		borderRadius: '15px',
	},
	'& > div > input': {
		paddingTop: '14px',
		paddingBottom: '14px',
	},
	[props.theme.breakpoints.down('sm')]: {
		width: '100%',
	}
}));

const FilterWrap = styled('div')((props: any) => ({
	flex: 1,
	textAlign: 'right',
	[props.theme.breakpoints.down('sm')]: {
		textAlign: 'unset',
	},
}));

const MainTableBoxWrapper = styled("div")({
	marginTop: '20px',
	alignItems: 'center',
	width: '100%'
});

const LoaderContainer = styled('div')({
	margin: "auto",
	width: "100%",
	padding: "19px",
	display: "flex",
	justifyContent: "center"
})

const TableWrapper = styled('div')({
	overflow: 'auto',
	width: '100%',
	'& .MuiTableCell-root': {
		borderRight: '1px solid rgba(224, 224, 224, 1)',
		textAlign: 'center',
	},
	'& .table-icons img': {
		margin: '0px 5px',
	}
})

const TableRowBox = styled(TableRow)({
	'& th': {
		borderRight: '1px solid rgba(224, 224, 224, 1)',
		textAlign: 'center',
	},
	'& th:first-child': {
		minWidth: '45px',
	},
	'& th:nth-child(2)': {
		minWidth: '110px',
	},
	'& th:nth-child(3)': {
		minWidth: '110px',
	},
	'& th:nth-child(4)': {
		minWidth: '100px',
		wordBreak: 'break-word'
	},
	'& th:nth-child(5)': {
		minWidth: '60px',
	},
	'& th:nth-child(6)': {
		minWidth: '120px',
	},
	'& th:nth-child(7)': {
		minWidth: '100px',
	},
	'& th:nth-child(8)': {
		minWidth: '100px',
	},
})

const TableBodyBox = styled(TableRow)({
	'& td': {
		borderRight: '1px solid rgba(224, 224, 224, 1)',
		textAlign: 'center',
	},
})

const Pagin = styled('div')({
	alignItems: 'center',
	display: 'flex',
	padding: '10px',
	justifyContent: 'center',
	ul: {
		"& > .MuiPaginationItem-root": {
			color: "green"
		}
	},
	'& > MuiPaginationItem-icon': {
		color: 'green',
	},
})

const EditHeader = styled('div')({
	fontSize: '20px',
	fontWeight: 500,
	fontFamily: 'HelveticaNeue',
	"@media screen and (max-width: 768px)": {
		fontSize: '18px'
	},
})  

// Customizable Area End