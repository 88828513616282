import React from "react";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import {
	iconAdd,
	editImg,
	deleteImg,
	warningIcon,
	viewIcon,
	SaveLoader
} from './assets'
import CfCarbonCalculatorFormController, {
	configJSON,
	Props
} from "./CfCarbonCalculatorFormController";
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import {
	Button,
	Box,
	Modal,
	Backdrop,
	Grid,
	IconButton,
	Typography,
	MenuItem,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableCell,
	TableRow,
	Paper,
	Fade
} from "@material-ui/core";
import Flex from "../../../components/src/Flex.web";
import CloseIcon from "@material-ui/icons/Close";
import { CloseCircleIcon } from "../../../components/src/Icons.web";
import { Formik, Form } from "formik";
import { InputRow, MInput, MSelect } from "../../../components/src/FormModal.web";
import OverlayLoader from '../../../components/src/Loader.web';
import Pagination from '@material-ui/lab/Pagination';
import PDFViewer from "./PdfViewer.web";

export default class CfCarbonCalculator extends CfCarbonCalculatorFormController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start

	CfStationaryCombustionForm = (onCloseCfModal: (activeTab: any) => void, cfForm: boolean) => {
		return (
			<ModalWrapper
				open={cfForm}
				closeAfterTransition
				BackdropComponent={Backdrop}
			>
				<MainModelWrapper>
					<Grid container spacing={0}>
						<ModelHeadWrapper>
							<ModalTitle item xs={12} >
								<Typography style={{ paddingTop: '6px', fontWeight: 'bold', textTransform: "uppercase", }}>
									{this.state.scopeFormTitle}
								</Typography>
								<CloseIconButton data-testid='cfFormCloseBtn' onClick={() => {
									this.setState({ isEditCfStationaryCombustionForm: false, isViewForm: false });
									onCloseCfModal("s1_stationaryCombustion")
								}}>
									<CloseIcon style={{}} />
								</CloseIconButton>
							</ModalTitle>
						</ModelHeadWrapper>
						<ModelBody item xs={12}>
							<Grid item xs={12} style={{ width: "100%" }}>
								<Formik
									initialValues={this.getInitialValuesSc(
										this.state.isEditCfStationaryCombustionForm,
										this.state.editDataCfStationaryCombustionForm
									)}
									onSubmit={(values) => { this.handleFormSubmission(this.state.cfNavActivate, values, onCloseCfModal); }}
									validationSchema={this.stationaryCombustionFormSchema}
								>
									{({
										values,
										handleChange,
										handleSubmit,
										setFieldValue
									}) => {
										return (
											<RightFormWrapper translate="no" onSubmit={handleSubmit}>
												<InputRow
													label={
														<Flex>
															Facility ID<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect
															MenuProps={{
																anchorOrigin: {
																	vertical: "bottom",
																	horizontal: "left"
																},
																transformOrigin: {
																	vertical: "top",
																	horizontal: "left"
																},
																getContentAnchorEl: null
															}}
															name="facility_id"
															disabled={this.state.isViewForm}
															data-testid="facility_id"
															defaultValue={''}
															onChange={handleChange}
															style={{ paddingLeft: '10px' }}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{this.state.facilityIdDropdownData.map((fdata) => <MenuItem value={fdata}>{fdata}</MenuItem>)}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Year<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																vertical: "top",
																horizontal: "left"
															},
															getContentAnchorEl: null,
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
														}}
															name="year"
															defaultValue={''}
															disabled={this.state.isViewForm}
															onChange={handleChange}
															style={{ paddingLeft: '10px' }}
															data-testid="yearSc"
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{this.state.yearsDropdownData.map((yData) => <MenuItem key={yData} value={yData}>{yData}</MenuItem>)}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Custom Emission Factors<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															anchorOrigin: {
																vertical: "bottom",
																horizontal: "left"
															},
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															getContentAnchorEl: null
														}}
															defaultValue={''}
															disabled={this.state.isViewForm}
															name="custom_emissions_factors"
															onChange={(e: any) => {
																handleChange(e);
																this.getFuelListStationary(e, () => {
																	setFieldValue('fuel_name', this.setDefaultValue(this.state.fuelListStationary))
																});
															}}
															data-testid="custom_emissions_factors"
															style={{ paddingLeft: '10px' }}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															<MenuItem value={'false'}>No</MenuItem>
															<MenuItem value={'true'}>Yes</MenuItem>
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Location (Country)<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm}
															defaultValue={''}
															name="location"
															data-testid="location"
															style={{ paddingLeft: '10px' }}
															onChange={handleChange}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{
																this.state.countriesDropdownData.map((countryName) => {
																	return <MenuItem key={countryName} value={countryName}>{countryName}</MenuItem>
																})
															}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Fuel<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm || this.state.fuelListStationary.length <= 1}
															defaultValue={''}
															name="fuel_name"
															data-testid="fuel_name"
															style={{ paddingLeft: '10px' }}
															onChange={handleChange}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{this.state.fuelListStationary.map((data: string) => <MenuItem value={data}>{data}</MenuItem>)}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Amount of Fuel<sup>*</sup>
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="amount_of_fuel"
															inputProps={{ "data-testid": "amount_of_fuel" }}
															onChange={handleChange}
															value={values.amount_of_fuel}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<InputRow
													label={
														<Flex>
															Units (e.g.kg or kWh) <sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm}
															defaultValue={''}
															name="unit_name"
															data-testid="unit_name"
															style={{ paddingLeft: '10px' }}
															onChange={handleChange}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{
																this.state.unitsDropdownData?.map((uName, index) => {
																	return (
																		<MenuItem value={uName}>{uName}</MenuItem>
																	)
																})
															}
														</MSelect>
													}
												/>
												<Box
													style={{
														display: "flex",
														flexDirection: "row",
														justifyContent: "flex-end",
														marginTop: "20px",
														gap: "20px",
													}}
												>
													<Button
														style={{
															backgroundColor: "rgb(205 205 205)",
															paddingTop: "8px",
															minWidth: "110px",
															color: "#fff",
														}}
														onClick={() => {
															onCloseCfModal("s1_stationaryCombustion");
															this.setState({ isEditCfStationaryCombustionForm: false, isViewForm: false });
														}}
														data-testid='cancelButtonSc'
													>
														CANCEL
													</Button>
													<BtnSave>
														<Button
															disabled={this.state.isViewForm}
															type="submit"
															data-testid="submitButtonSc"
															style={{
																backgroundColor: "#009B07",
																paddingTop: "8px",
																minWidth: "110px",
															}}
														>
															SAVE
														</Button>
													</BtnSave>
												</Box>
											</RightFormWrapper>
										)
									}}
								</Formik>
							</Grid>
						</ModelBody>
					</Grid>
				</MainModelWrapper>
			</ModalWrapper >
		)
	}

	CfMobileCombustionForm = (onCloseCfModal: (activeTab: any) => void, cfForm: boolean) => {
		return (
			<ModalWrapper
				open={cfForm}
				closeAfterTransition
				BackdropComponent={Backdrop}
			>
				<MainModelWrapper>
					<Grid container spacing={0}>
						<ModelHeadWrapper>
							<ModalTitle item xs={12} >
								<Typography style={{ paddingTop: '6px', fontWeight: 'bold', textTransform: "uppercase", }}>
									{this.state.scopeFormTitle}
								</Typography>
								<CloseIconButton data-testid='cfFormCloseBtn' onClick={() => {
									this.setState({ isEditCfMobileCombustionForm: false, isViewForm: false });
									onCloseCfModal("s1_mobileCombustion")
								}}>
									<CloseIcon style={{}} />
								</CloseIconButton>
							</ModalTitle>
						</ModelHeadWrapper>
						<ModelBody item xs={12}>
							<Grid item xs={12} style={{ width: "100%" }}>
								<Formik
									initialValues={{
										year: this.state.isEditCfMobileCombustionForm ? this.state.editDataCfMobileCombustionForm.year : "",
										description: this.state.isEditCfMobileCombustionForm ? this.state.editDataCfMobileCombustionForm.description : "",
										facility_id: this.state.isEditCfMobileCombustionForm ? this.state.editDataCfMobileCombustionForm.facility_id : "",
										location: this.state.isEditCfMobileCombustionForm ? this.state.editDataCfMobileCombustionForm.location : "",
										activity_type: this.state.isEditCfMobileCombustionForm ? this.state.editDataCfMobileCombustionForm.activity_type : "",
										fuel_source: this.state.isEditCfMobileCombustionForm ? this.state.editDataCfMobileCombustionForm.fuel_source : "",
										vehicle_type: this.state.isEditCfMobileCombustionForm ? this.state.editDataCfMobileCombustionForm.vehicle_type : "",
										activity_amount: this.state.isEditCfMobileCombustionForm ? this.state.editDataCfMobileCombustionForm.activity_amount : "",
										unit_of_fuel_amount: this.state.isEditCfMobileCombustionForm ? this.state.editDataCfMobileCombustionForm.unit_of_fuel_amount : "",
									}}
									onSubmit={(values) => { this.handleFormSubmission(this.state.cfNavActivate, values, onCloseCfModal) }}
									validationSchema={this.mobileCombustionFormSchema}
								>
									{({
										values,
										handleChange,
										handleSubmit,
										setFieldValue
									}) => {
										let { fuelSourceListMobile, vehicleTypeListMobile } = this.state
										return (
											<RightFormWrapper translate="no" onSubmit={handleSubmit}>
												<InputRow
													label={
														<Flex>
															Year<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm}
															name="year"
															defaultValue={''}
															data-testid="yearMc"
															style={{ paddingLeft: '10px' }}
															onChange={handleChange}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{
																this.state.yearsDropdownData.map((yearData) => <MenuItem key={yearData} value={yearData}>{yearData}</MenuItem>)
															}
														</MSelect>
													}
												/>
												<InputRow
													label={<Flex>Description<sup>*</sup></Flex>}
													input={
														<MInput
															variant="outlined"
															disabled={this.state.isViewForm}
															name="description"
															fullWidth
															onChange={handleChange}
															inputProps={{ "data-testid": "description" }}
															style={{ marginBottom: "0px" }}
															value={values.description}
														/>
													}
												/>
												<InputRow
													label={
														<Flex>
															Facility ID<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																vertical: "bottom",
																horizontal: "left",
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm}
															name="facility_id"
															defaultValue={''}
															data-testid="facility_id_Mc"
															style={{ paddingLeft: '10px' }}
															onChange={handleChange}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{
																this.state.facilityIdDropdownData.map((data, index) => {
																	return (
																		<MenuItem value={data}>{data}</MenuItem>
																	)
																})
															}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Location (Country)<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm}
															defaultValue={''}
															name="location"
															data-testid="location_Mc"
															style={{ paddingLeft: '10px' }}
															onChange={handleChange}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{
																this.state.countriesDropdownData.map((countryName) => {
																	return <MenuItem key={countryName} value={countryName}>{countryName}</MenuItem>
																})
															}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Activity Type<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm}
															defaultValue={''}
															name="activity_type"
															data-testid="activity_type"
															style={{ paddingLeft: '10px' }}
															onChange={(e: any) => {
																handleChange(e);
																this.mobileCombustionFuelSource(e, () => {
																	setFieldValue('fuel_source', this.setDefaultValue(this.state.fuelSourceListMobile));
																	setFieldValue('vehicle_type', this.setDefaultValue(this.state.vehicleTypeListMobile));
																	setFieldValue('unit_of_fuel_amount', '');
																});
															}}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{
																this.state.mobileCombustionDropdownData.activity_type.map((data: string) => <MenuItem value={data}>{data}</MenuItem>)
															}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Fuel Source<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm || fuelSourceListMobile.length <= 1}
															defaultValue={''}
															name="fuel_source"
															data-testid="fuel_source"
															style={{ paddingLeft: '10px' }}
															onChange={(e: any) => {
																handleChange(e);
																this.mobileCombustionVehicleType(e, () => {
																	setFieldValue('vehicle_type', this.setDefaultValue(this.state.vehicleTypeListMobile));
																});
															}}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{fuelSourceListMobile.map((data: string) => <MenuItem value={data}>{data}</MenuItem>)}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Vehicle Type<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm || vehicleTypeListMobile.length <= 1}
															defaultValue={''}
															name="vehicle_type"
															data-testid="vehicle_type"
															style={{ paddingLeft: '10px' }}
															onChange={handleChange}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{vehicleTypeListMobile.map((data: any) => <MenuItem value={data.value}>{data.value}</MenuItem>)}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Activity Amount<sup>*</sup>
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="activity_amount"
															inputProps={{ "data-testid": "activity_amount" }}
															onChange={handleChange}
															value={values.activity_amount}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<InputRow
													label={
														<Flex>
															Unit of Fuel Amount<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm}
															defaultValue={''}
															name="unit_of_fuel_amount"
															data-testid="unit_of_fuel_amount"
															style={{ paddingLeft: '10px' }}
															onChange={handleChange}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{this.mobileCombustionUnitOfFuelAmount(values.activity_type)}
														</MSelect>
													}
												/>
												<Box
													style={{
														display: "flex",
														flexDirection: "row",
														justifyContent: "flex-end",
														marginTop: "20px",
														gap: "20px",
													}}
												>
													<Button
														style={{
															backgroundColor: "rgb(205 205 205)",
															paddingTop: "8px",
															minWidth: "110px",
															color: "#fff",
														}}
														onClick={() => {
															onCloseCfModal("s1_mobileCombustion");
															this.setState({ isEditCfMobileCombustionForm: false, isViewForm: false });
														}}
														data-testid="cancelButtonMc"
													>
														CANCEL
													</Button>
													<BtnSave>
														<Button
															disabled={this.state.isViewForm}
															type="submit"
															data-testid="submitButtonMc"
															style={{
																backgroundColor: "#009B07",
																paddingTop: "8px",
																minWidth: "110px",
															}}
														>
															SAVE
														</Button>
													</BtnSave>
												</Box>
											</RightFormWrapper>
										)
									}}
								</Formik>
							</Grid>
						</ModelBody>
					</Grid>
				</MainModelWrapper>
			</ModalWrapper >
		)
	}

	cfRefrigerentsForm = (onCloseCfModal: (activeTab: any) => void, cfForm: boolean) => {
		return (
			<ModalWrapper
				open={cfForm}
				closeAfterTransition
				BackdropComponent={Backdrop}
			>
				<MainModelWrapper>
					<Grid container spacing={0}>
						<ModelHeadWrapper>
							<ModalTitle item xs={12} >
								<Typography style={{ paddingTop: '6px', fontWeight: 'bold', textTransform: "uppercase", }}>
									{this.state.scopeFormTitle}
								</Typography>
								<CloseIconButton data-testid='cfFormCloseBtn' onClick={() => {
									this.setState({ isEditCfRefrigerentsForm: false, isViewForm: false });
									onCloseCfModal("s1_refrigerants")
								}}>
									<CloseIcon style={{}} />
								</CloseIconButton>
							</ModalTitle>
						</ModelHeadWrapper>
						<ModelBody item xs={12}>
							<Grid item xs={12} style={{ width: "100%" }}>
								<Formik
									initialValues={this.getInitialValuesRf(
										this.state.isEditCfRefrigerentsForm,
										this.state.editDataCfRefrigerentsForm
									)}
									onSubmit={(values) => { this.handleFormSubmission(this.state.cfNavActivate, values, onCloseCfModal) }}
									validationSchema={this.refrigerentsFormSchema}
								>
									{({
										values,
										handleChange,
										handleSubmit
									}) => {
										return (
											<RightFormWrapper translate="no" onSubmit={handleSubmit}>
												<InputRow
													label={
														<Flex>
															Year<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															style={{ paddingLeft: '10px' }}
															defaultValue={''}
															name="year"
															onChange={handleChange}
															data-testid="yearRf"
															disabled={this.state.isViewForm}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{this.state.yearsDropdownData.map((data) => {
																return <MenuItem key={data} value={data}>{data}</MenuItem>
															})}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>Facility ID<sup>*</sup></Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																vertical: "top",
																horizontal: "left",
															},
															getContentAnchorEl: null,
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
														}}
															disabled={this.state.isViewForm}
															defaultValue={''}
															data-testid="facility_id_Rf"
															name="facility_id"
															style={{ paddingLeft: '10px' }}
															onChange={handleChange}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{
																this.state.facilityIdDropdownData.map((data, index) => {
																	return (
																		<MenuItem value={data}>{data}</MenuItem>
																	)
																})
															}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>Location (Country)<sup>*</sup></Flex>
													}
													input={
														<MSelect MenuProps={{
															getContentAnchorEl: null,
															transformOrigin: {
																horizontal: "left",
																vertical: "top",
															},
															anchorOrigin: {
																vertical: "bottom",
																horizontal: "left",
															},
														}}
															onChange={handleChange}
															style={{ paddingLeft: '10px' }}
															defaultValue={''}
															name="location"
															disabled={this.state.isViewForm}
															data-testid="locationRf"
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{this.state.countriesDropdownData.map((countryName) => {
																return <MenuItem key={countryName} value={countryName}>{countryName}</MenuItem>
															})
															}
														</MSelect>
													}
												/>
												<FormSubFieldTitle>Equipment and Refrigerant Type</FormSubFieldTitle>
												<InputRow
													label={
														<Flex>
															Type of Air Conditioning and Refrigeration Equipment<sup>*</sup>
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="type_of_air_conditioning_and_refrigeration_equipment"
															inputProps={{ "data-testid": "type_of_air_conditioning_and_refrigeration_equipment" }}
															onChange={handleChange}
															value={values.type_of_air_conditioning_and_refrigeration_equipment}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<InputRow
													label={
														<Flex>
															Refrigerant Used<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm}
															defaultValue={''}
															name="refrigerant_used"
															data-testid="refrigerant_used"
															style={{ paddingLeft: '10px' }}
															onChange={handleChange}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{
																this.state.refrigerentsDropdownData.refrigerant_used.map((data: string) => <MenuItem value={data}>{data}</MenuItem>)
															}
														</MSelect>
													}
												/>
												<FormSubFieldTitle>Decrease in Inventory (kilograms)</FormSubFieldTitle>
												<InputRow
													label={
														<Flex>
															Refrigerant inventory (in storage, not(equipment) at the beginning of the year)<sup>*</sup>
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="refrigerant_inventory_beginning_year"
															inputProps={{ "data-testid": "refrigerant_inventory_beginning_year" }}
															onChange={handleChange}
															value={values.refrigerant_inventory_beginning_year}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<InputRow
													label={
														<Flex>
															Refrigerant inventory (in storage, not(equipment) at the end of the year)<sup>*</sup>
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="refrigerant_inventory_end_year"
															inputProps={{ "data-testid": "refrigerant_inventory_end_year" }}
															onChange={handleChange}
															value={values.refrigerant_inventory_end_year}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<FormSubFieldTitle>Purchases/Acquisitions of refrigerant (kilograms)</FormSubFieldTitle>
												<InputRow
													label={
														<Flex>
															Refrigerant purchased from producers/ distributors<sup>*</sup>
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="refrigerant_purchased_from_producers"
															inputProps={{ "data-testid": "refrigerant_purchased_from_producers" }}
															onChange={handleChange}
															value={values.refrigerant_purchased_from_producers}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<InputRow
													label={
														<Flex>
															Refrigerant returned by equipment users<sup>*</sup>
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="refrigerant_returned_by_equipment_users"
															inputProps={{ "data-testid": "refrigerant_returned_by_equipment_users" }}
															onChange={handleChange}
															value={values.refrigerant_returned_by_equipment_users}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<InputRow
													label={
														<Flex>
															Refrigerant returned after off-site recycling or reclamation<sup>*</sup>
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="refrigerant_off_site_recycling_purchases"
															inputProps={{ "data-testid": "refrigerant_off_site_recycling_purchases" }}
															onChange={handleChange}
															value={values.refrigerant_off_site_recycling_purchases}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<FormSubFieldTitle>Sales/Disbursements of Refrigerant (kilograms)</FormSubFieldTitle>
												<InputRow
													label={
														<Flex>
															Refrigerant charged into equipment<sup>*</sup>
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="refrigerant_charged_into_equipment"
															inputProps={{ "data-testid": "refrigerant_charged_into_equipment" }}
															onChange={handleChange}
															value={values.refrigerant_charged_into_equipment}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<InputRow
													label={
														<Flex>
															Refrigerant delivered to equipment users in containers<sup>*</sup>
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="refrigerant_delivered_to_equipment_users_containers"
															inputProps={{ "data-testid": "refrigerant_delivered_to_equipment_users_containers" }}
															onChange={handleChange}
															value={values.refrigerant_delivered_to_equipment_users_containers}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<InputRow
													label={
														<Flex>
															Refrigerant returned to refrigerant producers<sup>*</sup>
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="refrigerant_returned_to_producers"
															inputProps={{ "data-testid": "refrigerant_returned_to_producers" }}
															onChange={handleChange}
															value={values.refrigerant_returned_to_producers}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<InputRow
													label={
														<Flex>
															Refrigerant sent off-site for recycling or reclamation<sup>*</sup>
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="refrigerant_off_site_recycling_sales"
															inputProps={{ "data-testid": "refrigerant_off_site_recycling_sales" }}
															onChange={handleChange}
															value={values.refrigerant_off_site_recycling_sales}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<InputRow
													label={
														<Flex>
															Refrigerant sent off-site for destruction<sup>*</sup>
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="refrigerant_sent_off_site_destruction"
															inputProps={{ "data-testid": "refrigerant_sent_off_site_destruction" }}
															onChange={handleChange}
															value={values.refrigerant_sent_off_site_destruction}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<FormSubFieldTitle>Refrigerant charged into equipment (kilograms)</FormSubFieldTitle>
												<InputRow
													label={
														<Flex>
															Nameplate Capacity of Partially Charged Equipment<sup>*</sup>
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="capacity_of_partially_charged_equipment"
															inputProps={{ "data-testid": "capacity_of_partially_charged_equipment" }}
															onChange={handleChange}
															value={values.capacity_of_partially_charged_equipment}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<InputRow
													label={
														<Flex>
															Density or Pressure** of partial charge<sup>*</sup>
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="pressure_of_partial_charge"
															inputProps={{ "data-testid": "pressure_of_partial_charge" }}
															onChange={handleChange}
															value={values.pressure_of_partial_charge}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<InputRow
													label={
														<Flex>
															Density or Pressure** of full charge<sup>*</sup>
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="pressure_of_full_charge"
															inputProps={{ "data-testid": "pressure_of_full_charge" }}
															onChange={handleChange}
															value={values.pressure_of_full_charge}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<Box
													style={{
														display: "flex",
														flexDirection: "row",
														justifyContent: "flex-end",
														marginTop: "20px",
														gap: "20px",
													}}
												>
													<Button
														style={{
															backgroundColor: "rgb(205 205 205)",
															paddingTop: "8px",
															minWidth: "110px",
															color: "#fff",
														}}
														onClick={() => {
															onCloseCfModal("s1_refrigerants");
															this.setState({ isEditCfRefrigerentsForm: false, isViewForm: false });
														}}
														data-testid="cancelButtonRf"
													>
														CANCEL
													</Button>
													<BtnSave>
														<Button
															disabled={this.state.isViewForm}
															type="submit"
															style={{
																backgroundColor: "#009B07",
																paddingTop: "8px",
																minWidth: "110px",
															}}
															data-testid="saveButtonRf"
														>
															SAVE
														</Button>
													</BtnSave>
												</Box>
											</RightFormWrapper>
										)
									}}
								</Formik>
							</Grid>
						</ModelBody>
					</Grid>
				</MainModelWrapper>
			</ModalWrapper >
		)
	}

	cfPurchasedElectricityForm = (onCloseCfModal: (activeTab: any) => void, cfForm: boolean) => {
		return (
			<ModalWrapper
				open={cfForm}
				closeAfterTransition
				BackdropComponent={Backdrop}
			>
				<MainModelWrapper>
					<Grid container spacing={0}>
						<ModelHeadWrapper>
							<ModalTitle item xs={12} >
								<Typography style={{ paddingTop: '6px', fontWeight: 'bold', textTransform: "uppercase", }}>
									{this.state.scopeFormTitle}
								</Typography>
								<CloseIconButton data-testid='cfFormCloseBtn' onClick={() => {
									this.setState({ isEditCfPurchasedElectricityForm: false, isViewForm: false });
									onCloseCfModal("s2_purchasedElectricity")
								}}>
									<CloseIcon style={{}} />
								</CloseIconButton>
							</ModalTitle>
						</ModelHeadWrapper>
						<ModelBody item xs={12}>
							<Grid item xs={12} style={{ width: "100%" }}>
								<Formik
									initialValues={{
										year: this.state.isEditCfPurchasedElectricityForm ? this.state.editDataCfPurchasedElectricityForm.year : "",
										facility_id: this.state.isEditCfPurchasedElectricityForm ? this.state.editDataCfPurchasedElectricityForm.facility_id : "",
										location: this.state.isEditCfPurchasedElectricityForm ? this.state.editDataCfPurchasedElectricityForm.location : "",
										amount_of_electricity_consumption: this.state.isEditCfPurchasedElectricityForm ? this.state.editDataCfPurchasedElectricityForm.amount_of_electricity_consumption : "",
										units: this.state.isEditCfPurchasedElectricityForm ? this.state.editDataCfPurchasedElectricityForm.units : "",
										calculation_approach: this.state.isEditCfPurchasedElectricityForm ? this.state.editDataCfPurchasedElectricityForm.calculation_approach : "",
										type_of_emission_factor: this.state.isEditCfPurchasedElectricityForm ? this.state.editDataCfPurchasedElectricityForm.type_of_emission_factor : "",
										custom_emission_factor: this.state.isEditCfPurchasedElectricityForm ? this.state.editDataCfPurchasedElectricityForm.custom_emission_factor : "",
									}}
									onSubmit={(values) => { this.handleFormSubmission(this.state.cfNavActivate, values, onCloseCfModal) }}
									validationSchema={this.purchasedElectricityFormSchema}
								>
									{({
										values,
										handleChange,
										handleSubmit,
										setFieldValue,
									}) => {
										return (
											<RightFormWrapper translate="no" onSubmit={handleSubmit}>
												<InputRow
													label={
														<Flex>
															Year<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm}
															defaultValue={''}
															name="year"
															data-testid="yearPe"
															style={{ paddingLeft: '10px' }}
															onChange={handleChange}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{
																this.state.yearsDropdownData.map((data) => {
																	return <MenuItem key={data} value={data}>{data}</MenuItem>
																})
															}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Facility ID<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															transformOrigin: {
																vertical: "top",
																horizontal: "left",
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm}
															name="facility_id"
															defaultValue={''}
															data-testid="facility_id_Pe"
															style={{ paddingLeft: '10px' }}
															onChange={handleChange}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{
																this.state.facilityIdDropdownData.map((data, index) => {
																	return (
																		<MenuItem value={data}>{data}</MenuItem>
																	)
																})
															}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Location (Country)<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm}
															defaultValue={''}
															name="location"
															data-testid="locationPe"
															style={{ paddingLeft: '10px' }}
															onChange={handleChange}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{
																this.state.countriesDropdownData.map((countryName) => {
																	return <MenuItem key={countryName} value={countryName}>{countryName}</MenuItem>
																})
															}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Amount Of Electricity Consumption<sup>*</sup>
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="amount_of_electricity_consumption"
															inputProps={{ "data-testid": "amount_of_electricity_consumption" }}
															onChange={handleChange}
															value={values.amount_of_electricity_consumption}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<InputRow
													label={
														<Flex>
															Units<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm}
															defaultValue={''}
															name="units"
															data-testid="units"
															style={{ paddingLeft: '10px' }}
															onChange={handleChange}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{
																this.state.unitsDropdownData?.map((data, index) => {
																	return (
																		<MenuItem value={data}>{data}</MenuItem>
																	)
																})
															}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Calculation Approach<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm}
															defaultValue={''}
															name="calculation_approach"
															data-testid="calculation_approach"
															style={{ paddingLeft: '10px' }}
															onChange={(e: any) => {
																setFieldValue('type_of_emission_factor', '')
																setFieldValue('custom_emission_factor','')
																handleChange(e);
															}}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															<MenuItem value={'Heat/Steam'}>Heat/Steam</MenuItem>
															<MenuItem value={'Purchased Electricity - Location Based'}>Purchased Electricity - Location Based</MenuItem>
															<MenuItem value={'Purchased Electricity - Market Based'}>Purchased Electricity - Market Based</MenuItem>
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Types Of Emission Factor<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm}
															defaultValue={''}
															name="type_of_emission_factor"
															data-testid="type_of_emission_factor"
															style={{ paddingLeft: '10px' }}
															onChange={(e: any) => {
																handleChange(e);
																this.purchasedEmissionFactorValue(e, values, setFieldValue)
															}}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															<MenuItem value={'Custom emission factor'}>Custom emission factor</MenuItem>
															<MenuItem value={'Grid Average/Location Based'}>Grid Average/Location Based</MenuItem>
															{values.calculation_approach == 'Purchased Electricity - Market Based' && <MenuItem value={'Residual Mix'}>Residual Mix</MenuItem>}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Custom Emission Factor<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm || this.state.filterPurchasedEmissionFactor.length <= 1}
															defaultValue={''}
															name="custom_emission_factor"
															data-testid="custom_emission_factor"
															style={{ paddingLeft: '10px' }}
															onChange={handleChange}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{this.state.filterPurchasedEmissionFactor.map((data: any) => <MenuItem value={data}>{data}</MenuItem>)}
														</MSelect>
													}
												/>
												<Box
													style={{
														display: "flex",
														flexDirection: "row",
														justifyContent: "flex-end",
														marginTop: "20px",
														gap: "20px",
													}}
												>
													<Button
														style={{
															backgroundColor: "rgb(205 205 205)",
															paddingTop: "8px",
															minWidth: "110px",
															color: "#fff",
														}}
														onClick={() => {
															onCloseCfModal("s2_purchasedElectricity");
															this.setState({ isEditCfPurchasedElectricityForm: false, isViewForm: false });
														}}
														data-testid="cancelButtonPe"
													>
														CANCEL
													</Button>
													<BtnSave>
														<Button
															disabled={this.state.isViewForm}
															type="submit"
															data-testid="submitButtonPe"
															style={{
																backgroundColor: "#009B07",
																paddingTop: "8px",
																minWidth: "110px",
															}}
														>
															SAVE
														</Button>
													</BtnSave>
												</Box>
											</RightFormWrapper>
										)
									}}
								</Formik>
							</Grid>
						</ModelBody>
					</Grid>
				</MainModelWrapper>
			</ModalWrapper >
		)
	}

	renderModeofTransport = (handleChange: Function, values: { [key: string]: string }, setFieldValue: Function) => {
		return (
			<>
				{this.checkedCategory() && <InputRow
					label={
						<Flex>
							{configJSON.modeOfTransport}<sup>*</sup>
						</Flex>
					}
					input={
						<MSelect MenuProps={{
							transformOrigin: {
								horizontal: "left",
								vertical: "top"
							},
							anchorOrigin: {
								horizontal: "left",
								vertical: "bottom"
							},
							getContentAnchorEl: null
						}}
							disabled={this.state.isViewForm}
							defaultValue={''}
							name="mode_of_transport"
							data-testid="mode_of_transport"
							style={{ paddingLeft: '10px' }}
							onChange={(event) => {
								handleChange(event);
								this.transportationVehicleType(event, values, setFieldValue)
							}}
						>
							<MenuItem disabled value={''}>Select</MenuItem>
							{
								this.state.transportDropdownData.mode_of_transport.map((data: string) => <MenuItem value={data}>{data}</MenuItem>)
							}
						</MSelect>
					}
				/>}
			</>
		)
	};

	renderAmountOfActivity = (handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>, values: { [key: string]: string }) => {
		return (
			<>
				{this.checkedCategory() && <InputRow
					label={
						<Flex>
							{configJSON.amountOfActivityType}<sup>*</sup>
						</Flex>
					}
					input={
						<MInput
							disabled={this.state.isViewForm}
							variant="outlined"
							fullWidth
							name="amount_of_activity_type"
							inputProps={{ "data-testid": "amount_of_activity_type" }}
							onChange={handleChange}
							value={values.amount_of_activity_type}
							style={{ marginBottom: "0px" }}
						/>
					}
				/>}
			</>
		)
	};

	renderEmissionMethodology = (handleChange: ((event: React.ChangeEvent<{ name?: string ; value: unknown; }>, child: React.ReactNode) => void) | undefined) => {
		return (
			<>
				{!this.checkedCategory() && (
					<InputRow
						label={
							<Flex>
								{configJSON.emissionMethodology}<sup>*</sup>
							</Flex>
						}
						input={
							<MSelect
								MenuProps={{
									transformOrigin: {
										horizontal: "left",
										vertical: "top",
									},
									anchorOrigin: {
										horizontal: "left",
										vertical: "bottom",
									},
									getContentAnchorEl: null,
								}}
								disabled={this.state.isViewForm}
								defaultValue={""}
								name="emission_calculation_methodology"
								data-testid="emission_calculation_methodology"
								style={{ paddingLeft: "10px" }}
								onChange={handleChange}
							>
								<MenuItem disabled value={""}>
									Select
								</MenuItem>
								{this.state.transportDropdownData.emission_calculation_methodology.map(
									(data: string) => (
										<MenuItem value={data}>{data}</MenuItem>
									)
								)}
							</MSelect>
						}
					/>
				)}
			</>
		)
	};

	renderNumberOfUnits = (handleChange:React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>,values:{[key:string]:string}) => {
		return (
			<>
				 
					<InputRow
						label={<Flex>Number of units</Flex>}
						input={
							<MInput
								disabled={this.state.isViewForm}
								variant="outlined"
								fullWidth
								name="number_of_units"
								inputProps={{
									"data-testid": "number_of_units",
								}}
								onChange={handleChange}
								value={values.number_of_units}
								style={{ marginBottom: "0px" }}
							/>
						}
					/>
				
			</>
		)
	};

	renderEmission = (handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>, values: { [key: string]: string }) => {
		return (
			<>
				{this.checkedCategory() && <InputRow
					label={
						<Flex>

							{configJSON.emissionco2e}
						</Flex>
					}
					input={
						<MInput
							disabled={this.state.isViewForm}
							variant="outlined"
							fullWidth
							name="co2_emissions"
							inputProps={{ "data-testid": "co2_emissions" }}
							onChange={handleChange}
							value={values.co2_emissions}
							style={{ marginBottom: "0px" }}
						/>
					}
				/>}
			</>
		)
	};

	renderBioFuel = (handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>, values: { [key: string]: string }) => {
		return (
			<>
				{this.checkedCategory() && <InputRow
					label={
						<Flex>
							Biofuel CO<sub>2</sub> (tonnes)
						</Flex>
					}
					input={
						<MInput
							disabled={this.state.isViewForm}
							variant="outlined"
							fullWidth
							name="biofuel_co2_tonnes"
							inputProps={{ "data-testid": "biofuel_co2_tonnes" }}
							onChange={handleChange}
							value={values.biofuel_co2_tonnes}
							style={{ marginBottom: "0px" }}
						/>
					}
				/>}
			</>
		)
	};

	renderEF = (handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>, values: { [key: string]: string }) => {
		return (
			<>
				{this.checkedCategory() && <InputRow
					label={
						<Flex>
							EF (kgCO<sub>2</sub>e/unit)
						</Flex>
					}
					input={
						<MInput
							disabled={this.state.isViewForm}
							variant="outlined"
							fullWidth
							name="ef_kg"
							inputProps={{ "data-testid": "ef_kg" }}
							onChange={handleChange}
							value={values.ef_kg}
							style={{ marginBottom: "0px" }}
						/>
					}
				/>}
			</>
		)
	};

	renderTotalEmission = (handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>, values: { [key: string]: string }) => {
		return (
			<>
				{!this.checkedCategory() && (
					<InputRow
						label={<Flex>{configJSON.totalEmission}</Flex>}
						input={
							<MInput
								disabled={this.state.isViewForm}
								variant="outlined"
								fullWidth
								name="total_emission"
								inputProps={{ "data-testid": "total_emission" }}
								onChange={handleChange}
								value={values.total_emission}
								style={{ marginBottom: "0px" }}
							/>
						}
					/>
				)}
			</>
		)
	};

	cfTransportationForm = (onCloseCfModal: (activeTab: any) => void, cfForm: boolean) => {
		let getValue = (isEdit:boolean,key:string) => {
			let value = isEdit ? this.state.editDataCfTransportationForm[key] : '';
			console.log("getValue+++",value);
			return  value;
		}
		return (
			<ModalWrapper
				open={cfForm}
				closeAfterTransition
				BackdropComponent={Backdrop}
			>
				<MainModelWrapper>
					<Grid container spacing={0}>
						<ModelHeadWrapper>
							<ModalTitle item xs={12} >
								<Typography style={{ paddingTop: '6px', fontWeight: 'bold', textTransform: "uppercase", }}>
									{this.state.scopeFormTitle}
								</Typography>
								<CloseIconButton data-testid='cfFormCloseBtn' onClick={() => {
									this.setState({ isEditCfTransportationForm: false, isViewForm: false });
									onCloseCfModal("s3_transportation")
								}}>
									<CloseIcon style={{}} />
								</CloseIconButton>
							</ModalTitle>
						</ModelHeadWrapper>
						<ModelBody item xs={12}>
							<Grid item xs={12} style={{ width: "100%" }}>
								<Formik
									initialValues={{
										year: this.state.isEditCfTransportationForm ? this.state.editDataCfTransportationForm.year : "",
										description: this.state.isEditCfTransportationForm ? this.state.editDataCfTransportationForm.description : "",
										category: this.state.isEditCfTransportationForm ? this.state.editDataCfTransportationForm.category : "",
										location: this.state.isEditCfTransportationForm ? this.state.editDataCfTransportationForm.location : "",
										emission_factor_dataset: this.state.isEditCfTransportationForm ? this.state.editDataCfTransportationForm.emission_factor_dataset : "",
										mode_of_transport: this.state.isEditCfTransportationForm ? this.state.editDataCfTransportationForm.mode_of_transport : "",
										activity_type: this.state.isEditCfTransportationForm ? this.state.editDataCfTransportationForm.activity_type : "",
										vehicle_type: getValue(this.state.isEditCfTransportationForm,"vehicle_type"),
										amount_of_activity_type: getValue(this.state.isEditCfTransportationForm,"amount_of_activity_type"),
										emission_calculation_methodology: getValue(this.state.isEditCfTransportationForm,"emission_calculation_methodology"),
									    number_of_units: getValue(this.state.isEditCfTransportationForm,"number_of_units"),
										units_of_measurement: this.state.isEditCfTransportationForm ? this.state.editDataCfTransportationForm.units_of_measurement : "",
										co2_emissions: getValue(this.state.isEditCfTransportationForm,'co2_emissions'),
										percentage_emissions: getValue(this.state.isEditCfTransportationForm,'percentage_emissions'),
										co2_tonnes: getValue(this.state.isEditCfTransportationForm,'co2_tonnes'),
										ch4_tonnes: getValue(this.state.isEditCfTransportationForm,'ch4_tonnes'),
										n2o_tonnes: getValue(this.state.isEditCfTransportationForm,'n2o_tonnes'),
										co2e_tonnes: getValue(this.state.isEditCfTransportationForm,'co2e_tonnes'),
										biofuel_co2_tonnes: getValue(this.state.isEditCfTransportationForm,'biofuel_co2_tonnes'),
										ef_kg: getValue(this.state.isEditCfTransportationForm,'ef_kg'),
										total_emission: getValue(this.state.isEditCfTransportationForm,"total_emission"),
									}}
									onSubmit={(values) => { this.handleFormSubmission(this.state.cfNavActivate, values, onCloseCfModal) }}
									validationSchema={this.transportationFormSchema}
								>
									{({
										values,
										handleChange,
										handleSubmit,
										setFieldValue
									}) => {
										return (
											<RightFormWrapper translate="no" onSubmit={handleSubmit}>
												<InputRow
													label={
														<Flex>
															Year<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect
															MenuProps={{
																getContentAnchorEl: null,
																anchorOrigin: {
																	horizontal: "left",
																	vertical: "bottom"
																},
																transformOrigin: {
																	horizontal: "left",
																	vertical: "top"
																},

															}}
															name="year"
															disabled={this.state.isViewForm}
															defaultValue={''}
															style={{ paddingLeft: '10px' }}
															onChange={handleChange}
															data-testid="yearTs"
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{
																this.state.yearsDropdownData.map((data) => {
																	return <MenuItem key={data} value={data}>{data}</MenuItem>
																})
															}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Description<sup>*</sup>
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="description"
															inputProps={{ "data-testid": "descriptionTs" }}
															onChange={handleChange}
															value={values.description}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<InputRow
													label={
														<Flex>
															Category<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															defaultValue={''}
															disabled={this.state.isViewForm}
															name="category"
															style={{ paddingLeft: '10px' }}
															onChange={(event)=>{
																setFieldValue("category",event.target.value);
																this.setState({selectedCategory:event.target.value})
															}}
															data-testid="categoryTs"
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{this.state.transportDropdownData.category.map((data: string) => {
																return (<MenuItem value={data}>{data}</MenuItem>)
															})
															}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Location (Country)<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															getContentAnchorEl: null,
															anchorOrigin: {
																vertical: "bottom",
																horizontal: "left",
															},
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
														}}
															style={{ paddingLeft: '10px' }}
															disabled={this.state.isViewForm}
															name="location"
															defaultValue={''}
															onChange={handleChange}
															data-testid="locationTs"
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{
																this.state.countriesDropdownData.map((countryName) => {
																	return <MenuItem key={countryName} value={countryName}>{countryName}</MenuItem>
																})
															}
														</MSelect>
													}
												/>
												<InputRow
													label={
														<Flex>
															Emission Factor Dataset<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm}
															defaultValue={''}
															name="emission_factor_dataset"
															data-testid="emission_factor_dataset"
															style={{ paddingLeft: '10px' }}
															onChange={ (e: any) =>{
																handleChange(e);
																this.transportationFactorDataset(e, setFieldValue)
															}}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{
																this.state.transportDropdownData.emission_factor_dataset.map((data: string) => <MenuItem value={data}>{data}</MenuItem>)
															}
														</MSelect>
													}
												/>
												{this.renderModeofTransport(handleChange,values,setFieldValue)}
												{this.checkedCategory() && <InputRow
													label={
														<Flex>
															Activity Type<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm || this.state.activityTypeList.length <= 1}
															defaultValue={''}
															name="activity_type"
															data-testid="activity_type"
															style={{ paddingLeft: '10px' }}
															onChange={(e: any) => {
																handleChange(e);
																this.transportUnitsOfMeasurement(e)
																setFieldValue('units_of_measurement','')
															}}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{this.state.activityTypeList.map((data: string) => <MenuItem value={data}>{data}</MenuItem>)}
														</MSelect>
													}
												/>}
												{this.checkedCategory() && <InputRow
													label={
														<Flex>
															Vehicle Type<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm || this.state.vehicleTypeListTransport.length <= 1}
															defaultValue={''}
															name="vehicle_type"
															data-testid="vehicle_type_Ts"
															style={{ paddingLeft: '10px' }}
															onChange={handleChange}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{this.state.vehicleTypeListTransport.map((data: any) => <MenuItem value={data.value}>{data.value}</MenuItem>)}
														</MSelect>
													}
												/>}
												{this.renderAmountOfActivity(handleChange,values)}
												{this.renderEmissionMethodology(handleChange)}
												{this.renderNumberOfUnits(handleChange,values)}
												<InputRow
													label={
														<Flex>
															Units Of Measurement<sup>*</sup>
														</Flex>
													}
													input={
														<MSelect MenuProps={{
															transformOrigin: {
																horizontal: "left",
																vertical: "top"
															},
															anchorOrigin: {
																horizontal: "left",
																vertical: "bottom"
															},
															getContentAnchorEl: null
														}}
															disabled={this.state.isViewForm}
															defaultValue={''}
															name="units_of_measurement"
															data-testid="units_of_measurement"
															style={{ paddingLeft: '10px' }}
															onChange={handleChange}
														>
															<MenuItem disabled value={''}>Select</MenuItem>
															{
																this.state.unitsOfMeasurement.map((data: any) => <MenuItem value={data.value}>{data.value}</MenuItem>)
															}
														</MSelect>
													}
												/>
												{this.renderEmission(handleChange,values)}
												<InputRow
													label={
														<Flex>
															Percentage of emissions calculated using data obtained from suppliers or value chain partners
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="percentage_emissions"
															inputProps={{ "data-testid": "percentage_emissions" }}
															onChange={handleChange}
															value={values.percentage_emissions}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>

												<InputRow
													label={
														<Flex>
															CO<sub>2</sub> (tonnes)
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="co2_tonnes"
															inputProps={{ "data-testid": "co2_tonnes" }}
															onChange={handleChange}
															value={values.co2_tonnes}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												<InputRow
													label={
														<Flex>
															CH<sub>4</sub> (tonnes)
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="ch4_tonnes"
															inputProps={{ "data-testid": "ch4_tonnes" }}
															onChange={handleChange}
															value={values.ch4_tonnes}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>

												<InputRow
													label={
														<Flex>
															N<sub>2</sub>O (tonnes)
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="n2o_tonnes"
															inputProps={{ "data-testid": "n2o_tonnes" }}
															onChange={handleChange}
															value={values.n2o_tonnes}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>

												<InputRow
													label={
														<Flex>
															CO<sub>2</sub>e (tonnes)
														</Flex>
													}
													input={
														<MInput
															disabled={this.state.isViewForm}
															variant="outlined"
															fullWidth
															name="co2e_tonnes"
															inputProps={{ "data-testid": "co2e_tonnes" }}
															onChange={handleChange}
															value={values.co2e_tonnes}
															style={{ marginBottom: "0px" }}
														/>
													}
												/>
												{this.renderBioFuel(handleChange,values)}
												{this.renderEF(handleChange,values)}
												{this.renderTotalEmission(handleChange,values)}
												<Box
													style={{
														display: "flex",
														flexDirection: "row",
														justifyContent: "flex-end",
														marginTop: "20px",
														gap: "20px",
													}}
												>
													<Button
														style={{
															backgroundColor: "rgb(205 205 205)",
															paddingTop: "8px",
															minWidth: "110px",
															color: "#fff",
														}}
														onClick={() => {
															onCloseCfModal("s3_transportation");
															this.setState({ isEditCfTransportationForm: false, isViewForm: false });
														}}
														data-testid="cancelButtonTs"
													>
														CANCEL
													</Button>
													<BtnSave>
														<Button
															disabled={this.state.isViewForm}
															type="submit"
															data-testid="submitButtonTs"
															style={{
																backgroundColor: "#009B07",
																paddingTop: "8px",
																minWidth: "110px",
															}}
														>
															SAVE
														</Button>
													</BtnSave>
												</Box>
											</RightFormWrapper>
										)
									}}
								</Formik>
							</Grid>
						</ModelBody>
					</Grid>
				</MainModelWrapper>
			</ModalWrapper >
		)
	}


	renderHeaders = (headers: any, isChildren?: boolean) => {
		return (headers.map((header: any, index: number) => {
			if (typeof header === 'string') {
				return (
					<TableCell size="medium" rowSpan={isChildren ? 0 : 2} key={index + 1} style={{ fontWeight: 'bold', color: 'black', minWidth: '150px' }}>
						{header}
					</TableCell>
				);
			} else {
				return (
					<TableCell size="medium" key={index + 1} colSpan={header?.children.length} style={{ fontWeight: 'bold', color: 'black' }}>
						{header?.name}
					</TableCell>
				);
			}
		}));
	};

	renderSubHeaders = (headers: any) => {
		return (headers.map((header: any, index: number) => {
			return (
				<>{header?.children && (
					this.renderHeaders(header?.children, true)
				)}</>
			)
		}));
	};

	renderTableDataRow = () => {
		switch (this.state.cfNavActivate) {
			case "s1_stationaryCombustion":
				return this.state.CfStationaryCombustionTableData?.length > 0 ?
					this.state.CfStationaryCombustionTableData?.map((row: any, index: number) => (
						<TableRow key={index + 1}>
							<TableCell>{row.facility_id}</TableCell>
							<TableCell>{row.year}</TableCell>
							<TableCell>{this.showBoolean(row.custom_emissions_factors)}</TableCell>
							<TableCell>{row.location}</TableCell>
							<TableCell>{row.fuel_name}</TableCell>
							<TableCell>{row.amount_of_fuel}</TableCell>
							<TableCell>{row.unit_name}</TableCell>
							<TableCell className='table-icons'>
								<BtnImg
									src={viewIcon}
									style={{ cursor: 'pointer' }}
									data-testid="viewIcoSc"
									onClick={() => {
										this.setState({ isViewForm: true, isEditCfStationaryCombustionForm: true, editDataCfStationaryCombustionForm: row, editCfStationaryCombustionDataIndex: { ind: index, key: "s1_stationaryCombustion" } });
										this.CfHandleFormModel(this.state.cfNavActivate);
									}}
								/>
								{
									!this.state.isReadable &&
									<>
										<BtnImg
											src={editImg}
											style={{ cursor: 'pointer' }}
											data-testid="editIcoSc"
											onClick={() => {
												this.setState({ isEditCfStationaryCombustionForm: true, editDataCfStationaryCombustionForm: row, editCfStationaryCombustionDataIndex: { ind: index, key: "s1_stationaryCombustion" } });
												this.CfHandleFormModel(this.state.cfNavActivate);
											}}
										/>
										<BtnImg
											src={deleteImg}
											style={{ cursor: 'pointer' }}
											data-testid="deleteIcoSc"
											onClick={() => { this.setState({ isDeleteModelOpen: true, deleteModelIndex: index }) }}
										/>
									</>
								}
							</TableCell>
						</TableRow>
					)) : <TableCell style={{ textAlign: 'center' }} colSpan={18}>No Data Found</TableCell>
			case "s1_mobileCombustion":
				return this.state.cfMobileCombustionTableData?.length > 0 ?
					this.state.cfMobileCombustionTableData?.map((row: any, index: number) => (
						<TableRow key={index + 1}>
							<TableCell>{row.year}</TableCell>
							<TableCell>{row.description}</TableCell>
							<TableCell>{row.facility_id}</TableCell>
							<TableCell>{row.location}</TableCell>
							<TableCell>{row.activity_type}</TableCell>
							<TableCell>{row.fuel_source}</TableCell>
							<TableCell>{row.vehicle_type}</TableCell>
							<TableCell>{row.activity_amount}</TableCell>
							<TableCell>{row.unit_of_fuel_amount}</TableCell>
							<TableCell className='table-icons'>
								<BtnImg
									src={viewIcon}
									style={{ cursor: 'pointer' }}
									data-testid="viewIcoMc"
									onClick={() => {
										this.setState({ isViewForm: true, isEditCfMobileCombustionForm: true, editDataCfMobileCombustionForm: row, editCfMobileCombustionDataIndex: { ind: index, key: "s1_mobileCombustion" } });
										this.CfHandleFormModel(this.state.cfNavActivate);
									}}
								/>
								{
									!this.state.isReadable &&
									<>
										<BtnImg
											src={editImg}
											style={{ cursor: 'pointer' }}
											data-testid="editIcoMc"
											onClick={() => {
												this.setState({ isEditCfMobileCombustionForm: true, editDataCfMobileCombustionForm: row, editCfMobileCombustionDataIndex: { ind: index, key: "s1_mobileCombustion" } });
												this.CfHandleFormModel(this.state.cfNavActivate);
											}}
										/>
										<BtnImg
											src={deleteImg}
											style={{ cursor: 'pointer' }}
											data-testid="deleteIcoMc"
											onClick={() => { this.setState({ isDeleteModelOpen: true, deleteModelIndex: index }) }}
										/>
									</>
								}
							</TableCell>
						</TableRow>
					)) : <TableCell style={{ textAlign: 'center' }} colSpan={18}>No Data Found</TableCell>
			case "s1_frigerants":
				return this.state.cfRefrigerentsTableData?.length > 0 ?
					this.state.cfRefrigerentsTableData?.map((row: any, index: number) => (
						<TableRow key={index + 1}>
							<TableCell>{row.year}</TableCell>
							<TableCell>{row.facility_id}</TableCell>
							<TableCell>{row.location}</TableCell>
							<TableCell>{row.type_of_air_conditioning_and_refrigeration_equipment}</TableCell>
							<TableCell>{row.refrigerant_used}</TableCell>
							<TableCell>{row.refrigerant_inventory_beginning_year}</TableCell>
							<TableCell>{row.refrigerant_inventory_end_year}</TableCell>
							<TableCell>{row.refrigerant_purchased_from_producers}</TableCell>
							<TableCell>{row.refrigerant_returned_by_equipment_users}</TableCell>
							<TableCell>{row.refrigerant_off_site_recycling_purchases}</TableCell>
							<TableCell>{row.refrigerant_charged_into_equipment}</TableCell>
							<TableCell>{row.refrigerant_delivered_to_equipment_users_containers}</TableCell>
							<TableCell>{row.refrigerant_returned_to_producers}</TableCell>
							<TableCell>{row.refrigerant_off_site_recycling_sales}</TableCell>
							<TableCell>{row.refrigerant_sent_off_site_destruction}</TableCell>
							<TableCell>{row.capacity_of_partially_charged_equipment}</TableCell>
							<TableCell>{row.pressure_of_partial_charge}</TableCell>
							<TableCell>{row.pressure_of_full_charge}</TableCell>
							<TableCell className='table-icons'>
								<BtnImg
									src={viewIcon}
									style={{ cursor: 'pointer' }}
									data-testid="viewIcoRf"
									onClick={() => {
										this.setState({ isViewForm: true, isEditCfRefrigerentsForm: true, editDataCfRefrigerentsForm: row, editCfRefrigerentsDataIndex: { ind: index, key: "s1_refrigerants" } });
										this.CfHandleFormModel(this.state.cfNavActivate);
									}}
								/>
								{
									!this.state.isReadable &&
									<>
										<BtnImg
											src={editImg}
											style={{ cursor: 'pointer' }}
											data-testid="editIcoRf"
											onClick={() => {
												this.setState({ isEditCfRefrigerentsForm: true, editDataCfRefrigerentsForm: row, editCfRefrigerentsDataIndex: { ind: index, key: "s1_refrigerants" } });
												this.CfHandleFormModel(this.state.cfNavActivate);
											}}
										/>
										<BtnImg
											src={deleteImg}
											style={{ cursor: 'pointer' }}
											data-testid="deleteIcoRf"
											onClick={() => { this.setState({ isDeleteModelOpen: true, deleteModelIndex: index }) }}
										/>
									</>
								}
							</TableCell>
						</TableRow>
					)) : <TableCell style={{ textAlign: 'center' }} colSpan={18}>No Data Found</TableCell>
			case "s2_purchasedElectricity":
				return this.state.cfPurchasedElectricityTableData?.length > 0 ?
					this.state.cfPurchasedElectricityTableData?.map((row: any, index: number) => (
						<TableRow key={index + 1}>
							<TableCell>{row.year}</TableCell>
							<TableCell>{row.facility_id}</TableCell>
							<TableCell>{row.location}</TableCell>
							<TableCell>{row.amount_of_electricity_consumption}</TableCell>
							<TableCell>{row.units}</TableCell>
							<TableCell>{row.calculation_approach}</TableCell>
							<TableCell>{row.type_of_emission_factor}</TableCell>
							<TableCell>{row.custom_emission_factor}</TableCell>
							<TableCell className='table-icons'>
								<BtnImg
									src={viewIcon}
									style={{ cursor: 'pointer' }}
									data-testid="viewIcoPe"
									onClick={() => {
										this.setState({ isViewForm: true, isEditCfPurchasedElectricityForm: true, editDataCfPurchasedElectricityForm: row, editCfPurchasedElectricityDataIndex: { ind: index, key: "s2_purchasedElectricity" } });
										this.CfHandleFormModel(this.state.cfNavActivate);
									}}
								/>
								{
									!this.state.isReadable &&
									<>
										<BtnImg
											src={editImg}
											style={{ cursor: 'pointer' }}
											data-testid="editIcoPe"
											onClick={() => {
												this.setState({ isEditCfPurchasedElectricityForm: true, editDataCfPurchasedElectricityForm: row, editCfPurchasedElectricityDataIndex: { ind: index, key: "s2_purchasedElectricity" } });
												this.CfHandleFormModel(this.state.cfNavActivate);
											}}
										/>
										<BtnImg
											src={deleteImg}
											style={{ cursor: 'pointer' }}
											data-testid="deleteIcoPe"
											onClick={() => { this.setState({ isDeleteModelOpen: true, deleteModelIndex: index }) }}
										/>
									</>
								}
							</TableCell>
						</TableRow>
					)) : <TableCell style={{ textAlign: 'center' }} colSpan={18}>No Data Found</TableCell>
			case "s3_transportation":
				return this.renderTransportationTable();
			default:
				return <TableCell style={{ textAlign: 'center' }} colSpan={18}>No Data Found</TableCell>
		}
	}

	renderTransportationTable = () => {
		return this.state.cfTransportationTableData?.length > 0 ?
			this.state.cfTransportationTableData?.map((row: any, index: number) =>
				<TableRow key={index + 1}>
					<TableCell>{row.year}</TableCell>
					<TableCell>{row.description}</TableCell>
					<TableCell>{row.category}</TableCell>
					<TableCell>{row.location}</TableCell>
					<TableCell>{row.emission_factor_dataset}</TableCell>
					<TableCell>{row.mode_of_transport}</TableCell>
					<TableCell>{row.activity_type}</TableCell>
					<TableCell>{row.vehicle_type}</TableCell>
					<TableCell>{row.amount_of_activity_type}</TableCell>
					<TableCell>{row.emission_calculation_methodology}</TableCell>
					<TableCell>{row.number_of_units}</TableCell>
					<TableCell>{row.units_of_measurement}</TableCell>
					<TableCell>{row.co2_emissions}</TableCell>
					<TableCell>{row.percentage_emissions}</TableCell>
					<TableCell>{row.co2_tonnes}</TableCell>
					<TableCell>{row.ch4_tonnes}</TableCell>
					<TableCell>{row.n2o_tonnes}</TableCell>
					<TableCell>{row.co2e_tonnes}</TableCell>
					<TableCell>{row.biofuel_co2_tonnes}</TableCell>
					<TableCell>{row.ef_kg}</TableCell>
					<TableCell>{row.total_emission}</TableCell>
					<TableCell className='table-icons'>
						<BtnImg
							src={viewIcon}
							style={{ cursor: 'pointer' }}
							data-testid="viewIcoTs"
							onClick={() => {
								this.setState({ isViewForm: true, isEditCfTransportationForm: true, editDataCfTransportationForm: row, editCfTransportationDataIndex: { ind: index, key: "s3_transportation" } });
								this.CfHandleFormModel(this.state.cfNavActivate);
							}}
						/>
						{
							!this.state.isReadable &&
							<>
								<BtnImg
									src={editImg}
									style={{ cursor: 'pointer' }}
									data-testid="editIcoTs"
									onClick={() => {
										this.setState({ isEditCfTransportationForm: true, editDataCfTransportationForm: row, editCfTransportationDataIndex: { ind: index, key: "s3_transportation" } });
										this.CfHandleFormModel(this.state.cfNavActivate);
									}}
								/>
								<BtnImg
									src={deleteImg}
									style={{ cursor: 'pointer' }}
									data-testid="deleteIcoTs"
									onClick={() => { this.setState({ isDeleteModelOpen: true, deleteModelIndex: index }) }}
								/>
							</>
						}
					</TableCell>
				</TableRow>
			)
			: <TableCell style={{ textAlign: 'center' }} colSpan={18}>No Data Found</TableCell>
	}

	userModal = (modalName: string) => {
		return (
			<Modal
				open={modalName === 'delete' ? this.state.isDeleteModelOpen : this.state.isDraftModelOpen}
				onClose={() => {
					modalName === 'delete' ?
						this.setState({ isDeleteModelOpen: !this.state.isDeleteModelOpen }) :
						this.setState({ isDraftModelOpen: !this.state.isDraftModelOpen })
				}}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={modalName === 'delete' ? this.state.isDeleteModelOpen : this.state.isDraftModelOpen}>
					<UserModelWrapper>
						<UserModelSecondWrapper>
							<CloseWrapper data-test-id='handleDeleteModal' onClick={() => {
								modalName === 'delete' ?
									this.setState({ isDeleteModelOpen: !this.state.isDeleteModelOpen }) :
									this.setState({ isDraftModelOpen: !this.state.isDraftModelOpen })
							}}>
								<CloseCircleIcon />
							</CloseWrapper>
							<Typography variant="h6" component="h2">
								<img src={warningIcon} alt='waning' style={{ width: "50px", marginBottom: "24px" }} />
							</Typography>
							<Typography >
								{modalName === 'delete' ? 'Are you sure, You want to Delete the data ?' : 'Are you sure, You want to Save the data as Draft?'}
							</Typography>
							<UserModalButtonWrapper>
								<YesButton
									data-testid='modelYesbtn'
									onClick={() => {
										if (modalName === 'delete') {
											this.handleDeleteData(this.state.deleteModelIndex);
											this.setState({ isDeleteModelOpen: !this.state.isDeleteModelOpen });
										}
										else {
											this.handleDraftData(this.state.cfNavActivate);
											this.setState({ isDraftModelOpen: !this.state.isDraftModelOpen });
										}
									}}
								>Yes</YesButton>
								<NoButton data-testid='modelNobtn' onClick={() => {
									modalName === 'delete' ?
										this.setState({ isDeleteModelOpen: !this.state.isDeleteModelOpen }) :
										this.setState({ isDraftModelOpen: !this.state.isDraftModelOpen })
								}}>No</NoButton>
							</UserModalButtonWrapper>
						</UserModelSecondWrapper>
					</UserModelWrapper>
				</Fade>
			</Modal>
		)
	}

	saveModal = () => {
		return (
			<Modal
				open={this.state.isSaveModelOpen}
				onClose={() => {
					this.setState({ isSaveModelOpen: !this.state.isSaveModelOpen })
				}}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={this.state.isSaveModelOpen}>
					<UserModelWrapper>
						<UserModelSecondWrapper>
							<CloseWrapper data-test-id='handleDeleteModal' onClick={() => {
								this.setState({ isSaveModelOpen: !this.state.isSaveModelOpen })
							}}>
								<CloseCircleIcon />
							</CloseWrapper>
							<Typography variant="h6" component="h2">
								<img src={warningIcon} alt='waning' style={{ width: "50px", marginBottom: "24px" }} />
							</Typography>
							<Typography >
								Are you sure, You want to Submit the data?
							</Typography>
							<UserModalButtonWrapper>
								<YesButton
									data-testid='modelYesbtn'
									onClick={() => {
										this.handleSubmitData(this.state.cfNavActivate);
										this.setState({ isSaveModelOpen: !this.state.isSaveModelOpen });
									}}
								>Yes</YesButton>
								<NoButton data-testid='modelNobtn' onClick={() => {
									this.setState({ isSaveModelOpen: !this.state.isSaveModelOpen })
								}}>No</NoButton>
							</UserModalButtonWrapper>
						</UserModelSecondWrapper>
					</UserModelWrapper>
				</Fade>
			</Modal>
		)
	}

	submitDataLoader = () => {
		return (
			<Modal
				open={this.state.submitDataLoader}
				onClose={() => {
					this.setState({ submitDataLoader: !this.state.submitDataLoader })
				}}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={this.state.submitDataLoader}>
					<UserModelWrapper>
						<SaveModelSecondWrapper>
							<Typography variant="h6" component="h2">
								<img src={SaveLoader} alt='waning' style={{ width: "80px", marginBottom: "30px", marginTop: "50px" }} />
							</Typography>
							<Typography >
								Please wait <br /> Your Report is getting Generated
							</Typography>
						</SaveModelSecondWrapper>
					</UserModelWrapper>
				</Fade>
			</Modal>
		)
	}

	navButton=(data:any,index:number) => {
		return (
			<CfNavBtn
				data-testid='cfNavTab'
				key={index + 1}
				onClick={() => this.CfNavActivation(data.key)}
				style={this.state.cfNavActivate === data.key ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}
			>{data.name}</CfNavBtn>
		)
	}
	addNewButton = () => {
		return (
			!this.state.isReadable &&
			<RoundedButton
				data-testid="cfAddNewBtn"
				onClick={() => this.CfHandleFormModel(this.state.cfNavActivate)}
				variant="contained"
				disableElevation
				disabled={false}
				startIcon={<img src={iconAdd} width={30} style={{ marginLeft: '-5px' }} />}>
				Add New
			</RoundedButton>
		)
	}

	mobileCombustionUnitOfFuelAmount = (activityType: string) => {
		let { mobileCombustionDropdownData } = this.state
		let fuelAmountList = activityType == "Distance Activity" ? mobileCombustionDropdownData.unit_of_fuel_amount_distance : mobileCombustionDropdownData.unit_of_fuel_amount;


		return fuelAmountList.sort((a: string, b: string) => a.localeCompare(b, 'en', { sensitivity: 'base' })).map((data: string) => <MenuItem value={data}>{data}</MenuItem>)
	}
	
	// Customizable Area End

	render() {
		// Customizable Area Start
		const { navigation } = this.props
		// Customizable Area End
		return (
			// Customizable Area Start
			<DashboardLayout isDashboard={true} isUserSurvey={false} navigation={navigation} >
				<CecfFormWrapper>
					<CecfFormHeadingWrapper>
						<CecfFormPageTitle onClick={() => this.getNavigationMessage(this.path,this.props)} data-testid='navigateBack'>
							ESG Journey
							<ArrowBackIosIcon />
						</CecfFormPageTitle>
						<CecfFormPageTitle onClick={() => this.getNavigationMessage('CarbonCalculatorCard',this.props)} data-testid='navigateBack2'>
							Carbon Emission Calculator - GHG Tool
							<ArrowBackIosIcon />
						</CecfFormPageTitle>
						<CfHead>FORM</CfHead>
					</CecfFormHeadingWrapper>
					<CecfFormCardWrapper>
						<RoundedButtonBase>
							{
								this.state.typesOfScope.map((data, index) => this.navButton(data, index))
							}
						</RoundedButtonBase>

						{
							!this.state.pdfUrl &&
							<AddNewBtnWrapper>
								{this.addNewButton()}
							</AddNewBtnWrapper>
						}

						{this.state.cfStationaryCombustionForm && this.CfStationaryCombustionForm(this.CfHandleFormModel, this.state.cfStationaryCombustionForm)}
						{this.state.cfMobileCombustionForm && this.CfMobileCombustionForm(this.CfHandleFormModel, this.state.cfMobileCombustionForm)}
						{this.state.cfPurchasedElectricityForm && this.cfPurchasedElectricityForm(this.CfHandleFormModel, this.state.cfPurchasedElectricityForm)}
						{this.state.cfTransportationForm && this.cfTransportationForm(this.CfHandleFormModel, this.state.cfTransportationForm)}
						{this.state.cfRefrigerentsForm && this.cfRefrigerentsForm(this.CfHandleFormModel, this.state.cfRefrigerentsForm)}

						{
							!this.state.pdfUrl &&
							<><MainTableBoxWrapper>
								<TableWrapper>
									<TableContainer component={Paper}>
										{this.state.loading ? <LoaderContainer>Loading...</LoaderContainer> :
											<Table>
												<TableHead>
													<TableRow>
														{this.renderHeaders(this.state.tableHeader)}
													</TableRow>
													<TableRow>
														{this.renderSubHeaders(this.state.tableHeader)}
													</TableRow>
												</TableHead>
												<TableBody>
													{this.renderTableDataRow()}
												</TableBody>
											</Table>
										}
									</TableContainer>
								</TableWrapper>
								<Pagin>
									<Pagination data-testid="tablePagination" disabled={this.state.total_pages <= 1 || !this.state.total_pages} defaultPage={1} color="primary" variant="outlined" count={this.state.total_pages} page={this.state.current_page} onChange={this.handlePageChange} />
								</Pagin>
								<OverlayLoader loading={this.state.loading} />
							</MainTableBoxWrapper>
							<FooterBtnWrapper>
								{
									!this.state.isReadable &&
									<>
										<RoundedButton
											variant="contained"
											disableElevation
											disabled={false}
											data-testid="saveAsDraftButton"
											onClick={() => { this.setState({ isDraftModelOpen: true }) }}>
											SAVE AS DRAFT
										</RoundedButton>
										<RoundedButton
											variant="contained"
											disableElevation
											data-testid="submitButton"
											disabled={false}
											onClick={() => { this.setState({ isSaveModelOpen: true }) }}>
											SUBMIT
										</RoundedButton>
									</>
								}
							</FooterBtnWrapper>
							</>
						}
						
						{this.state.isDeleteModelOpen && this.userModal('delete')}
						{this.state.isDraftModelOpen && this.userModal('draft')}
						{this.state.isSaveModelOpen && this.saveModal()}
						{this.state.submitDataLoader && this.submitDataLoader()}

						{this.state.pdfUrl && <PDFViewer url={this.state.pdfUrl} />}
					</CecfFormCardWrapper>
				</CecfFormWrapper>
			</DashboardLayout>
			// Customizable Area End
		);
	}
}

// Customizable Area Start

const RoundedButton = styled(Button)({
	backgroundColor: '#009c05',
	color: 'white',
	borderRadius: '20px',
	'&:hover': {
		backgroundColor: '#009c05',
	}
})

const CfNavBtn = styled(Button)({
	border: "2px solid transparant",
	color: "#009B07",
	fontWeight: 600,
	backgroundColor: "#FFFFFF",
	textTransform: "uppercase",
	borderRadius: "38px",
	height: "100%",
	padding: "0 65px 0 65px",
	'@media screen and (max-width: 1110px) and (min-width: 769px)': {
		padding: "0 35px 0 35px",
	},
	'@media screen and (max-width: 624px)': {
		padding: "0 35px 0 35px",
	},
	'@media screen and (max-width: 500px)': {
		padding: "0 25px 0 25px",
	}
});

const RoundedButtonBase = styled('div')((props: any) => ({
	height: "47px",
	fontWeight: 700,
	display: 'flex',
	textAlign: 'center',
	justifyContent: 'center',
	backgroundColor: "#FFFFFF",
	flex: "wrap",
	color: "#009E00",
	borderRadius: '38px',
	border: " 2px solid #009B07"
}))

const CfHead = styled("div")({
	margin: "40px 0px 15px 0px",
	fontSize: "1.4rem",
	fontWeight: "bolder",
	textTransform: "uppercase",
	"@media screen and (max-width: 768px)": {
		margin: "20px 0px 10px 12px",
	},
});

const AddNewBtnWrapper = styled("div")({
	textAlign: 'end',
	width: '100%'
});

const MainTableBoxWrapper = styled("div")({
	marginTop: '20px',
	alignItems: 'center',
	width: '100%'
});

const CecfFormPageTitle = styled("span")({
	margin: "40px 0px 15px 0px",
	cursor: "pointer",
	display: 'flex',
	alignItems: "center",
	gap: "15px",
});

const CecfFormHeadingWrapper = styled("div")({
	display: "flex",
	alignItems: "center"
});

const CecfFormWrapper = styled("div")({
	display: 'flex',
	flexWrap: 'wrap',
	flexDirection: 'column',
	padding: '0px 40px',
	gap: '30px'
});

const FooterBtnWrapper = styled("div")({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	gap: '20px'
});

const CecfFormCardWrapper = styled("div")({
	display: 'flex',
	gap: '25px',
	flexWrap: 'wrap',
	width: "100%",
});

const FormSubFieldTitle = styled("span")({
	color: '#000',
	fontFamily: 'Roboto',
	fontSize: '1rem',
	fontWeight: 700,
	display: 'block',
	marginBottom: '-10px',
	marginTop: '15px'
});

// For all models

const TableWrapper = styled('div')({
	overflow: 'auto',
	width: '100%',
	'& .MuiTableCell-root': {
		borderRight: '1px solid rgba(224, 224, 224, 1)',
		textAlign: 'center',
	},
	'& .table-icons img': {
		margin: '0px 5px',
	}
})

const BtnSave = styled(Box)({
	textAlign: "right",
	"& button": {
		color: "#fff",
		minWidth: "100px",
	},
});

const MainModelWrapper = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	background: '#fff',
	borderRadius: 8,
	width: '924px',
	position: "relative",
	margin: '10px',
})

const ModelHeadWrapper = styled(Box)({
	paddingLeft: 30,
	paddingRight: 30,
	paddingTop: 20,
	width: '100%'
})

const ModelBody = styled(Grid)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	padding: '15px 8px 15px 32px',
})

export const ModalWrapper = styled(Modal)({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	overflow: 'scroll'
})

export const RightFormWrapper = styled(Form)({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: '25px',
	padding: '10px 25px 10px 0',
	overflowX: 'hidden',
	maxHeight: '490px',
	boxSizing: 'border-box',
})

const LoaderContainer = styled('div')({
	margin: "auto",
	width: "100%",
	padding: "19px",
	display: "flex",
	justifyContent: "center"
})

const Pagin = styled('div')({
	alignItems: 'center',
	display: 'flex',
	padding: '10px',
	justifyContent: 'center',
	ul: {
		"& > .MuiPaginationItem-root": {
			color: "green"
		}
	},
	'& > MuiPaginationItem-icon': {
		color: 'green',
	},
})
const CloseIconButton = styled(IconButton)({
	color: '#fcfcfc',
	background: '#676767',
	height: '32px',
	width: '32px',
	'&:hover': {
		opacity: 0.9,
		background: '#999'
	}
})

const ModalTitle = styled(Grid)({
	alignItem: 'center',
	display: 'flex',
	width: '100%',
	justifyContent: 'space-between',
	flexDirection: 'row',
	padding: '10px 0 0 0',
	borderBottom: '0.2px solid #e6e6e6',
})

const UserModelWrapper = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	minHeight: '100vh'
})

const UserModelSecondWrapper = styled(Box)({
	backgroundColor: '#fff',
	position: 'relative',
	padding: '30px 30px',
	borderRadius: '8px',
	textAlign: 'center',
	maxWidth: '500px',
	margin: '0 auto'
})

const SaveModelSecondWrapper = styled(Box)({
	backgroundColor: '#fff',
	position: 'relative',
	padding: '50px 40px',
	borderRadius: '8px',
	textAlign: 'center',
	width: '250px',
	height: '250px',
	margin: '0 auto'
})

const CloseWrapper = styled(Box)({
	position: 'absolute',
	right: '15px',
	top: '15px',
	color: '#fff',
	width: '20px',
	height: '20px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '8px',
	cursor: 'pointer'
});

const UserModalButtonWrapper = styled(Box)({
	display: 'flex',
	marginTop: '20px',
	alignItems: 'center',
	justifyContent: 'center',
	gap: '20px'
})

const YesButton = styled('button')({
	backgroundColor: 'green',
	color: '#fff',
	borderRadius: '5px',
	padding: '5px 20px',
	border: 'none',
	cursor: 'pointer'
})

const NoButton = styled('button')({
	backgroundColor: 'red',
	color: '#fff',
	borderRadius: '5px',
	padding: '5px 20px',
	border: 'none',
	cursor: 'pointer'
})

const BtnImg = styled('img')({ width: '25px' })

// Customizable Area End