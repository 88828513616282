import React, { Component } from 'react'
// Customizable Area Start
import DashboardLayout from '../../../components/src/DashboardLayout.web';

import {
  Card,
  Divider,
  Button,
  Typography,
  TextField,
  InputAdornment,
  Hidden,
} from '@material-ui/core'
// import NestedList, { FilterMenuMobile } from './Filter.web';
//@ts-ignore
import classes from './RequestManagement.module.css';
import { styled } from '@material-ui/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import SearchFilter from '../../../components/src/Search.web';
import { OyoAvatar } from '../../ActivityFeed/src/assets';
import RequestManagementController, {
  Props,
  configJSON
} from "./RequestManagementController";
import { ArrowDownIcon } from '../../filteritems/src/assets';
import { iconAdd } from '../../JobListing2/src/assets';
import SeeMore from '../../../components/src/SeeMore.web';
import { format } from 'timeago.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingSpinner } from '../../../components/src/LoadingSpinner.web';
import { FilterMenu, FilterMenuMobile } from './Filter.web';
import { CloseCircleIcon } from '../../../components/src/Icons.web';
import OverlayLoader from '../../../components/src/Loader.web'


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// Customizable Area End
export class RequestManagement extends RequestManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { navigation } = this.props;
    const { allRequest, pagination, loadingRequests } = this.state;
    console.log({ allRequest, filterText: this.filterText() })
    // Customizable Area End
    return (
      // Customizable Area Start
      // <h1>reques</h1>
      <DashboardLayout navigation={navigation}>
        <PageWrapper>
          <CenterRow>
            <Typography variant='h5'>Requests</Typography>
            <FlexSpaceBetween style={{ gap: 6 }}>
              <div style={{ flex: 1 }}>
                <SearchFilter
                  onChange={this.onFilterQueryChange}
                />
              </div>
              <div style={{ flex: 1 }}>
                <TextFieldRounded
                  value={this.filterText()}
                  onClick={this.handleFilterMenuOpen}
                  // @ts-ignore 
                  InputProps={{
                    ref: this.filterRef,
                    endAdornment: (<InputAdornment position="start">
                      {this.filterText() && <CloseCircleIcon
                        onClick={this.clearFilters}
                        style={{ margin: '0 10px', width: '24px', cursor: 'pointer' }}
                      />}
                      <img src={ArrowDownIcon} width="16" />
                    </InputAdornment>)
                  }} variant="outlined" placeholder={configJSON.labelFilterBy} />
                <Hidden mdDown>
                  <FilterMenu
                    filterByMe={this.state.filterCreatedByMe}
                    filterStatus={this.state.filterStatus}
                    seletedFilterStatus={this.onSelecteFilterStatus}
                    toggleFilterByMe={this.toggleFilterByMe}
                    handleDateInputFocus={this.handleDateInputFocus}
                    handleDateInputBlur={this.handleDateInputBlur}
                    keepOpen={this.state.selectingDate}
                    menuPosition={this.state.filterMenuPosition}
                    onClear={this.clearFilters}
                    onClose={this.handleFilterMenuClose}
                    requestTypeControls={{
                      requestTypes: this.state.filterRequestTypes,
                      handleSelect: this.handleRequestTypeSelect,
                      selectedRequestType: this.state.filterRequestType,
                    }}
                    dateControls={{
                      handleSubmit: this.handleDateFilterSubmit,
                      handleCancel: this.handleDateFilterCancel,
                      handleFromDateChange: this.handleFromDateChange,
                      handleToDateChange: this.handleToDateChange,
                      filterFromDate: this.state.filterFromDate,
                      filterToDate: this.state.filterToDate,
                      minDate: this.state.filterFromDate,
                      maxDate: this.state.filterToDate
                    }}
                  />
                </Hidden>
                <FilterMenuMobile
                  menuPosition={this.state.filterMenuPosition}
                  onClose={this.handleFilterMenuClose}
                  filterByMe={this.state.filterCreatedByMe}
                  toggleFilterByMe={this.toggleFilterByMe}
                  seletedFilterStatus={this.onSelecteFilterStatus}
                  filterStatus={this.state.filterStatus}
                  requestTypeControls={{
                    requestTypes: this.state.requestTypes,
                    handleSelect: this.handleRequestTypeSelect,
                    selectedCategories: this.state.filterRequestType,
                  }}
                  dateControls={{
                    handleSubmit: this.handleDateFilterSubmit,
                    handleCancel: this.handleDateFilterCancel,
                    handleFromDateChange: this.handleFromDateChange,
                    handleToDateChange: this.handleToDateChange,
                    filterFromDate: this.state.filterFromDate,
                    filterToDate: this.state.filterToDate,
                    minDate: this.state.filterFromDate,
                    maxDate: this.state.filterToDate
                  }}
                  disabled={ this.state.filterFromDate && this.state.filterToDate?false:true }
                />
              </div>
              <div >
                <RoundedButtonBase
                  onClick={() => navigation.navigate('AddRequest')}
                  variant="contained"
                  disableElevation
                  startIcon={<img src={iconAdd} width={30} style={{ marginLeft: '-5px' }} />}>
                  Add Request
                  {/* {buttonLabels[selectedTab]} */}
                </RoundedButtonBase>
              </div>

            </FlexSpaceBetween>
            {(this.filterText() || this.state.filterQuery) && allRequest?.length === 0 && !loadingRequests && (
                <Typography variant="h5" style={{ marginTop: '60px' }} align="center">
                  No Match Found
                </Typography>)
            }
            <InfiniteScroll
              dataLength={allRequest.length} //This is important field to render the next data
              next={this.loadNextPage}
              hasMore={(!!pagination?.next_page) && !loadingRequests}
              // Show only when this.state.loadingPosts component is not rendered
              loader={!(!loadingRequests && allRequest?.length === 0) && <LoadingSpinner />}
            >
              {allRequest?.map((request: any, i) => {
                const { accounts: { data = [] } } = request;
                {console.log("Account123456",request)}
                return (
                  <RoundedCard key={i} onClick={() => { this.openRequestDetailPopup(request) }}>
                    <FlexSpaceBetweenN>
                      <Typography variant='h6' className={`${classes.title}`}>{(request?.title || '').length > 51 ? (request?.title || '').substr(0, 51) + ' ...' : request?.title}</Typography>
                      <StatusChip request={request} />
                      
                    </FlexSpaceBetweenN>
                    <TimeStamp>
                      <Typography variant='subtitle1' style={{ color: '#999' }}>{format(request.created_at)}</Typography>
                      {/* <Typography variant='subtitle1' >Type : </Typography> */}
                      <Typography variant='subtitle1' ><span>Type : </span>
                        <span className={`${classes.labelColor}`}>{request?.request_type_id?.request_type?.title}</span>
                      </Typography>
                    </TimeStamp>
                    <Typography variant='body2' style={{ color: '#999', pointerEvents: 'none' }}>
                      {(request?.description || '').length > 150 ? (request?.description || '').substr(0, 150) + ' ...' : request?.description}
                    </Typography>

                    <Divider style={{ margin: '10px 0' }} />

                    <FlexSpaceBetween>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='subtitle1' className={`${classes.labelColor}`}>Request Owner :</Typography>
                        <BtnImg src={request?.sender_id?.profile_pic || OyoAvatar} />
                        <Typography variant='subtitle1' className={`${classes.textColor} ml10`}>{`${request?.sender_id?.first_name || ''} ${request?.sender_id?.last_name || ''}`} </Typography>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='subtitle1' className={`${classes.labelColor}`}>Assign to :</Typography>
                        <BtnImg src={(data[0] || {})?.attributes?.profile_pic || OyoAvatar} />
                        <Typography variant='subtitle1' className={`${classes.textColor} ml10`}>{`${(data[0] || {})?.attributes?.first_name || ''} ${(data[0] || {})?.attributes?.last_name || ''}`} {(data || []).length > 1 && (<span className={`${classes.labelColor} font13 ml5`}>+{(data || []).length - 1} More</span>)}</Typography>
                      </div>
                    </FlexSpaceBetween>
                  </RoundedCard>
                )
              })}
            </InfiniteScroll>

          </CenterRow>
        </PageWrapper>
        {/* <RequestResponse/> */}

        <OverlayLoader loading={this.state.loadingRequests} />
      </DashboardLayout>
      // Customizable Area End
    )
  }
}

// Customizable Area Start
export default RequestManagement


const StatusChip = ({ request }: any) => {
  console.log("Account123",request, request?.status, request?.is_completed,request?.status || request?.is_completed )
  const className = `${classes.status} ${classes[request?.status || request?.is_completed ? 'Completed' : 'Pending']}`
  const label = (request?.status || request?.is_completed) ? 'Completed' : 'Pending'
  return <Typography className={className}>
    {label}
  </Typography>
}

//*********** styles ***************//

const TextFieldRounded = styled(TextField)({
  width: '100%',
  background: '#FFFFFF',
  borderRadius: '15px',
  '& > .MuiOutlinedInput-root': {
    borderRadius: '15px',
  },
  '& > div > input': {
    paddingTop: '14px',
    paddingBottom: '14px'
  }
})

const PageWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  minHeight: '100vh',
  width: '100%',
  '@media screen and (max-width:600px)':{
    padding:'0 15px',
    width:'94.4%',
  },
  '@media screen and (max-width:400px)':{
    padding:'0 10px',
    width:'94.4%',
  },
  '@media screen and (min-width:601px) and (max-width:768px)':{
    padding:'0 15px',
    width:'96%',
  }
})


const BtnImg = styled('img')({
  width: 32,
  height: 32,
  borderRadius: '100%',
  marginLeft: '10px'
})

const RoundedButtonBase = styled(Button)({
  //width: '100%',
  //display: 'flex',
  //justifyContent: 'space-between',
  backgroundColor: '#009c05',
  color: 'white',
  borderRadius: '20px',
  '&:hover': {
    backgroundColor: '#009c05',
  }
})

const CenterRow = styled('div')(({ theme }) => ({
  // maxWidth: '60vw',
  width: '100%',
  marginTop: '60px',
  '@media screen and (max-width:768px)':{
    marginTop:'20px'
  },
}))

const FlexSpaceBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 0',
  paddingBottom: '0',
  width: '100%',
  '@media screen and (max-width:600px)':{
    flexDirection:'column',
    alignItems: 'normal',
  },
  '@media screen and (min-width:769px) and (max-width:1200px)':{
    flexDirection:'column',
    alignItems: 'normal',
  }
})

const FlexSpaceBetweenN = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 0',
  paddingBottom: '0',
  width: '100%',
})


const RoundedCard = styled(Card)({
  borderRadius: '15px',
  padding: '10px 20px',
  margin: '20px 0px'
})

const TimeStamp = styled('div')({
  display: 'flex',
  gap: '35px',
  margin: '0 0 10px 0',
  '@media screen and (max-width:450px)':{
    gap:'0px',
    flexDirection:'column'
  },
  '@media screen and (min-width:769px) and (max-width:1200px)':{
    gap:'0px',
    flexDirection:'column'
  }
})


// Customizable Area End