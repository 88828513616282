import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from 'yup'

import { Course } from './types'
import { sendAPIRequest } from '../../../components/src/utils'
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { sendSharePopupMessage } from '../../share/src/Messages';
import { sendAddRequestMessage } from '../../RequestManagement/src/Messages'
import { sendReportMessage } from '../../ActivityFeed/src/Messages'
import { toast } from 'react-toastify'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  modal: 'create' | 'enquire' | '';
  selectedCourse?: Course;
  courses: Course[];
  loading: boolean;
  filterQuery: string;
  loadingCourses: boolean;
  userRole?: any;
  courseID: string;
  startCourseDate: any,
  endCourseDate: any,
  filterSubCategories: any[];
  filterCategories: number[];
  pagination?: {
    next_page?: number;
  };
  threedotAnchorEl?: any;
  currentCourseId?: any; // ID of post currently being edited
  currentCourse?: any;
  deleteCourseId?: any;
  CourseDeleteCallId?: any;
  urlSearchParam: URLSearchParams;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfCourseListing5Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  token:string = ''
  courseCreateRequestId:string = ""
  courseListRequestId: string = ""
  apiCoursesFilterCallId: string = ""
  courseEnquireRequestIds: string[] = []
  apiFavCourseCallId: string[] = []
  apiPaginatePostsCallId: string = ""
  favCallIdToCourseId: { [key: string]: string } = {}
  userRoleCallId:string = "";
  generateCourseIDRequestId: string = ""
  allPreferences = [{id: 1, label: "E - Environmental"}, {id: 2, label: "S - Social"}, {id: 3, label: "G - Governance"}]
  phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CourseUpdatedMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token:'',
      modal: '',
      courses: [],
      loading: false,
      filterQuery: '',
      loadingCourses: false,
      userRole: "",
      startCourseDate: '',
      endCourseDate: '',
      filterSubCategories: [],
      filterCategories: [],
      courseID: '',
      urlSearchParam: new URLSearchParams()
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if ( apiRequestCallId === this.courseCreateRequestId ) {
          this.handleCreateCourse(responseJson)
      } else if ( apiRequestCallId === this.courseListRequestId ) {
        this.handleCourseListRequestId(responseJson)
      } else if ( this.courseEnquireRequestIds.indexOf(apiRequestCallId) !== -1 ) {
        this.hideModal()
        toast.success("Your enquiry has been send successfully.")
      }  else if (apiRequestCallId === this.apiCoursesFilterCallId) {
          this.handleCoursesFilterCallId(responseJson)
      }  else if (this.apiFavCourseCallId.includes(apiRequestCallId)) {
        const courseId = this.favCallIdToCourseId[apiRequestCallId]
        this.apiFavCourseCallId = this.apiFavCourseCallId.filter(x => x !== apiRequestCallId)
        delete this.favCallIdToCourseId[apiRequestCallId]
        if (responseJson.data) {
          const fav = { ...responseJson.data, ...responseJson.data?.attributes }
          const newCourses = this.state.courses.map(course => {
            if (Number(course.id) === Number(courseId))
              return { ...course, favourite_id: [...(course.favourite_id || []), fav] }
            return { ...course }
          })
          this.setState({ courses: newCourses })
        }
      } else if (apiRequestCallId === this.userRoleCallId) {
        // toast.success('Community created successfully')
        this.setState({ 
          userRole: responseJson.user_role || ''
        })
      } else if (apiRequestCallId === this.apiPaginatePostsCallId) {
        const courseData = responseJson.courses?.data || responseJson.post?.data
        let allCourses = courseData?.map((d: any) => ({ ...d.attributes, id: d.id }))
        const courses = [...this.state.courses, ...allCourses]
        this.setState({ courses, pagination: responseJson.meta.pagination })
      } else if (apiRequestCallId === this.generateCourseIDRequestId) {
        this.setState({ 
          courseID: responseJson.course_unique_id || '', modal: 'create'
        })
      } else if (apiRequestCallId === this.state.CourseDeleteCallId) {
        const pid = this.state.deleteCourseId
        this.closeDeleteModal()
        const courses = this.state.courses.filter((p: any) => p.id != pid)
        console.log({ pid, courses })
        this.setState({ courses })
        toast.success('Course Deleted Successfully')
      }

    }  if (message.id === getName(MessageEnum.CourseUpdatedMessage)) {
      const updatedCourse = message.getData(getName(MessageEnum.CourseUpdatedDataMessage))
      this.handleUpdatedCourse(updatedCourse)
    }
    // Customizable Area End

  }

  // Customizable Area Start
  handleCreateCourse = (responseJson:any) => {
    if(responseJson.data){
      this.setState({ courses: [{id:responseJson.data.id, ...responseJson.data.attributes}, ...this.state.courses] })
      this.hideModal()
      toast.success('Course Created Successfully')
    }
  }

  handleCourseListRequestId = (responseJson:any) => {
    if(responseJson.courses.data) {
      const courseData = responseJson?.courses?.data
      let courses = courseData?.map((d: any) => ({ ...d.attributes, id: d.id })) || []
      this.setState({ courses, pagination: responseJson?.meta?.pagination, loadingCourses: false })
    } else {
      this.setState({ courses: [], loadingCourses: false})
    }
  }

  handleCoursesFilterCallId = (responseJson:any) => {
    if(responseJson?.course?.data) {
      const courseData = responseJson?.course?.data
      let courses = courseData?.map((d: any) => ({ ...d.attributes, id: d.id })) || []
      this.setState({ courses, pagination: responseJson?.meta?.pagination, loadingCourses: false })
    } else {
      this.setState({ courses: [], loadingCourses: false})
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  async componentDidMount(){
    this.token = await getStorageData('authToken');
    this.handleUserRole()
    this.getAllCourses()
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.navigation.history.location.state?.comment) {
      this.handleUserRole()
      this.getAllCourses()
      this.props.navigation.history.replace({ state: {} })
    }
  }

  getAllCourses = async() => {
    this.setState({ loadingCourses: true })
    const token = await getStorageData('authToken')
    let baseUrl = configJSON.courseMyListEndpoint
    if (window.location.toString().indexOf('/MyMarketplace') > 0) {
      baseUrl = configJSON.courseListEndpoint
    }
    this.courseListRequestId = sendAPIRequest(
      baseUrl, {
        method: 'GET',
        headers: { 'token': token },
      }    
    )
  }

  createCourse = async() => {
    const token = await getStorageData('authToken')
    this.generateCourseIDRequestId = sendAPIRequest(
      configJSON.generateCourseIDEndpoint, {
        method: 'GET',
        headers: { 'token': token },
      }    
    )
  }

  handleUserRole = async() => {
    const token = await getStorageData('authToken')
    const url = configJSON.getUserRoleEndpoint
    this.userRoleCallId =  sendAPIRequest(url , { method: 'GET', headers: {token} })
  }
  createFormSchema = yup.object({
    course_id_1: yup.string().label('Course ID').required(),
    course_id_2: yup.string().label('Course ID').required(),
    course_name: yup.string().max(255, 'Course name must not be more than 255 character long').label('Course Name').required(),
    course_type: yup.number().label('Course Type').required('Course type should be selected'),
    preference_ids: yup.array().min(1, "Select atleast one Preference").label('Preferences'),
    category_ids: yup.array().min(1, "Select atleast one Category").label('Categories'),
    organization: yup.string().max(100, 'organization must not be more than 100 character long').label('Organization').required(),
    price_Usd: yup.number().typeError('Price must be a number').label('Price').required().positive(),
    price: yup.number().typeError('Price must be a number').label('Price').required().positive(),
    coin_discount: yup.number().label('Discount').required().min(0, "Minimum atleast 0").max(100, "Allowed maximum is 100"),
    // equivalent_coins: yup.number().label('Equivalent Coins').min(0, "Cannot be less than 0").required(),
    equivalent_coins: yup.number().when("coin_discount", {
      is: (coin_discount) => coin_discount >0,
      then: yup.number().label('Equivalent Coins').min(1,"Minimum value should be one").required('Coin should be there')}),
    description: yup.string().max(1000, 'Description must not be more than 1000 characters long').label('Description').required(),
    mode: yup.number().label('Mode').required(),
    start_date: yup.string().label('Start Date').required(),
    end_date: yup.string().label('End Date').required(),
    eligibility: yup.string().max(200, 'Eligibility must not be more than 200 character long').label('Eligibility').required(),
  })

  enquireFormSchema = yup.object({

    name: yup.string().required().min(2).max(50)
      .matches(/^[a-zA-Z\s]+$/, "Name can only contain alphabets")
      .matches(/^.*\S+.*$/, "Name should not be all spaces").label('Name'),
      email: yup.string().required().email().label('Email ID'),
    // @ts-ignore
    phone_number: yup.string().required().matches(this.phoneRegExp, 'Please provide valid phone number.').label('Phone Number'),
    location: yup.string().required().matches(/^[a-zA-Z0-9 ,]+$/, "Location cannot contain special characters").label('Location'),
    description: yup.string().max(1000).label("Description").required(),
  })

  hideModal = () => {
    this.setState({ modal: '', endCourseDate: '', startCourseDate: '' })
  }

  handleCreateFormSubmit = async(formData: any) => {
    const { course_id_1="", course_id_2="", /*category_ids=[], preference_ids=[], sub_category_ids=[],*/ ...data } = formData;
    const token = await getStorageData('authToken')
    
    const body = new FormData();
    (['preference_ids',
          'category_ids',
          'sub_category_ids',]).forEach((id: any) => {
          body.append(`[data][attributes][${id}]`, `[${String(data[id])}]`)
        }
    );
    delete data.preference_ids;
    delete data.category_ids;
    delete data.sub_category_ids;

    body.append('data[attributes][course_unique_id]', `${course_id_1}-${course_id_2}`);
    Object.keys(data).forEach(k => body.append(`data[attributes][${k}]`, data[k]));
    this.courseCreateRequestId = sendAPIRequest(configJSON.courseCreateEndpoint, {
      method: 'POST',
      headers: {'token': token},
      body
    })


  }

  handleEnquireClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const id = Number(e.currentTarget.dataset.id)
    this.setState({ selectedCourse: this.state.courses.find(c=>c.id == id), modal: 'enquire' })
  }

  handleEnquireFormSubmit = async(data: any) => {
    const token = await getStorageData('authToken')
    const body = new FormData()
    body.append('data[attributes][course_id]', String(this.state.selectedCourse?.id) || '')
    body.append('data[attributes][course_unique_id]', String(this.state.selectedCourse?.course_unique_id) || '')
    body.append('data[attributes][course_name]', String(this.state.selectedCourse?.course_name) || '')
    body.append('data[attributes][account_id]', String(this.state.selectedCourse?.account_id) || '')
    Object.keys(data).forEach(k => body.append(`data[attributes][${k}]`, data[k]))
    this.courseEnquireRequestIds.push(
      sendAPIRequest( configJSON.courseEnquireEndpoint, {
        method: 'POST',
        headers: {'token':token},
        body
      })
    )
  }

  onLikeChange = (courseId: any, delta: number) => {
    const courses = this.state.courses.map((p: any) => {
      if (p.id == courseId) {
        p.total_likes += delta;
        p.like_id = [];
      }
      return p
    })
    this.setState({ courses })
  }
  onLikeIdUpdate = (courseId: any, likeObj: any) => {
    const courses = this.state.courses.map((p: any) => {
      if (p.id == courseId) {
        p.like_id = [likeObj];
      }
      return p
    })
    this.setState({ courses })
  }

  handleCourse = async(course:any,courseId:any)=>{
    let endpoint = course.is_favourite ? configJSON.setCourseUnFavEndpoint : configJSON.setCourseFavEndpoint
      const method = course.is_favourite ? 'DELETE' : 'POST'
    if (!course.is_favourite) {
      const token = await getStorageData('authToken')
      // POST REQUEST
      const body = new FormData()
      body.append("data[favouriteable_type]", "BxBlockCfcourselisting5::Course")
      body.append("data[favouriteable_id]", courseId as string)
      const callId = sendAPIRequest(endpoint, { body, method, headers: { token: token } })
      this.apiFavCourseCallId.push(callId)
      this.favCallIdToCourseId[callId] = courseId
    } else {
      const token = await getStorageData('authToken')
      // DELETE REQUEST
      if (!course.favourite_id?.length) return;

      endpoint = endpoint.replace(':id', String(course.favourite_id[0].id))
      sendAPIRequest(endpoint, { method, headers: { token: token } })
    }
  }

  handleFavoriteClick = (courseId: any) => ()  => {
    if (!courseId) return;
    const course = this.state.courses.find((x: Course) => x.id == courseId)
    // if a fav request already exists, skip this one
    if (Array.from(Object.values(this.favCallIdToCourseId)).includes(courseId)) return;
  
    if (course) {
      let endpoint = course.is_favourite ? configJSON.setCourseUnFavEndpoint : configJSON.setCourseFavEndpoint
      const method = course.is_favourite ? 'DELETE' : 'POST'
      this.handleCourse(course,courseId)
     
      // optimistic update
      const courses = this.state.courses.map(p => {
        if (p.id == Number(courseId)) {
          p.is_favourite = !p.is_favourite
          p.favourite_id = []
        }
        return p
      })
      this.setState({ courses })
    }
  }

  handleShareCourse = (p: any) => () => {
    let data = {
      userId: p?.created_by?.id,
      postId: p?.id,
      link: `${window?.location?.origin}/user/${JSON.parse(p?.created_by?.id || '0')}`,
      shareableType: 'BxBlockCfcourselisting5::Course',
      shareableId: p?.id,
    }
    sendSharePopupMessage(data);
  }

  getBlankFilters = () => {
    return {
      filterFromDate: null,
      filterToDate: null,
      filterCategories: [],
      filterSubCategories: [],
      filterByMe: false,
      filterByFav: false,
    }
  }

  onFilterChange = async(filter: string) => {
    const token = await getStorageData('authToken')
    if(filter === this.state.filterQuery) return;
    this.setState({ filterQuery: filter })
    if(filter) {
      this.apiCoursesFilterCallId = sendAPIRequest(`${configJSON.courseFilterAPIEndpoint}?${filter}`, { method: 'GET', headers: { token } })
      this.setState({ loadingCourses: true, courses: [] })
    } else {
      this.getAllCourses()
    }
  }

  queryToString = (queryParams: URLSearchParams) => {
    const from_date = queryParams.get('from_date')
    const to_date = queryParams.get('to_date')

    if(from_date) {
      queryParams.delete('from_date')
      queryParams.append('from_date', (new Date(from_date)).toISOString().slice(0, 10))
    }
    if(to_date) {
      queryParams.delete('to_date')
      queryParams.append('to_date', (new Date(to_date)).toISOString().slice(0, 10))
    }
    return queryParams.toString()
  }

  onFilterQueryChange = (query: any) => {
    this.setState({ urlSearchParam: query })
    console.log("query", query);
    this.onFilterChange(this.queryToString(query))
  }

  loadNextPage = async() => {
    const query = this.state.urlSearchParam
    const isFiltersSet = query.toString().length > 0
    //query.append('per_page', '6')
    query.append('page', String(this.state.pagination?.next_page))

    let baseUrl = isFiltersSet ? `${configJSON.courseFilterAPIEndpoint}` : configJSON.courseMyListEndpoint
    if (window.location.toString().indexOf('/MyMarketplace') > 0) {
      baseUrl = configJSON.courseListEndpoint
    }
    this.apiPaginatePostsCallId = await this.sendGetRequest(baseUrl + "?" + query.toString())
    // this.setState({  })
  }

 

  sendGetRequest = async(endpoint: string, ) => {
    const token = await getStorageData('authToken')
    const headers = {
      "Content-Type": "application/json",
      "token": token,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    const callId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return callId;
  }

  goToCourse = (course_id: any) => () => {
    const url = `${this.props.navigation.history.location.pathname}/course/${course_id}`
    this.props.navigation.history.push(url)
  }

  handleThreeDotClose = () => this.setState({ threedotAnchorEl: null, currentCourseId: undefined })

  openThreeDot = (e: any) => this.setState({ threedotAnchorEl: e.currentTarget, currentCourseId: e.currentTarget.dataset.id, currentCourse: this.state.courses.find((x: any) => x.id == e.currentTarget.dataset.id) })

  handleCourseEdit = () => {
    console.log({ history: this.props.navigation.history })
    const url = `${this.props.navigation.history.location.pathname}/course-edit/${this.state.currentCourseId}`
    this.props.navigation.history.push(url)
    this.setState({ threedotAnchorEl: null })
  }

  handleCourseDelete = () => {
    this.setState({ threedotAnchorEl: null, deleteCourseId: this.state.currentCourseId })
  }
  handleCourseDeleteConfirm = async() => {
    const token = await getStorageData('authToken')
    const CourseDeleteCallId = sendAPIRequest(configJSON.courseDeleteEndpoint.replace(':id', this.state.deleteCourseId), {
      method: 'DELETE',
      headers: { token }
    })
    this.setState({ CourseDeleteCallId })
  }
  closeDeleteModal = () => this.setState({
    currentCourseId: undefined,
    deleteCourseId: undefined,
    CourseDeleteCallId: undefined,
    currentCourse: undefined,
  })
  handleCourseReport = () => {
    sendReportMessage(this.state.currentCourseId, 'BxBlockCfcourselisting5::Course')
    this.setState({ threedotAnchorEl: null })
  }

  handleRaiseRequest = (e: any) => {
    this.props.navigation.navigate('AddRequest')
    this.handleThreeDotClose()
    const requestOwner = this.context
    let assignedTo = this.state.currentCourse.created_by
    assignedTo = { ...assignedTo, first_name: assignedTo.name, last_name: '' }

    setTimeout(() => sendAddRequestMessage({
          disable_fields: ['created_by', 'accounts', 'request_type_id'],
          sender_id: requestOwner,
          created_by: requestOwner,
          accounts: {data: [{ id: String(assignedTo.id), attributes: assignedTo}]},
          "request_type_id": {
            "request_type": {
              "id": 5,
              "title": "course related",
            }
          }
        }), 500 // a slight delay to send after component is rendered
    )
  }

  handleUpdatedCourse = (updatedCourse: any) => {
    console.log('inerst uc2')
    console.log(updatedCourse, 'inerst uc2')
    const allCourses = this.state.courses
    const courses = allCourses.map((p: any) => {
      if (updatedCourse.id != p.id) return p
      return updatedCourse
    })
    console.log(courses, 'inerst uc2')
    this.setState({ courses })
  }

  startDateChange = (v: any) => {
    this.setState({ startCourseDate: v})
  }

  endDateChange = (v: any) => {
    this.setState({endCourseDate: v})
  }
  // Customizable Area End
  
}
