import React from "react";
// Customizable Area Start
import DashboardLayout from "../../../components/src/DashboardLayout.web";

import ServicesController, {
  Props,
} from "./servicesController";
import SeeMore from '../../../components/src/SeeMore.web'
import {
  Card, CardHeader, CardContent,
  Grid,
  Divider,
  Typography,
  IconButton,
  MenuItem,
  Button,
  Avatar,
  Menu,
} from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroll-component';
import MediaCarousel from '../../../components/src/MediaCarousel.web'
import OverlayLoader from '../../../components/src/Loader.web'
import FilterPosts from '../../filteritems/src/FilterPosts.web'
import { LoadingSpinner } from '../../../components/src/LoadingSpinner.web'
import PostCard, { CommonActions } from '../../../components/src/PostCard.web'
import { styled } from '@material-ui/styles'
import Flex from '../../../components/src/Flex.web'
import { Formik, useField } from 'formik'

import { Link } from 'react-router-dom';
import PlusMorePopup from "../../../components/src/PlusMorePopup.web";
import LikeAPost from '../../LikeAPost/src/LikeAPost.web'
import { iconCorp, iconGreenThumb } from "../../Events2/src/assets";
import { commentCount, MoreHorizIcon } from '../../ActivityFeed/src/assets'
import MarketplaceTopbar from "../../../components/src/MarketplaceTopbar.web";
import Filteritems from "../../filteritems/src/Filteritems.web";
import DeleteModal from "../../../components/src/DeleteModal.web";
import FormModal, { FRating, InputRow, MInput } from "../../../components/src/FormModal.web";
import DiscountCard from "../../../components/src/DiscountCard.web";
import Rating from "../../../components/src/Rating.web";
import UserContext from "../../../components/src/UserProvider";
import { mapLocation } from "./assets";
import RoomIcon from '@material-ui/icons/Room'
// import MediaCarousel from "../../../components/src/MediaCarousel.web";
// Customizable Area End
export default class Services extends ServicesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  RateServicesModal = () => {
    return <Formik initialValues={{}} onSubmit={this.handleRateServices}>

    <FormModal modalStyle={{ height: '360px' }} headerTitle="Rate this Service" submitLabel="submit review" onClose={this.hideModal}>
    <Flex py="25px" gap="10px" col align="center">

          <Typography style={{ wordBreak: 'break-all' }} variant="h5">{this.state.selectedService?.service_name}</Typography>
          <Typography>Service ID: {this.state.selectedService?.service_unique_id}</Typography>
          <Typography color="textSecondary" variant="h5">Rate your service!</Typography>
          <FRating value={this.state.modalRating} onChange={this.setModalRating} />
        </Flex>
      </FormModal></Formik>

  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { navigation } = this.props
    const servicesModal = this.RateServicesModal()
    const services = this.state.services;
    const filterMenu = <FilterPosts filterAll={this.onFilterChange} onQueryChange={this.onFilterQueryChange} favouriteable_type="BxBlockJoblisting2::Job" id={this.props.id} navigation={this.props.navigation} />
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <DashboardLayout token={this.state.token} navigation={navigation}>
          <MarketplaceTopbar filters={filterMenu} userRole={this.context.role.role_level} onCreate={this.createServiceClick} selectedTab={3} onTabChange={() => { }} />
          {this.state.deleteServiceId && <DeleteModal label="Delete this event?" onClose={this.closeDeleteModal} onSubmit={this.handleServiceDeleteConfirm} />}
          {!this.state.loadingServices && this.state.services.length === 0 && <Typography style={{padding: '50px 0'}} align="center" variant="h5">No Match Found</Typography>}
        <Menu
        id="threedot-menu"
        anchorEl={this.state.threedotAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        keepMounted
        open={Boolean(this.state.threedotAnchorEl)}
        onClose={this.handleThreeDotClose}
      >
      <MenuItem
        disabled={this.state.currentService?.created_by?.id == this.context.id}
        onClick={this.handleServiceReport}
        >Report This Listing</MenuItem>
        <MenuItem
        disabled={!(this.state.currentService?.created_by?.id == this.context.id)}
        onClick={this.handleServiceEdit}
        >
          Edit
        </MenuItem>
        <MenuItem
        disabled={!(this.state.currentService?.created_by?.id == this.context.id)}
        onClick={this.handleServiceDelete}
        >
          Delete
        </MenuItem>
        <MenuItem
        disabled={this.state.currentService?.created_by?.id == this.context.id}
        onClick={this.handleRaiseRequest}
        >
          Raise A Request
        </MenuItem>
      </Menu>
          <OverlayLoader loading={!!this.state.loadingServices} />
          <InfiniteScroll
            dataLength={this.state.services.length} //This is important field to render the next data
            next={this.loadNextPage}
            hasMore={(!!this.state.pagination?.next_page) && !this.state.loadingServices}
            // Show only when this.state.loadingPosts component is not rendered
            loader={this.state.loadingServices && <LoadingSpinner />}
          >
            {services && services.map(s => (

              <RoundedCard style={{ padding: '0' }} key={s.id} variant="outlined">
                <CardHeader
                  style={{ padding: '25px 20px 15px 20px' }}
                  avatar={<Link to={`/user/${s.created_by.id}`}><Avatar style={{ width: 55.26, height: 51.87 }} src={s.created_by.profile_pic} onClick={() => navigation.navigate(`user/${s.created_by.id}`)} /></Link>}
                  title={<span style={{ fontWeight: 600, marginBottom: '4px', fontSize: "1.021rem", maxWidth: '30ch', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', textTransform: 'capitalize' }}><Link to={`/user/${s.created_by.id}`}>{s.created_by.name}</Link></span>}
                  subheader={<Grid style={{ gap: '10px' }} container alignItems="center">
                    <span style={{ textTransform: 'capitalize', fontSize: '0.75rem' }}>{s.created_by?.location}</span>
                    <Divider orientation="vertical" flexItem />
                    <span style={{ fontSize: '0.75rem' }}>{s.created_at}</span>
                  </Grid>}

                  // @todo this is scheduled for later release                        
                  action={<div onClick={this.openThreeDot} data-id={s.id}>
                    <IconButton style={{ backgroundColor: 'transparent' }} disableFocusRipple disableRipple>
                      <img src={MoreHorizIcon} width="30px" />
                    </IconButton>
                  </div>}

                >
                </CardHeader >
                <Divider />
                <CardContent style={{ padding: '16px 30px' }}>
                  <FlexSpaceBetween style={{ padding: '0', alignItems: 'start' }}>

                    <div style={{ flex: 1 }}>
                      <CourseContent>
                        Category :
                      </CourseContent>
                      <MR5>{s.service_categories[0]}</MR5>
                      {s.service_categories?.length > 1 && <PlusMorePopup heading="Category" listItems={s.service_categories}>
                        +{s.service_categories?.length - 1}
                      </PlusMorePopup>}
                    </div>

                    <div style={{ flex: 1 }}>
                      <CourseContent>
                        Sub Category :
                      </CourseContent>
                      <MR5>{s.service_subcategories[0]}</MR5>
                      {s.service_subcategories?.length > 1 && <PlusMorePopup heading="Sub Category" listItems={s.service_subcategories}>
                        +{s.service_subcategories?.length - 1}
                      </PlusMorePopup>}
                    </div>
                    <div style={{ flex: 1 }}>
                      <CourseContent>
                        Skills :
                      </CourseContent>
                      <MR5>{s.skills}</MR5>
                    </div>
                    
                  </FlexSpaceBetween>
                  <FlexSpaceBetween style={{ padding: '0', alignItems: 'start', marginTop: '15px' }}>
                    <div style={{ flex: 1 }}>
                      <CourseContent>
                        Experience :
                      </CourseContent>
                      <MR5>{s.service_experience} {Number(s.service_experience) >1 ? "Years" : "Year"}</MR5>
                    </div>
                    <div style={{ flex: 2 }}>
                      <CourseContent>
                        Service ID :
                      </CourseContent>
                      <MR5>{s.service_unique_id}</MR5>
                    </div>
                  </FlexSpaceBetween>
                </CardContent>
                <Divider />
                <CardContent >
                  <Flex col py="10px" gap="20px" style={{ padding: '0px 15px 15px' }}>
                    <Flex col >
                      <InnerContent>
                        <Typography style={{ color: '#2A353F', fontSize: '20px', fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {s.service_name.length > 80 ? `${s.service_name.substring(0, 80)}...` : s.service_name}
                        </Typography>
                        <div style={{ transform: 'scale(0.7)' }}>
                          <Rating disabled={true} total={5} value={s.average_rating} />
                        </div>
                      </InnerContent>
                      <PlContent>
                            <div style={{display: 'flex', alignItems: "center"}}>
                              <PriceContent>
                                Price Range :
                              </PriceContent>
                              <PR5>{`$${s.price_min_usd} (₹${s.price_min_inr}) to $${s.price_max_usd} (₹${s.price_max_inr})`}</PR5>
                            </div>
                            <LocationWraper>
                              <span style={{color: '#646464',  marginRight: '0px'}}><RoomIcon /></span>
                              <span style={{ color:'#2A353F', opacity: 0.34, fontSize:"17px", }}>
                                {s.location}
                              </span>
                            </LocationWraper>
                      </PlContent>
                    </Flex>
                  </Flex>
                </CardContent>
                <CardContent>
                  <Flex col py="10px" gap="20px" style={{ padding: '0px 15px 15px' }}>
                    {
                      s.description && <Flex col style={{ color: 'rgba(0,0,0,0.32)' }}>
                        <SeeMore limit={100} text={s.description} />
                      </Flex>
                    }
                    {s.images && <MediaCarousel items={s.images} />}
                    {Number(s.coin_discount) > 0 && <DiscountCard coins={Number(s.equivalent_coins)} discount={Number(s.coin_discount)} />}
                    <Flex align="center" justify="space-between">
                      <div style={{ lineHeight: '30px', display: 'flex', gap: '5px', fontSize: '0.875rem', color: '#212529', paddingRight:'8px' }}>
                        <img src={iconGreenThumb} width="30" />
                        <span>{s.total_likes}</span>
                        <img src={commentCount} width="30" />
                        <span>{s.total_comments}</span>
                      </div>
                      <Flex style={{ gap: '10px' }}>
                        {
                          s.is_rated ?
                          <RatedService area-disabled  data-id={s.id}>Rate this Service</RatedService>
                          :
                          <RateService onClick={this.handleServicesRating} data-id={s.id}>Rate this Service</RateService>
                        }
                        
                        <ActionRoundedButton data-id={s.id} onClick={this.handleEnquireClick}>Enquire</ActionRoundedButton>
                      </Flex>
                    </Flex>
                  </Flex>
                </CardContent>
                <Divider />
              <CommonActions
                onStarClick={this.handleFavClick(s.id)} 
                onShareClick={this.handleShareCourse(s)}
                isFavourite={s.is_favourite}  
                onCommentClick={this.goToEvent(s.id)}
                likeButton={
                <>
                <LikeAPost
                    navigation={this.props.navigation}
                    id=""
                    likeable_type="BxBlockContentManagement::MarketplaceService"
                    isLiked={s.like_id.length > 0}
                    post_id={String(s.id) }
                    like_id={s.like_id[0]?.id}
                    onLikeChange={this.onLikeChange}
                    onLikeIdUpdate={this.onLikeIdUpdate}
                  />
                </>}
              />
              </RoundedCard>
            ))}
          </InfiniteScroll>
        </DashboardLayout>
        {this.state.modal === 'rateService' && servicesModal}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
ServicesController.contextType = UserContext

const EarnCoins = ({ coins }: { coins: number }) => <GreenBg>
  <img src={iconCorp} width='35px' />
  <Typography variant="h6" style={{ fontWeight: 600 }} color="primary">Earn {coins} Corpstage coins</Typography>
</GreenBg>

const LocationWraper = styled('div')({
  display: 'flex', 
  alignItems: 'center',
})

const RoundedCard = styled(Card)({
  borderRadius: '15px',
  padding: '10px 20px',
  margin: '20px 0px'
})

const FlexSpaceBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  padding: '20px 0px',
  gap: '10px',
  width: '100%',
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
})

const InnerContent = styled(Flex)({
  gap: "20px", 
  alignItems: "center", 
  justifyContent: "space-between",
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    // alignItems: "start"
  },
})

const PlContent = styled(Flex)({
  fontSize: '16px',
  color: '#2A353F', 
  gap: '30px', 
  padding: '0', 
  alignItems: 'center', 
  marginTop: '15px',
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    // alignItems: "start"
  },
})

const MR5 = styled('span')({
  marginRight: '5px',
  color: '#2a353f',
  textTransform: 'capitalize',
  fontSize: '15px'
})

const CourseContent = styled('span')({
  fontWeight: 700,
  color: '#000',
  fontSize: "15px",
  marginRight: '5px'
})

const ActionRoundedButton = styled(Button)({
  color: '#2caa38',
  border: '2px solid #79bb74',
  borderRadius: '25px',
  padding: '5px 35px',
  textTransform: 'none',
  fontWeight: 600
})

const GreenBg = styled('div')({
  padding: '15px',
  background: '#F2F7E4',
  borderRadius: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '25px'
})

const PriceContent = styled('span')({
  fontWeight:700,
  // fontSize"15px",
  marginRight: '5px',
  marginTop: '2px',
})

const PR5 = styled('div')({
  fontWeight: 700,
})


const RatedService = styled('div')({
  padding: '15px',
  background: '#ccc',
  borderRadius: '10px',
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
  // gap: '25px'
})

const RateService = styled('div')({
  padding: '15px',
  background: '#2caa38',
  borderRadius: '10px',
  color: '#fff',
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
  // gap: '25px'
})

// Customizable Area End